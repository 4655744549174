<script setup>
import { ref, computed } from 'vue'
import { DEFAULT_SOURCE_FILTERS, SOURCES } from '@/components/pfp/const'
import usePfpStore from '@/pinia/pfp';
import DatePicker from '@/components/pfp/date-picker/DatePicker.vue'
import ResultSection from '@/components/pfp/results-table/ResultSection.vue'
import Input from '@/components/pfp/ui/input/Input.vue'
import Label from '@/components/pfp/ui/label/Label.vue'
import SourceFilter from '../source-filter/SourceFilter.vue'

const emit = defineEmits(['search:input', 'sources:change', 'started:change', 'completed:change'])

const search = ref('')
const selectedSources = ref({ ...DEFAULT_SOURCE_FILTERS })
const startedRange = ref(null)
const completedRange = ref(null)

const store = usePfpStore();

function handleSearch(query) {
  search.value = query
  emit('search:input', query)
}

function handleSelectSource(source) {
  if (source in selectedSources.value) {
    selectedSources.value[source] = !selectedSources.value[source]
  }
  
  emit('sources:change', selectedSources.value)
}

function handleRange(which, range) {
  if (which === 'started') {
    startedRange.value = range
    emit('started:change', range)

    return
  }

  if (which === 'completed') {
    completedRange.value = range
    emit('completed:change', range)

    return
  }

  return;
}
</script>

<template>
  <ResultSection class="tw-flex tw-flex-col tw-gap-y-4">
    <div>
      <h3 class="tw-text-xl tw-font-bold tw-flex tw-gap-x-3 tw-items-center tw-mb-0">
        Filters
      </h3>
      <p class="tw-text-sm tw-text-muted-foreground">
        If you want to search for a specific result, you can use the filters below to narrow down your search. Please note, this will only search loaded results.
      </p>
    </div>
  
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
      <!-- <div class="tw-flex-grow"> -->
      <div class="tw-basis-full lg:tw-basis-2/3">
        <Label>
          Search by reference or query
          <span class="tw-text-xs tw-block tw-text-muted-foreground tw-mt-2">Enter a search term below and find relevant results based on the "reference" and "query" columns.</span>
        </Label>
        <Input placeholder="Search..." @input="handleSearch" :value="search" />
      </div>

      <!-- <div class="t tw-basis-1/3"> -->
      <div class="tw-basis-full lg:tw-basis-1/3">
        <Label>
          Sources matched
          <span class="tw-text-xs tw-block tw-text-muted-foreground tw-mt-2">Toggle sources below to filter results</span>
        </Label>
        
        <SourceFilter @select="handleSelectSource" :selected="selectedSources" :sources="store.availableSources"  />
      </div>
    </div>

    <div class="tw-flex tw-gap-x-6">
      
    </div>

    <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-6">
      <div class="tw-basis-1/2">
        <Label>
          Started date range
          <span class="tw-text-xs tw-block tw-text-muted-foreground tw-mt-2">Show results that were started during a date range</span>
        </Label>
        
        <DatePicker @range:selected="handleRange('started', $event)" />
      </div>
      
      <div class="tw-basis-1/2">
        <Label>
          Completion date range
          <span class="tw-text-xs tw-block tw-text-muted-foreground tw-mt-2">Show results that were completed during a date range</span>
        </Label>
        
        <DatePicker @range:selected="handleRange('completed', $event)" />
      </div>
    </div>
    
  </ResultSection>
</template>