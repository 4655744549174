<template>
    <b-card-group deck>
        <b-card tag="section"
                class="mb-2">
            <template v-slot:header>
                <h4 class="card-title">
                    {{ proprietor.name }}
                </h4>
            </template>
            <b-card-text v-if="proprietor.company_number">
                Company Number: {{ proprietor.company_number }}
            </b-card-text>
            <b-card-text v-if="proprietor.address_1">
                {{ proprietor.address_1 }}
            </b-card-text>
            <b-card-text v-if="proprietor.address_2">
                {{ proprietor.address_2 }}
            </b-card-text>
            <b-card-text v-if="proprietor.address_3">
                {{ proprietor.address_3 }}
            </b-card-text>
            <b-card-text></b-card-text>
            <b-card-text></b-card-text>
            <b-button :href="`https://beta.companieshouse.gov.uk/company/${proprietor.company_number}`"
                      variant="primary"
                      target="_blank"
                      v-if="proprietor.company_number">
                Search Proprietor
            </b-button>
        </b-card>
    </b-card-group>
</template>

<script>
    export default {
        name: 'ProprietorSection',
        props: [
            'proprietor'
        ]
    }
</script>

<style scoped lang="scss">

</style>
