<template>
    <div class="profile-finder-results">
        <template v-if="hasResults">
            <template v-if="hasPerson">
                <possible-person :person="results.person"
                                 v-if="hasPerson"
                                 result-position="1"
                                 :results-count="numberOfResults">
                </possible-person>
            </template>
            <template v-else-if="hasPossiblePersons">
                <possible-person :person="subject" v-for="(subject, key) in results.possible_persons"
                                 :key="key"
                                 :result-position="key + 1"
                                 :results-count="numberOfResults">
                </possible-person>
            </template>
        </template>
        <template v-else>
            <h2 class="text-center">Sorry, there were no results for that search</h2>
        </template>
    </div>
</template>

<script>
    import PossiblePerson from './Results/PossiblePerson'
    import { mapGetters, mapMutations } from 'vuex'

    export default {
        name: 'profile-finder-results',
        components: {
            PossiblePerson,
        },
        computed: {
            ...mapGetters({
                results: 'profileFinder/results'
            }),
            hasPerson () {
                return !!this.results.person
            },
            hasPossiblePersons () {
                return !!this.results.possible_persons
            },
            hasResults () {
                return !!this.numberOfResults
            },
            numberOfResults () {
                if (this.results.possible_persons) {
                    return this.results.possible_persons.length
                } else if (this.results.person) {
                    return 1
                } else {
                    return 0
                }
            },
        },
        methods: {
            ...mapMutations({
                backToSearch: 'profileFinder/RESET_RESULTS'
            })
        },
    }
</script>
