<template>
    <b-card class="card-default" :header="$t('privacy_checks')">
        <b-table striped
                 hover
                 :fields="fields"
                 :items="privacyCheckers"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 v-if="hasPrivacyCheckers">
            <template v-slot:cell(type)="data">
                <p v-html="data.value"></p>
            </template>
            <template v-slot:cell(private)="data">
                <p v-html="data.value"></p>
            </template>
            <template #cell(last_checked)="data">
                <p>{{ data.value | datetime }}</p>
            </template>
            <template v-slot:cell(active)="data">
                <p v-html="data.value"></p>
            </template>
            <template #cell(created_at)="data">
                <p>{{ data.value | datetime }}</p>
            </template>
            <template v-slot:cell(expires)="data">
                <p>{{ data.item.expires | datetime }}
                    <span v-html="timeLeft(data.item.expires)"></span>
                </p>
            </template>
            <template v-slot:cell(actions)="row">
                <!-- We use @click.stop here to prevent a 'row-clicked' event from also happening -->
                <b-button size="sm" @click.stop="info(row.item)" class="mr-1">
                    Delete Check
                </b-button>
                <toggle :active="row.item.active" @set-active="setActive(row.item.uuid, $event)"></toggle>
            </template>
        </b-table>
        <b-alert :show="true" variant="success" v-if="!hasPrivacyCheckers">
            Your Privacy Checks will show here
        </b-alert>
        <b-modal id="modalDelete" @hide="resetModal" :title="modalDelete.title" v-model="modal"
                 @ok="deletePrivacyCheck(modalDelete.content.uuid)">
            <p>Are you sure you wish to delete this privacy check?</p>
            <small></small>
        </b-modal>
    </b-card>
</template>

<script>
    import axios from 'axios'
    import booleanFilter from './../utils/mixins/booleanFilter'
    import localDatetime from './../utils/mixins/localDatetime'
    import Toggle from './partials/elements/Toggle'
    import { mapGetters } from 'vuex'

    export default {
        name: 'ListPrivacyCheck',
        components: {
            Toggle
        },
        computed: {
            ...mapGetters({
                privacyCheckers: 'privacyChecker/privacyCheckers',
                hasPrivacyCheckers: 'privacyChecker/hasPrivacyCheckers'
            }),
        },
        data: () => ({
            fields: [
                {
                    key: 'username',
                    sortable: true,
                },
                {
                    key: 'type',
                    formatter: 'typeFormatter',
                },
                {
                    key: 'client_reference',
                    sortable: true,
                },
                {
                    key: 'private',
                    label: 'Private',
                    formatter: 'yesNoFormatter',
                    sortable: true,
                },
                {
                    key: 'last_checked',
                    label: 'Last Checked',
                    sortable: true,
                },
                {
                    key: 'active',
                    label: 'Active',
                    formatter: 'yesNoFormatter',
                    sortable: true,
                },
                {
                    key: 'created_at',
                    label: 'Created at',
                    sortable: true,
                },
                {
                    key: 'expires',
                    label: 'Expires',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: 'Actions'
                }
            ],
            modal: false,
            modalDelete: {
                title: '',
                content: '',
            },
            privacy_checks: [],
            sortBy: 'username',
            sortDesc: false,
        }),
        methods: {
            async deletePrivacyCheck (uuid) {
                await axios.delete(`/api/privacy-checker/${uuid}`)

                await this.$store.dispatch('privacyChecker/fetchPrivacyCheckers')
            },
            resetModal () {
                this.modalDelete.title = ''
                this.modalDelete.content = ''
            },
            setActive (uuid, value) {
                axios.patch(`/api/privacy-checker/${uuid}`, { active: value })
                    .then(response => {
                        console.log(response)
                    })
            },
            info (item) {
                this.modalDelete.title = `Delete Privacy Check for ${item.username}`
                this.modalDelete.content = item
                this.modal = true
            },
            yesNoFormatter (value) {
                switch (value) {
                    case true:
                        return `<svg aria-hidden="true" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon-15">
                                    <path fill="green" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg>`
                    case false:
                        return `<svg aria-hidden="true" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="icon-15">
                                    <path fill="red" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                                </svg>`
                    default:
                        return 'error'
                }
            },
            timeLeft (value) {
                let d = new Date
                let currentTime = d.getTime() / 1000
                let expireTime = value
                let oneMonth = 2592000

                if (expireTime - currentTime >= oneMonth) {
                    return `<svg aria-hidden="true" data-prefix="fas" data-incon="hourglass-start" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon-15">
                                <path fill="#03AFFB" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" class=""></path>
                            </svg>`
                } else {
                    return `<svg aria-hidden="true" data-prefix="fas" data-incon="hourglass-half" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon-15">
                                <path fill="#ffc107" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" class=""></path>
                            </svg>`
                }
            },
        },
        mixins: [
            booleanFilter,
            localDatetime
        ],
    }
</script>

<style scoped lang="scss">
    @import '../../sass/variables';

    .card {

        &-body {
            text-align: center;

            table {
                thead {
                    color: $secondary-color;
                }

                button {
                    background: $danger-red;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }
            }
        }
    }
</style>
