import { render, staticRenderFns } from "./LandRegistryProprietor.vue?vue&type=template&id=d84ec186&scoped=true"
import script from "./LandRegistryProprietor.vue?vue&type=script&lang=js"
export * from "./LandRegistryProprietor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d84ec186",
  null
  
)

export default component.exports