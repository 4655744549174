<template>
    <div class="tool">
        <div class="tool-header">
            <h2>
                <span>
                    <svg aria-hidden="true" data-prefix="fas" data-incon="search" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon-25">
                        <path fill="currentColor"
                              d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                              class=""></path>
                    </svg>
                </span>
                Profile Finder
            </h2>
            <small>Find associated social media accounts by email or telephone</small>
        </div>
        <div class="tool-body">
            <div class="container">
                <b-form @submit.prevent="search" @reset.prevent="resetForm">
                    <div class="row">
                        <div class="col-12 my-4">
                            <div class="card card-default">
                                <div class="card-body">
                                    <b-form-group id="input-group__client-reference"
                                                  description="Your reference (required)"
                                                  class="my-0">
                                        <b-form-input id="input__client-reference"
                                                      type="text"
                                                      v-model.trim="form.client_reference"
                                                      placeholder="Client Reference"
                                                      :class="{ 'is-invalid': form.errors.has('client_reference') }"
                                        />
                                        <has-error :form="form" field="client_reference"></has-error>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-default">
                                <div class="card-header">
                                    <span>
                                        <svg aria-hidden="true" data-prefix="fas" data-incon="address-card" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="icon-20">
                                            <path fill="currentColor"
                                                  d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"
                                                  class=""></path>
                                        </svg>
                                    </span>
                                    Contact details
                                </div>
                                <div class="card-body">
                                    <!-- Email -->
                                    <b-form-group id="email"
                                                  description="Subject's email address">
                                        <b-form-input id="input__email-address"
                                                      type="email"
                                                      v-model.trim="form.email"
                                                      placeholder="Subject's email"
                                                      :class="{ 'is-invalid': form.errors.has('email') }"
                                        />
                                        <has-error :form="form" field="email"></has-error>
                                    </b-form-group>
                                    <!-- Phone Number -->
                                    <b-form-group id="phone"
                                                  description="Subject's phone number">
                                        <b-form-input id="input__phone"
                                                      type="text"
                                                      v-model="form.phone"
                                                      placeholder="Subject's phone number"
                                                      :class="{ 'is-invalid': form.errors.has('phone') }"
                                        />
                                        <has-error :form="form" field="phone"></has-error>
                                    </b-form-group>
                                    <!-- Username -->
                                    <b-form-group id="username"
                                                  description="Subject's Username">
                                        <b-form-input id="input__username"
                                                      type="text"
                                                      v-model="form.username"
                                                      placeholder="Subject's Username"
                                                      :class="{ 'is-invalid': form.errors.has('username') }"
                                        />
                                        <has-error :form="form" field="username"></has-error>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-default">
                                <div class="card-header">
                                    <span>
                                        <svg aria-hidden="true" data-prefix="fas" data-incon="user" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon-20">
                                            <path fill="currentColor"
                                                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                                                  class=""></path>
                                        </svg>
                                    </span>
                                    Personal Information
                                </div>
                                <div class="card-body">
                                    <!-- First Name -->
                                    <b-form-group id="first"
                                                  description="Subject's First Name">
                                        <b-form-input id="input__first-name"
                                                      type="text"
                                                      v-model="form.first"
                                                      placeholder="First name"
                                                      :class="{ 'is-invalid': form.errors.has('first') }"
                                        />
                                        <has-error :form="form" field="first"></has-error>
                                    </b-form-group>
                                    <!-- Middle Name -->
                                    <b-form-group id="middle"
                                                  description="Subject's Middle Name">
                                        <b-form-input id="input__middle-name"
                                                      type="text"
                                                      v-model="form.middle"
                                                      placeholder="Middle name"
                                                      :class="{ 'is-invalid': form.errors.has('middle') }"
                                        />
                                        <has-error :form="form" field="middle"></has-error>
                                    </b-form-group>
                                    <!-- Last Name -->
                                    <b-form-group id="last"
                                                  description="Subject's Last Name">
                                        <b-form-input id="input__last-name"
                                                      type="text"
                                                      v-model="form.last"
                                                      placeholder="Last name"
                                                      :class="{ 'is-invalid': form.errors.has('last') }"
                                        />
                                        <has-error :form="form" field="last"></has-error>
                                    </b-form-group>
                                    <!-- Age -->
                                    <b-form-group id="age"
                                                  description="Subject's Age">
                                        <b-form-input id="input__age"
                                                      type="number"
                                                      v-model="form.age"
                                                      placeholder="Age"
                                                      :class="{ 'is-invalid': form.errors.has('age') }"
                                        />
                                        <has-error :form="form" field="age"></has-error>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-default">
                                <div class="card-header">
                                    <span>
                                        <svg aria-hidden="true" data-prefix="fas" data-incon="map-marked-alt" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="icon-20">
                                            <path fill="currentColor"
                                                  d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"
                                                  class=""></path>
                                        </svg>
                                    </span>
                                    Location
                                </div>
                                <div class="card-body">
                                    <!-- Country Code -->
                                    <b-form-group id="country"
                                                  description="Subject's Country Code">
                                        <b-form-select v-model="form.country"
                                                       :options="countries"
                                                       id="input__country-code"
                                                       :class="{ 'is-invalid': form.errors.has('country') }"
                                        />
                                        <has-error :form="form" field="country"></has-error>
                                    </b-form-group>
                                    <!-- State Code -->
                                    <b-form-group id="state"
                                                  description="Subject's State Code">
                                        <b-form-select v-model="form.state"
                                                       :options="stateOptions"
                                                       id="input__state-code"
                                                       :class="{ 'is-invalid': form.errors.has('state') }"
                                        />
                                        <has-error :form="form" field="state"></has-error>
                                    </b-form-group>
                                    <!-- City -->
                                    <b-form-group id="city"
                                                  description="Subject's City">
                                        <b-form-input id="input__city"
                                                      type="text"
                                                      v-model="form.city"
                                                      placeholder="Subject's City"
                                                      :class="{ 'is-invalid': form.errors.has('city') }"
                                        />
                                        <has-error :form="form" field="city"></has-error>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center">
                                <b-button type="submit" variant="primary">Find profiles</b-button>
                                <b-button type="reset" variant="danger">Reset</b-button>
                            </div>
                        </div>
                    </div>
                </b-form>
                <div class="row">
                    <div class="col-12">
                        <p>
                            Please enter all the information you have about the person you're searching for, at least
                            one field is required: Email/Phone/Username/Name (First + Last).
                        </p>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { capitalize } from 'lodash'

    export default {
        name: 'ProfileFinderSearch',
        computed: {
            ...mapGetters({
                states: 'profileFinder/states',
                countries: 'profileFinder/countries',
                form: 'profileFinder/form'
            }),
            stateOptions () {
                return this.states[this.form.country]
            },
            label () {
                return `${capitalize(this.type)} username`
            },
        },
        methods: {
            ...mapMutations({
                resetForm: 'profileFinder/RESET_FORM'
            }),
            ...mapActions({
                search: 'profileFinder/search'
            })
        },
    }
</script>

<style scoped lang="scss">
    @import '../../sass/variables';

    .tool {
        height: 100%;

        &-body {
            .btn {
                min-width: 100px;
                margin: 50px 0 25px 0;
                border: none;
                border-radius: 5px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);

                &-primary {
                    background: $primary-color;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }

                &-danger {
                    background: $danger-red;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }
            }
        }

        .card {
            height: 100%
        }
    }
</style>
