import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.NAMES;

const NAMES_KEY = {
    FIRST_NAME: TABLE_KEY.FIRST_NAME,
    LAST_NAME: TABLE_KEY.LAST_NAME,

    FULL_NAMES: 'full_names',

    INITIALS: TABLE_KEY.INITIALS,

    TITLES: 'titles',
};

export function getNames(result) {
    const names = {};

    const fullNames = [];

    if (result.raw[TABLE_KEY.FIRST_NAME]) {
        names[NAMES_KEY.FIRST_NAME] = result.raw[TABLE_KEY.FIRST_NAME];
    }

    if (result.raw[TABLE_KEY.LAST_NAME]) {
        names[NAMES_KEY.LAST_NAME] = result.raw[TABLE_KEY.LAST_NAME];
    }

    if (result.raw[TABLE_KEY.FIRST_NAME] && result.raw[TABLE_KEY.LAST_NAME]) {
        if (result.raw[TABLE_KEY.MIDDLE_NAME]) {
            fullNames.push(
                `${result.raw[TABLE_KEY.FIRST_NAME]} ${
                    result.raw[TABLE_KEY.MIDDLE_NAME]
                } ${result.raw[TABLE_KEY.LAST_NAME]}`,
            );
        } else if (result.raw[TABLE_KEY.MIDDLE_INITIALS]) {
            fullNames.push(
                `${result.raw[TABLE_KEY.FIRST_NAME]} ${
                    result.raw[TABLE_KEY.MIDDLE_INITIALS]
                } ${result.raw[TABLE_KEY.LAST_NAME]}`,
            );
        } else {
            fullNames.push(
                `${result.raw[TABLE_KEY.FIRST_NAME]} ${
                    result.raw[TABLE_KEY.LAST_NAME]
                }`,
            );
        }
    }

    if (result.raw[TABLE_KEY.FULL_NAME]) {
        fullNames.push(result.raw[TABLE_KEY.FULL_NAME]);
    }

    if (result.raw[TABLE_KEY.FULL_NAME_2]) {
        fullNames.push(result.raw[TABLE_KEY.FULL_NAME_2]);
    }

    if (result.raw[TABLE_KEY.DISPLAY_NAME]) {
        fullNames.push(result.raw[TABLE_KEY.DISPLAY_NAME]);
    }

    if (result.raw[TABLE_KEY.FACEBOOK_NAME]) {
        fullNames.push(result.raw[TABLE_KEY.FACEBOOK_NAME]);
    }

    if (fullNames.length > 0) {
        names[NAMES_KEY.FULL_NAMES] = fullNames;
    }

    if (result.raw[TABLE_KEY.INITIALS]) {
        names[NAMES_KEY.INITIALS] = result.raw[TABLE_KEY.INITIALS];
    }

    const titles = [];

    if (result.raw[TABLE_KEY.TITLE]) {
        titles.push(result.raw[TABLE_KEY.TITLE]);
    }

    if (result.raw[TABLE_KEY.NAME_PREFIX]) {
        titles.push(result.raw[TABLE_KEY.NAME_PREFIX]);
    }

    if (titles.length > 0) {
        names[NAMES_KEY.TITLES] = titles;
    }

    return {
        names,
        fullNames,
        titles,
    };
}
