<script setup>
import { computed, ref } from 'vue';
import Card from '@/components/pfp/ui/card/Card.vue';
import AttributeTable from '@/components/pfp/results-table/AttributeTable.vue';
import SourceTags from '@/components/pfp/source-tags/SourceTags.vue';
import { flattenObject } from '@/utils/lib';
import Button from '../../../ui/button/Button.vue';
import TransitionExpand from '../../../transition-expand/TransitionExpand.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
  data: {
    type: [Object, Array],
    required: false,
  },
  query: {
    type: String,
    required: false,
  },
  hideOnNoResults: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const open = ref(false);

const flat = computed(() => {
  return flattenObject(props.data);
})

const filtered = computed(() => {
  if (!props.query) {
    return flat.value;
  }

  const query = props.query.toLowerCase();

  return Object.entries(flat.value).reduce((acc, [key, value]) => {
    if (key.toLowerCase().includes(query) || String(value).toLowerCase().includes(query)) {
      acc[key] = value;
    }

    return acc;
  }, {});
})

const showingTotal = computed(() => {
  return Object.keys(filtered.value).length;
})

const total = computed(() => {
  return Object.keys(flat.value).length;
})

const showCard = computed(() => {
  if (props.query && props.hideOnNoResults) {
    console.log(showingTotal.value);
    return showingTotal.value > 0;
  }

  return true;
})
</script>

<template>
  <Card class="tw-h-fit" v-if="showCard">
    <div class="tw-flex tw-justify-between">
      <div>
        <p class="tw-mb-2">{{ props.name }}</p>
        <SourceTags :data="[props.source]" class="tw-mb-2" />
      </div>

      <Button variant="ghost" @click="open = !open">{{ open ? 'Hide' : 'Show' }} data</Button>
    </div>

    <TransitionExpand>
      <div v-if="open">
        <div class="tw-overflow-y-auto tw-max-h-[400px]">
          <AttributeTable :data="filtered" :flatten="false" />
        </div>
      </div>
    </TransitionExpand>

    <p class="tw-text-sm tw-text-muted-foreground tw-mt-2">
      Showing {{ showingTotal }} of {{ total }} items
    </p>
  </Card>
</template>
