import { render, staticRenderFns } from "./AdditionalInfoSection.vue?vue&type=template&id=36f4d0c6"
import script from "./AdditionalInfoSection.vue?vue&type=script&setup=true&lang=js"
export * from "./AdditionalInfoSection.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports