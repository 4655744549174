<script>
import {defineComponent} from 'vue'
import {mapGetters} from 'vuex';
import {get} from 'lodash';

export default defineComponent({
    name: 'OsintIndustriesLookupView',
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const uuid = vm.$route.params.uuid
            await vm.$store.dispatch('osintIndustriesLookup/fetchOsintIndustriesLookup', uuid)
        })
    },
    computed: {
        ...mapGetters({
            loading: 'osintIndustriesLookup/loading',
            osintIndustriesLookup: 'osintIndustriesLookup/osintIndustriesLookup'
        }),
        response() {
            return get(this.osintIndustriesLookup, 'response', {})
        }
    },
    filters: {
        pretty: function (value) {
            return JSON.stringify(JSON.parse(JSON.stringify(value, null, 2)), null, 2)
        }
    }
})
</script>

<template>
    <b-card>
        <b-skeleton-wrapper :loading="!loading">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
        </b-skeleton-wrapper>
        <pre>{{ response | pretty }}</pre>
    </b-card>
</template>
