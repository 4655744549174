import axios from 'axios'

export const state = {
    loading: false,
    virtualWitnesses: []
}

export const getters = {
    virtualWitnesses: state => state.virtualWitnesses
}

export const mutations = {
    SET_LOADING(state, value) {
        state.loading = false
    },
    SET_VIRTUAL_WITNESSES(state, virtualWitnesses) {
        state.virtualWitnesses = virtualWitnesses
    }
}

export const actions = {
    async fetchVirtualWitnesses({commit}) {
        commit('SET_LOADING', true)

        const {data} = await axios.get('/api/virtual-witness')

        commit('SET_VIRTUAL_WITNESSES', data.data)
        commit('SET_LOADING', false)
    }
}
