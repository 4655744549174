import axios from 'axios'
import Form from 'vform'

export const state = {
    loading: false,
    vouchers: [],
    voucher: new Form({
        code: '',
        valid_from: null,
        valid_to: null,
        team_id: null
    })
}

export const getters = {
    loading: state => state.loading,
    vouchers: state => state.vouchers,
    hasVouchers: state => !state.vouchers.length,
    code: state => state.voucher.code,
    valid_from: state => state.voucher.valid_from,
    valid_to: state => state.voucher.valid_to,
    team_id: state => state.voucher.team_id
}

export const mutations = {
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_VOUCHERS (state, payload) {
        state.vouchers = payload
    },
    SET_CODE (state, payload) {
        state.voucher.code = payload
    },
    SET_VALID_FROM (state, payload) {
        state.voucher.valid_from = payload
    },
    SET_VALID_TO (state, payload) {
        state.voucher.valid_to = payload
    },
    SET_TEAM_ID (state, payload) {
        state.voucher.team_id = payload
    }
}

export const actions = {
    async fetchVouchers ({ commit }) {
        commit('SET_LOADING', true)
        const { data } = await axios.get(`/api/kiosk/vouchers`)

        commit('SET_VOUCHERS', data.data)
        commit('SET_LOADING', false)
    }
}
