"use strict";
var _classVarianceAuthority = require("class-variance-authority");

var badgeVariants = (0, _classVarianceAuthority.cva)(
  "tw-inline-flex tw-items-center tw-rounded tw-border tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "tw-border-transparent tw-bg-prime tw-text-prime-foreground hover:tw-bg-prime/80",
        secondary:
          "tw-border-transparent tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80",
        destructive:
          "tw-border-transparent tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/80",
        outline: "tw-text-foreground",
        nwData: "tw-border-transparent tw-bg-sky-400 tw-text-white",
        nwOSINT: "tw-border-transparent tw-bg-indigo-400 tw-text-white",
        nwGumtree: "tw-border-transparent tw-bg-emerald-400 tw-text-white",
        pipl: "tw-border-transparent tw-bg-amber-400 tw-text-white",
        leakedDatasets: "tw-border-transparent tw-bg-pink-400 tw-text-white",
        leakedDatasetsOld: "tw-border-transparent tw-bg-pink-800 tw-text-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);
exports.badgeVariants = badgeVariants;