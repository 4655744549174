import axios from 'axios';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useFilters } from '@/pinia/vw/filters';
import { useNotifcations } from '@/pinia/global/notifications';

const useVWStore = defineStore('vw', () => {
    const results = ref([]);

    const filters = useFilters();
    const notifications = useNotifcations();

    // {
    //   "client_reference": "test",
    //   "date": "2023-12-31", // date or registration
    //   "registration": "MJ16YXL" // date or registration
    // }
    async function searchForMaterials(queryObject) {
        const { reference, date, registration } = queryObject;

        if (!reference && !date && !registration) {
            return false;
        }

        if (date && registration) {
            return false;
        }

        const isDate = date && !registration;

        try {
            const { data } = await axios.post('/api/virtual-witness', {
                client_reference: reference,
                date: isDate ? date : null,
                registration: !isDate ? registration : null,
            });

            results.value.unshift(data.data);

            notifications.addNotification({
                id: new Date().getTime(),
                title: 'Searching for materials',
                message: `Reference ${reference} - ${date || registration}`,
                type: 'success',
            });

            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function fetchResults() {
        const { data } = await axios.get('/api/virtual-witness');

        results.value = data.data;

        if (data.meta) {
            filters.updatePageMeta(data.meta);
        }
    }

    async function fetchNextPage() {
        if (!filters.pageMeta) {
            notifications.addNotification({
                id: new Date().getTime(),
                title: 'Error fetching next page',
                message: 'There are no pages to load',
                type: 'error',
            });

            return;
        }

        const current = filters.pageMeta.current_page;
        const last = filters.pageMeta.last_page;

        if (current === last) {
            notifications.addNotification({
                id: new Date().getTime(),
                title: 'No more pages',
                message: 'You have reached the end of your results',
                type: 'success',
            });
            return;
        }

        const { data } = await axios.get('/api/virtual-witness', {
            params: {
                page: current + 1,
            },
        });

        results.value = [...results.value, ...data.data];

        if (data.meta) {
            filters.updatePageMeta(data.meta);
        }
    }

    return {
        // data
        results,

        // actions
        fetchResults,
        searchForMaterials,
        fetchNextPage,

        // modules
        filters,
        notifications,
    };
});

export default useVWStore;
