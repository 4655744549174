<template>
    <div class="card">
        <div class="card-header card-header__primary">
            Instructions
        </div>
        <div class="card-body p-0">
            <b-table striped
                     hover
                     :fields="fields"
                     :items="tics"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
            :busy="loading">
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template v-slot:cell(profile)="row">
                    <router-link :to="{name: 'temporary-intelligence-capture-profile', params: {uuid: row.item.uuid}}">
                        {{ row.item.profile }}
                    </router-link>
                </template>
                <template v-slot:cell(platform)="data">
                    <p v-html="data.value"></p>
                </template>
                <template v-slot:cell(active)="data">
                    <p v-html="data.value"></p>
                </template>
                <template v-slot:cell(is_private)="data">
                    <p v-html="data.value"></p>
                </template>
                <template v-slot:cell(expires)="row">
                    <p>{{ row.item.expires | localDatetime }}
                        <span v-html="timeLeft(row.item.expires)"></span>
                    </p>
                </template>
                <template v-slot:cell(actions)="row">
                    <!-- We use @click.stop here to prevent a 'row-clicked' event from also happening -->
                    <b-button size="sm" @click.stop="info(row.item)" class=" mr-1">
                        Delete TIC
                    </b-button>
                    <!--<toggle :active="row.item.active" @set-active="setActive(row.item.uuid, $event)"></toggle>-->
                </template>
            </b-table>
            <b-modal id="modalDelete" @hide="resetModal" :title="modalDelete.title" v-model="modal"
                     @ok="deleteTic(modalDelete.content.uuid)">
                <p>Are you sure you wish to delete this Temporary Intelligence Capture job?</p>
                <small></small>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import booleanFilter from '../../utils/mixins/booleanFilter'
import localDatetime from '../../utils/mixins/localDatetime'
import nullFilter from '../../utils/mixins/nullFilter'
import { mapGetters } from 'vuex'

export default {
    name: 'TemporaryIntelligenceCaptureList',
    computed: {
        ...mapGetters({
            loading: 'temporaryIntelligenceCaptures/loading',
            tics: 'temporaryIntelligenceCaptures/tics'
        }),
    },
    data () {
        return {
            fields: [
                'profile',
                {
                    key: 'platform',
                    formatter: 'platformFormatter',
                },
                {
                    key: 'client_reference',
                    sortable: true,
                },
                {
                    key: 'active',
                    formatter: 'booleanFormatterIcons',
                },
                {
                    key: 'is_private',
                    formatter: 'booleanFormatterIcons',
                },
                {
                    key: 'max_stories',
                    formatter: 'nullNaFormatter',
                },
                {
                    key: 'created_at',
                    formatter: 'localDateTimeFormatter',
                },
                {
                    key: 'expires',
                    formatter: 'localDateTimeFormatter',
                },
                'actions',
            ],
            modal: false,
            modalDelete: {
                title: '',
                content: {},
            },
            sortBy: 'profile',
            sortDesc: false,
            tic: {}
        }
    },
    methods: {
        async getTic (uuid) {
            const { data } = axios.get(`/api/temporary-intelligence-capture/${uuid}`)
            this.tic = data.data
        },
        async deleteTic (uuid) {
            await axios.delete(`/api/temporary-intelligence-capture/${uuid}`)

            await this.$store.dispatch('temporaryIntelligenceCaptures/fetchTics')
        },
        info (item) {
            this.modalDelete.title = `Delete Temporary Intelligence Capture Confirmation`
            this.modalDelete.content = item
            this.modal = true
        },
        resetModal () {
            this.modalDelete.title = ''
        },
        async setActive (uuid, value) {
            await axios.patch(`/api/temporary-intelligence-capture/${uuid}`, { active: value })

            await this.$store.dispatch('temporaryIntelligenceCaptures/fetchTics')
        },
    },
    mixins: [
        booleanFilter,
        localDatetime,
        nullFilter,
    ],
}
</script>

<style scoped lang="scss">
    @import '../../../sass/variables';

    .card {
        &-body {
            text-align: center;

            table {
                thead {
                    color: $secondary-color;
                }

                button {
                    background: $danger-red;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }
            }
        }
    }
</style>
