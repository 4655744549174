<script setup>
import axios from 'axios';
import { computed, ref } from 'vue';
import { format, formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { ChevronRightIcon, PaperclipIcon, XIcon } from 'lucide-vue';
import Card from '@/components/pfp/ui/card/Card.vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import Tooltip from '@/components/pfp/ui/tooltip/Tooltip.vue';
import TransitionExpand from '@/components/pfp/transition-expand/TransitionExpand.vue';
import SourceListItem from '@/components/pfp/virtual-witness-modal/SourceListItem.vue';

const emit = defineEmits(['close']);

const props = defineProps({
    result: {
        type: Object,
        required: true,
    },
});

const selectedResult = ref(null);

const results = computed(() => {
    return props.result.results.filter(result => getMaterialCount(result) > 0);
});

const queryLabel = computed(() => {
    if (props.result.search.registration) {
        return 'Registration';
    }

    return 'Date';
});

const query = computed(() => {
    if (props.result.search.registration) {
        return props.result.search.registration;
    }

    return props.result.search.date;
});

function getMaterialCount(result) {
    return result.sources.reduce((acc, source) => {
        return acc + Object.keys(source.assets).length;
    }, 0);
}

function handleClick(uuid) {
    if (selectedResult.value === uuid) {
        selectedResult.value = null;
        return;
    }

    selectedResult.value = uuid;
}

function _constToString(constString) {
    return constString
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const locale = {
    ...enGB,
    formatRelative: (token) => {
        return {
            lastWeek: "'Last' eeee 'at' p",
            yesterday: "'Yesterday at' p",
            today: "'Today at' p",
            tomorrow: "'Tomorrow at' p",
            nextWeek: "eeee 'at' p",
            other: 'P p',
        }[token];
    },
};
</script>

<template>
    <div
        class="tw-fixed tw-top-0 tw-left-0 tw-bg-foreground/40 dark:tw-bg-background/80 tw-z-50 tw-w-full tw-h-screen tw-flex tw-justify-center tw-items-center tw-pl-[16.66666667%]"
        @click.self="emit('close')"
    >
        <Card
            class="tw-w-full md:tw-w-4/5 tw-overflow-y-auto tw-max-h-[75%] tw-p-4 md:tw-p-8 tw-relative tw-max-w-screen-lg"
        >
            <Button
                class="tw-absolute tw-top-4 tw-right-4"
                @click="emit('close')"
                aria-label="Close modal"
                variant="outline"
                size="icon"
            >
                <XIcon class="tw-w-6 tw-h-6" />
            </Button>
            <div>
                <div class="tw-px-0">
                    <h3
                        class="tw-text-base tw-font-semibold tw-leading-7 tw-text-foreground"
                    >
                        {{ props.result.client_reference }} results
                    </h3>
                    <p
                        class="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-muted-foreground"
                    >
                        List of results for the virtual witness search item
                        selected.
                    </p>
                </div>
                <div
                    class="tw-flex tw-flex-col tw-space-y-4 tw-divide-y tw-divide-muted"
                >
                    <div v-for="(res, ix) in results" :key="res.uuid">
                        <div
                            @click="handleClick(res.uuid)"
                            class="tw-cursor-pointer"
                        >
                            <h4
                                class="tw-mt-6 tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground tw-flex tw-items-center"
                            >
                                <ChevronRightIcon
                                    class="tw-w-6 tw-h-6 tw-mr-2 tw-transition-transform"
                                    :class="{
                                        'tw-rotate-90':
                                            selectedResult === res.uuid,
                                    }"
                                />
                                Result {{ ix + 1 }}
                            </h4>
                            <div
                                class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground tw-items-center tw-gap-y-2 tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-flex lg:tw-gap-x-6 md:tw-gap-y-0"
                            >
                                <p class="tw-p-0 tw-flex tw-items-center">
                                    Categories:
                                    <span
                                        class="tw-inline-flex tw-items-center tw-ml-1 tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-gray-100 tw-text-gray-800"
                                    >
                                        {{ res.categories.length }}
                                    </span>
                                </p>
                                <p class="tw-p-0 tw-flex tw-items-center">
                                    Locations:
                                    <span
                                        class="tw-inline-flex tw-items-center tw-ml-1 tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-gray-100 tw-text-gray-800"
                                    >
                                        {{ res.locations.length }}
                                    </span>
                                </p>
                                <p class="tw-p-0 tw-flex tw-items-center">
                                    Vehicles:
                                    <span
                                        class="tw-inline-flex tw-items-center tw-ml-1 tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-gray-100 tw-text-gray-800"
                                    >
                                        {{ res.vehicles.length }}
                                    </span>
                                </p>
                                <p class="tw-p-0 tw-flex tw-items-center">
                                    Materials:
                                    <span
                                        class="tw-inline-flex tw-items-center tw-ml-1 tw-px-2 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium tw-bg-gray-100 tw-text-gray-800"
                                    >
                                        {{ getMaterialCount(res) }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <TransitionExpand>
                            <div
                                class="tw-mt-6"
                                v-if="selectedResult === res.uuid"
                            >
                                <dl
                                    class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2"
                                >
                                    <div
                                        class="tw-border-t tw-border-gray-100 tw-px-4 tw-py-4 sm:tw-col-span-1 sm:tw-px-0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            {{ queryLabel }} searched
                                        </dt>
                                        <dd
                                            class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground sm:tw-mt-2"
                                        >
                                            {{ query }}
                                        </dd>
                                    </div>
                                    <div
                                        class="tw-border-t tw-border-gray-100 tw-px-4 tw-py-4 sm:tw-col-span-1 sm:tw-px-0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            Event categor{{
                                                res.categories.length > 1
                                                    ? 'ies'
                                                    : 'y'
                                            }}
                                        </dt>
                                        <dd
                                            class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground sm:tw-mt-2"
                                        >
                                            {{
                                                res.categories
                                                    .map(_constToString)
                                                    .join(', ')
                                            }}
                                        </dd>
                                    </div>
                                    <div
                                        class="tw-px-4 tw-py-4 sm:tw-col-span-1 sm:tw-px-0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            Event date
                                        </dt>
                                        <dd
                                            class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground sm:tw-mt-2"
                                        >
                                            <p>
                                                <Tooltip
                                                    hover
                                                    :hover-intent="400"
                                                    class="tw-block tw-w-full"
                                                >
                                                    <template
                                                        #trigger
                                                        class="w-full"
                                                    >
                                                        <span>{{
                                                            format(
                                                                new Date(
                                                                    res.datetime,
                                                                ),
                                                                'dd/MM/yyyy',
                                                            )
                                                        }}</span>
                                                    </template>
                                                    <template #content>
                                                        {{
                                                            formatRelative(
                                                                new Date(
                                                                    res.datetime,
                                                                ),
                                                                new Date(),
                                                                {
                                                                    locale,
                                                                },
                                                            )
                                                        }}
                                                    </template>
                                                </Tooltip>
                                                <span
                                                    class="tw-text-sm tw-block tw-text-muted-foreground"
                                                    >{{
                                                        format(
                                                            new Date(
                                                                res.datetime,
                                                            ),
                                                            'p',
                                                        )
                                                    }}</span
                                                >
                                            </p>
                                        </dd>
                                    </div>
                                    <div
                                        class="tw-px-4 tw-py-4 sm:tw-col-span-1 sm:tw-px-0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            Event type{{
                                                res.types.length > 1 ? 's' : ''
                                            }}
                                        </dt>
                                        <dd
                                            class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground sm:tw-mt-2"
                                        >
                                            {{
                                                res.types
                                                    .map(_constToString)
                                                    .join(', ')
                                            }}
                                        </dd>
                                    </div>
                                    <div
                                        v-if="res.locations.length > 0"
                                        class="tw-border-t tw-border-gray-100 tw-px-4 tw-py-4 sm:tw-col-span-2 sm:tw-px-0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            Locations
                                        </dt>
                                        <dd
                                            class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground sm:tw-mt-2"
                                        >
                                            <div
                                                class="tw-px-4 sm:tw-px-2 lg:tw-px-8"
                                            >
                                                <div
                                                    class="tw-mt-2 tw-flow-root"
                                                >
                                                    <div
                                                        class="-tw-mx-4 -tw-my-2 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8"
                                                    >
                                                        <div
                                                            class="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle"
                                                        >
                                                            <table
                                                                class="tw-min-w-full tw-divide-y tw-divide-transparent"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            class="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-foreground sm:tw-pl-0"
                                                                        >
                                                                            Name
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-foreground"
                                                                        >
                                                                            Postcode
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody
                                                                    class="tw-divide-y tw-divide-muted"
                                                                >
                                                                    <tr
                                                                        v-for="loc in res.locations"
                                                                        :key="
                                                                            loc.id
                                                                        "
                                                                    >
                                                                        <td
                                                                            class="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-foreground sm:tw-pl-0"
                                                                        >
                                                                            {{
                                                                                loc.name
                                                                            }}
                                                                        </td>
                                                                        <td
                                                                            class="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-muted-foreground"
                                                                        >
                                                                            {{
                                                                                loc.postcode
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div
                                        class="tw-border-t tw-border-gray-100 tw-px-4 tw-py-4 sm:tw-col-span-2 sm:tw-px-0"
                                        v-if="res.vehicles.length > 0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            Vehicles
                                        </dt>
                                        <dd
                                            class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-muted-foreground sm:tw-mt-2"
                                        >
                                            <div
                                                class="tw-px-4 sm:tw-px-2 lg:tw-px-8"
                                            >
                                                <div
                                                    class="tw-mt-2 tw-flow-root"
                                                >
                                                    <div
                                                        class="-tw-mx-4 -tw-my-2 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8"
                                                    >
                                                        <div
                                                            class="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle"
                                                        >
                                                            <table
                                                                class="tw-min-w-full tw-divide-y tw-divide-transparent"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            class="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-foreground sm:tw-pl-0"
                                                                        >
                                                                            Registration
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-foreground"
                                                                        >
                                                                            Year
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody
                                                                    class="tw-divide-y tw-divide-muted"
                                                                >
                                                                    <tr
                                                                        v-for="vehicle in res.vehicles"
                                                                        :key="
                                                                            vehicle.id
                                                                        "
                                                                    >
                                                                        <td
                                                                            class="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-foreground sm:tw-pl-0"
                                                                        >
                                                                            {{
                                                                                vehicle.registration
                                                                            }}
                                                                        </td>
                                                                        <td
                                                                            class="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-muted-foreground"
                                                                        >
                                                                            {{
                                                                                vehicle.year
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div
                                        v-if="res.sources.length > 0"
                                        class="tw-border-t tw-border-gray-100 tw-px-4 tw-pt-4 sm:tw-col-span-2 sm:tw-px-0"
                                    >
                                        <dt
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-foreground"
                                        >
                                            Sources
                                            <p
                                                class="tw-text-sm tw-text-destructive tw-block tw-font-normal"
                                            >
                                                Please note that you will be
                                                invoiced in line with your
                                                service agreement by viewing or
                                                downloading the materials.
                                            </p>
                                        </dt>
                                        <dd
                                            class="tw-mt-2 tw-text-sm tw-text-foreground"
                                        >
                                            <ul
                                                role="list"
                                                class="tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-gray-200"
                                            >
                                                <SourceListItem
                                                    v-for="source in res.sources"
                                                    :key="source.uuid"
                                                    :source="source"
                                                    :vwId="props.result.uuid"
                                                    :resultId="res.uuid"
                                                />
                                            </ul>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </TransitionExpand>
                    </div>
                </div>
            </div>
        </Card>
    </div>
</template>
