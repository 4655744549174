<script setup>
import { onMounted } from "vue";
import { InfoIcon } from "lucide-vue";
import usePfpStore from "@/pinia/pfp";
import useTheme from "@/composables/useTheme";
import QueryStepper from "@/components/pfp/query-stepper/QueryStepper.vue";
import ResultsTable from "@/components/pfp/results-table/ResultsTable.vue";
import ModeToggle from "@/components/pfp/mode-toggle/ModeToggle.vue";
import Card from "@/components/pfp/ui/card/Card.vue";

useTheme();

const store = usePfpStore();

onMounted(() => {
    store.init();
});
</script>

<template>
    <div
        id="pfp2"
        class="tw-flex tw-flex-col tw-gap-y-10 tw-items-center tw-w-full tw-my-6"
    >
        <ModeToggle class="tw-absolute tw-right-2 tw-top-0" />
        <QueryStepper />
        <div class="tw-relative tw-w-full">
            <div
                class="tw-relative tw-overflow-visible tw-w-full -tw-z-10 tw-flex tw-items-center tw-justify-center"
            >
                <div
                    class="tw-aspect-square tw-w-full md:tw-w-4/5 tw-fixed tw-bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(56,189,248,0.12)_6.77%,_rgba(124,201,235,0.05)_46.35%,_rgba(217,217,217,0)_100%)] tw-dark:tw-bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(56,_189,_248,_0.37)_6.77%,_rgba(124,_201,_235,_0.21)_46.35%,_rgba(217,_217,_217,_0)_100%)]"
                />
            </div>
            <Card class="tw-p-6 tw-w-full tw-max-w-[1200px] tw-mx-auto">
                <ResultsTable />
            </Card>
        </div>

        <div
            class="tw-fixed tw-right-2 tw-bottom-2 tw-flex-col-reverse tw-max-w-[320px] tw-w-full"
        >
            <div
                v-for="notif in store.notifications.active"
                :key="notif.id"
                class="tw-relative tw-w-full tw-rounded-lg tw-border tw-p-4 [&amp;>svg~*]:tw-pl-7 [&amp;>svg+div]:tw-translate-y-[-3px] [&amp;>svg]:tw-absolute [&amp;>svg]:tw-left-4 [&amp;>svg]:tw-top-4 [&amp;>svg]:tw-text-foreground tw-bg-background tw-text-foreground tw-flex tw-gap-x-2 tw-mt-2"
            >
                <InfoIcon class="tw-w-4" />
                <div>
                    <h5
                        class="tw-mb-1 tw-font-medium tw-leading-none tw-tracking-tight"
                    >
                        {{ notif.title }}
                    </h5>
                    <div class="tw-text-sm [&amp;_p]:tw-leading-relaxed">
                        {{ notif.message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
