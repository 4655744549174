<template>
    <div class="container-fluid">
        <b-row>
            <b-col cols="12" class="px-0">
                <profile-finder-search/>
            </b-col>
            <b-col cols="12" v-if="results">
                <profile-finder-results/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import ProfileFinderResults from '../components/ProfileFinderResults'
    import ProfileFinderSearch from '../components/ProfileFinderSearch'
    import { mapGetters, mapMutations } from 'vuex'

    export default {
        name: 'ProfileFinder',
        beforeRouteEnter (to, from, next) {
            next(async vm => {
                await vm.$store.dispatch('profileFinder/fetchProfileFinders')
            })
        },
        computed: {
            ...mapGetters({
                hasResults: 'profileFinder/hasResults',
                results: 'profileFinder/results'
            })
        },
        components: {
            ProfileFinderResults,
            ProfileFinderSearch
        },
        middleware: 'auth',
        methods: {
            ...mapMutations({
                showResults: 'profileFinders/RESET_RESULTS'
            })
        }
    }
</script>

<style scoped>

</style>
