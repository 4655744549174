<script setup>
import { defineProps, ref, computed } from 'vue';
import { ClipboardCopyIcon, ExternalLinkIcon, EyeIcon, EyeOffIcon, ImageIcon } from 'lucide-vue';
import { copyToClipboard, uppercaseFirstLetter } from '@/utils/lib';
import usePfpStore from '@/pinia/pfp';
import Card from '@/components/pfp/ui/card/Card.vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import SourceTags from '@/components/pfp/source-tags/SourceTags.vue';
import TransitionExpand from '../../../transition-expand/TransitionExpand.vue';

const props = defineProps({
  // {
  //   id: number
  //   name: string
  //   url: string
  //   sources: ('nwData' | 'nwOSINT' | 'nwGumtree' | 'pipl' | 'leakedDatasets')[]
  // }
  data: {
    type: Object,
    default: () => ({}),
  },
});

const store = usePfpStore();
const imgError = ref(false);
const open = ref(false);

const attributes = computed(() => {
  if (!props.data.otherAttributes) {
    return [];
  }

  return Object.values(props.data.otherAttributes).filter((attr) => {
    return Boolean(attr.value);
  });
});

function handleClipboard(val) {
  copyToClipboard(val);
  store.notifications.addNotification({
    id: Date.now(),
    title: 'Profile attribute copied',
    message: val,
  });
}

function handleImageError(e) {
  imgError.value = true;
}
</script>

<template>
  <Card class="">
    <div class="tw-flex tw-justify-between tw-gap-x-4 tw-p-4 tw-flex-wrap md:tw-flex-nowrap">
      <div class="tw-flex tw-gap-x-3 tw-flex-col sm:tw-flex-row">
        <div class="tw-w-20 tw-h-20">
          <img v-if="props.data.image && !imgError" :src="props.data.image" class="tw-w-20 tw-h-20 tw-rounded-full" @error="handleImageError" />
          <p v-else class="tw-w-20 tw-h-20 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-200 tw-text-gray-500 tw-text-2xl">
            <ImageIcon class="tw-w-7 tw-h-7" />
          </p>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-3 tw-items-start tw-relative tw-p-1 tw-pr-8">
          <h4 class="tw-text-xl tw-font-semibold">{{ uppercaseFirstLetter(props.data.network) }}</h4>

          <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
            <p v-if="props.data.name" class="tw-text-lg tw-font-semibold tw-leading-none">{{ props.data.name }}</p>
            <p v-if="props.data.email" class="tw-text tw-font-light tw-leading-none">{{ props.data.email }}</p>
            <p v-if="props.data.phone" class="tw-text tw-font-light tw-leading-none">{{ props.data.phone }}</p>
          </div>
        </div>
      </div>
      

      <div class="tw-flex tw-flex-col tw-items-end tw-justify-end tw-grow lg:tw-grow-0 lg:tw-w-1/2 tw-gap-y-2 tw-w-full tw-mt-3 md:tw-mt-0" :class="{ 'tw-justify-between': !props.data.url }">
        <SourceTags :data="[props.data.source]" />

        <Button v-if="props.data.url" class="tw-w-full tw-max-w-[400px]" as="a" :href="props.data.url">
          <ExternalLinkIcon class="tw-w-5 tw-h-5 tw-mr-2" /> View profile
        </Button>

        <Button v-if="attributes.length > 0" variant="secondary" class="tw-w-full tw-max-w-[400px]" @click="open = !open">
          <EyeOffIcon v-if="open" class="tw-w-5 tw-h-5 tw-mr-2" />
          <EyeIcon v-else class="tw-w-5 tw-h-5 tw-mr-2" /> View details
        </Button>
      </div>

    </div>
    
    <TransitionExpand>
      <div v-if="open">
        <div class="tw-w-full tw-h-px tw-bg-muted tw-mb-4" />
        <div class="tw-flex tw-flex-wrap tw-p-2 tw-gap-y-3 tw-gap-x-6 tw-overflow-y-auto tw-overflow-x-hidden tw-max-h-[260px]">
          <div v-for="attr in attributes" class="tw-relative tw-group tw-min-w-[70px]">
            <p class="tw-font-semibold">{{ attr.label }}</p>
            <p class="tw-font-light">{{ attr.value }}</p>
            
            <Button v-if="attr.value" class="tw-absolute tw-right-1 tw-bottom-1 tw-hidden group-hover:tw-flex" @click="handleClipboard(attr.value)" size="icon" variant="outline">
              <ClipboardCopyIcon class="tw-w-5 tw-h-5" />
            </Button>
          </div>
        </div>
      </div>
    </TransitionExpand>
  </Card>
</template>
