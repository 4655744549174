import axios from 'axios'

export const state = {
    loading: false,
    platforms: [
        {
            value: 'instagram',
            text: 'Instagram',
        }
    ],
    tics: []
}

export const getters = {
    loading: state => state.loading,
    tics: state => state.tics,
    hasTics: state => !!state.tics.length
}

export const mutations = {
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_TICS (state, payload) {
        state.tics = payload
    }
}

export const actions = {
    async fetchTics ({ commit }) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(`/api/temporary-intelligence-capture`)

            commit('SET_TICS', data.data)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
