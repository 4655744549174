import axios from 'axios'
import Form from 'vform';

export const state = {
    loading: false,
    virtualWitness: null,
    form: new Form({
        client_reference: '',
        date: null,
        registration: null,
    })
}

export const getters = {
    form: state => state.form,
    hasVirtualWitness: state => state.virtualWitness !== null,
    virtualWitness: state => state.virtualWitness,
    clientReference: state => state.form.client_reference
}

export const mutations = {
    SET_LOADING(state, value) {
        state.loading = value
    },
    SET_VIRTUAL_WITNESS(state, virtualWitness) {
        state.virtualWitness = virtualWitness
    },
    RESET_VIRTUAL_WITNESS(state) {
        state.virtualWitness = null
    },
    SET_CLIENT_REFERENCE(state, clientReference) {
        state.form.client_reference = clientReference
    },
    SET_DATE(state, date) {
        state.form.date = date
    },
    SET_REGISTRATION(state, registration) {
        state.form.registration = registration
    },
}

export const actions = {
    async fetchVirtualWitness({commit}, id) {
        commit('SET_LOADING', true)

        const {data} = await axios.get(`/api/virtual-witness/${id}`)

        commit('SET_VIRTUAL_WITNESS', data.data)
        commit('SET_LOADING', false)
    },
    async saveVirtualWitness({commit}) {
        commit('SET_LOADING', true)

        const {data} = await state.form.post(`/api/virtual-witness`)

        commit('SET_VIRTUAL_WITNESS', data.data)
        commit('SET_LOADING', false)
    }
}
