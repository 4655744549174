<script setup>
import { computed } from 'vue';
import { PlusCircleIcon } from 'lucide-vue';
import { SOURCE_LABELS } from '@/components/pfp/const';
import Button from '@/components/pfp/ui/button/Button.vue';
import ResultSection from '@/components/pfp/results-table/ResultSection.vue';
import ResultTitle from '@/components/pfp/results-table/ResultTitle.vue';
import SourceTags from '@/components/pfp/source-tags/SourceTags.vue';

const props = defineProps({
  source: {
    type: String,
    required: true,
  }
})

const emit = defineEmits(['add:source']);

const label = computed(() => {
  return SOURCE_LABELS[props.source];
})

function handleAddSource() {
  emit('add:source', props.source);
}
</script>

<template>
  <ResultSection class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-gap-2">
    <div>
      <ResultTitle>
        <SourceTags :data="[props.source]" />
        Add another source?
      </ResultTitle>
      <p class="tw-text-muted-foreground tw-font-light">
        You didn't add <span class="tw-text-foreground tw-font-normal">{{ label }}</span> as a source on your original query.
      </p>
      <p class="tw-text-muted-foreground tw-font-light -tw-mt-1">
        By adding more sources you can widen your search drastically.
      </p>
    </div>

    <Button class="tw-w-full md:tw-w-auto" @click="handleAddSource">
      <PlusCircleIcon class="tw-mr-2 tw-w-5 tw-h-5" />
      Add {{ label }} as a source
    </Button>
  </ResultSection>
</template>