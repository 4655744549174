import axios from 'axios'
import { Form } from 'vform'
import { get, isEmpty } from 'lodash'

export const state = {
    form: new Form({
        query: ''
    }),
    searching: false,
    searchResults: [],
    team: {},
    loading: false,
}

export const getters = {
    hasSearchResults: state => !!state.searchResults.length,
    searchResults: state => state.searchResults,
    team: state => state.team,
    loading: state => state.loading,
    searching: state => state.searching,
    hasTeam: state => !isEmpty(state.team),
    onGenericTrial: state => get(state, 'team.trial_ends_at', null),
    teamTools: state => {
        return Object.values(get(state.team, 'tools', {}))
    },
    teamMembers: state => state.team.users
}

export const mutations = {
    RESET_SEARCH_RESULTS (state) {
        state.searchResults = []
    },
    SET_SEARCH_RESULTS (state, payload) {
        state.searchResults = payload
    },
    UPDATE_QUERY (state, payload) {
        state.form.query = payload
    },
    SET_SEARCHING (state, payload) {
        state.searching = payload
    },
    SET_TEAM (state, payload) {
        state.team = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    RESET_TEAM (state) {
        state.team = {}
    }
}

export const actions = {
    async search ({ commit, state }) {
        commit('SET_SEARCHING', true)
        commit('SET_LOADING', true)

        const { data } = await state.form.post(`/api/kiosk/teams/search`)

        commit('SET_SEARCH_RESULTS', data)
        commit('SET_SEARCHING', false)
        commit('SET_LOADING', false)
    },
    async fetchTeam ({ commit, state }, id) {
        const { data } = await axios.get(`/api/kiosk/teams/${id}/profile`)

        commit('SET_TEAM', data.team)
    }
}
