import axios from 'axios'

export const state = {
    loading: false,
    teams: []
}

export const getters = {
    hasTeams: state => !!state.teams.length,
    teams: state => state.teams
}

export const mutations = {
    SET_LOADING (data, payload) {
        state.loading = payload
    },
    SET_TEAMS (state, payload) {
        state.teams = payload
    }
}

export const actions = {
    async fetchAllTeams ({ commit }) {
        commit('SET_LOADING', true)

        const { data } = axios.get(`/api/teams`)

        commit('SET_TEAMS', data)
        commit('SET_LOADING', true)
    }
}
