import axios from 'axios'

export const state = {
    invitations: [],
    loading: false,
}

export const getters = {
    invitations: state => state.invitations,
    hasInvitations: state => !!state.invitations.length,
    loading: state => state.loading,
}

export const mutations = {
    SET_INVITATIONS (state, payload) {
        state.invitations = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    }
}

export const actions = {
    async fetchInvitations ({ commit }, teamId) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(
                `/api/settings/teams/${teamId}/invitations`
            )

            commit('SET_INVITATIONS', data)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async acceptInvitation ({ commit, dispatch }, invite) {
        commit('SET_LOADING', true)

        try {
            await axios.post(
                `/api/settings/invitations/${invite.id}/accept`
            )
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)

            await dispatch('auth/fetchUser')
            await dispatch('fetchInvitations')
        }
    },
    async rejectInvitation ({ commit, dispatch }, invite) {
        commit('SET_LOADING', true)

        try {
            await axios.post(
                `/api/settings/invitations/${invite.id}/reject`
            )
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)

            await dispatch('auth/fetchUser')
            await dispatch('fetchInvitations')
        }
    }
}
