<template>
    <div class="row">
        <div class="col-12">
            <b-overlay :show="loading">
                <dl>
                    <dt>
                        Profile
                    </dt>
                    <dd>
                        {{ tic.profile }}
                    </dd>

                    <dt>
                        Last Checked
                    </dt>
                    <dd>
                        {{ tic.last_checked_at | localDatetime }}
                    </dd>

                    <dt>
                        Active
                    </dt>
                    <dd>
                        {{ tic.active | booleanYesNo }}
                    </dd>

                    <dt>
                        Maximum Stories
                    </dt>
                    <dd>
                        {{ tic.max_stories }}
                    </dd>
                </dl>
                <timeline-stories :stories="tic.stories"></timeline-stories>
            </b-overlay>
        </div>
    </div>
</template>

<script>
    import booleanFilter from '../../utils/mixins/booleanFilter'
    import TimelineStories from '../../components/partials/TimelineStories'
    import localDatetime from '../../utils/mixins/localDatetime'
    import { mapGetters } from 'vuex'

    export default {
        name: 'TemporaryIntelligenceCaptureProfile',
        components: {
            TimelineStories
        },
        beforeRouteEnter (to, from, next) {
            next(async vm => {
                const uuid = vm.$route.params.uuid

                await vm.$store.dispatch('temporaryIntelligenceCapture/fetchTic', uuid)
            })
        },
        computed: {
            ...mapGetters({
                loading: 'temporaryIntelligenceCapture/loading',
                tic: 'temporaryIntelligenceCapture/tic'
            })
        },
        mixins: [
            booleanFilter,
            localDatetime
        ]
    }
</script>
