<template>
    <b-card class="card-default mb-4">
        <template #header>
            {{ $t('create_new_privacy_check') }}
        </template>
        <b-form @submit.prevent="submitPrivacyChecker">
            <b-form-row>
                <b-col cols="6">
                    <b-form-group id="input-group__username"
                                  label-for="input__username">
                        <b-form-input id="input__username"
                                      type="text"
                                      :placeholder="$t('username')"
                                      v-model="username"
                                      :class="{'is-invalid': form.errors.has('username')}"
                                      trim
                        />
                        <has-error :form="form"
                                   field="username"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group id="input-group__type"
                                  label-for="input__type">
                        <b-form-select v-model="type"
                                       :options="types"
                                       :class="{'is-invalid': form.errors.has('type')}"
                        />
                        <has-error :form="form"
                                   field="type"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group id="input-group__client-reference"
                                  label-for="input-group__client-reference">
                        <b-form-input id="input__client-reference"
                                      type="text"
                                      :placeholder="$t('client_reference')"
                                      v-model="client_reference"
                                      :class="{'is-invalid': form.errors.has('client_reference')}"
                                      trim
                        />
                        <has-error :form="form"
                                   field="client_reference"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <div class="instruction-duration">
                        <b-form-group id="input-group__duration"
                                      :label="$t('duration_months')"
                                      label-for="input__duration">
                            <b-form-input id="input__duration"
                                          class="range-slider"
                                          type="range"
                                          value="1"
                                          min="1"
                                          max="12"
                                          v-model="duration"
                                          :class="{'is-invalid': form.errors.has('duration')}"
                                          trim
                            />
                            <has-error :form="form"
                                       field="duration"
                            />
                            <span>{{ form.duration }}</span>
                        </b-form-group>
                    </div>
                </b-col>
                <b-col cols="12">
                    <b-button type="submit" variant="primary" :disabled="form.busy">
                        {{ $t('check_privacy') }}
                    </b-button>
                </b-col>
            </b-form-row>
        </b-form>
    </b-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'FormPrivacyCheck',
        computed: {
            ...mapGetters({
                loading: 'privacyChecker/loading',
                submitting: 'privacyChecker/submitting',
                form: 'privacyChecker/form'
            }),
            username: {
                get () {
                    return this.$store.state.privacyChecker.form.username
                },
                set (value) {
                    this.$store.commit('privacyChecker/SET_FORM_USERNAME', value)
                }
            },
            client_reference: {
                get () {
                    return this.$store.state.privacyChecker.form.client_reference
                },
                set (value) {
                    this.$store.commit('privacyChecker/SET_FORM_CLIENT_REFERENCE', value)
                }
            },
            duration: {
                get () {
                    return this.$store.state.privacyChecker.form.duration
                },
                set (value) {
                    this.$store.commit('privacyChecker/SET_FORM_DURATION', value)
                }
            },
            type: {
                get () {
                    return this.$store.state.privacyChecker.form.type
                },
                set (value) {
                    this.$store.commit('privacyChecker/SET_FORM_TYPE', value)
                }
            },
            types () {
                return [
                    {
                        value: null,
                        text: this.$t('social_network')
                    },
                    {
                        label: this.$t('social_networks'),
                        options: this.$store.getters['privacyChecker/types'].map(type => {
                            return {
                                value: type,
                                text: type
                            }
                        })
                    }
                ]
            }
        },
        methods: {
            async submitPrivacyChecker () {
                await this.$store.dispatch('privacyChecker/submitPrivacyChecker')
                //TODO: Privacy Check confirmed swal
            },
        },
    }
</script>

<style scoped lang="scss">
    @import '../../sass/variables';

    .instruction-duration {
        position: relative;

        span {
            position: absolute;
            right: 9px;
            top: 0;
            color: $text-gray;
        }
    }
</style>
