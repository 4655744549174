<template>
    <div>
        <particles-bg type="cobweb" bg/>
        <navbar-guest/>
        <b-container :fluid="true">
            <main>
                <b-container>
                    <b-row class="justify-content-center">
                        <b-col>
                            <child/>
                        </b-col>
                    </b-row>
                </b-container>
            </main>
        </b-container>
    </div>
</template>

<script>
import NavbarGuest from '../components/NavbarGuest'
import Child from '../components/Child'
import { ParticlesBg } from 'particles-bg-vue'

export default {
    name: 'auth',
    components: {
        NavbarGuest,
        Child,
        ParticlesBg
    }
}
</script>
