<script setup>
import { ref, computed, defineProps, watch } from 'vue'
import { ClipboardCopyIcon } from 'lucide-vue'
import { useClipboard } from '@vueuse/core'
import Button from '@/components/pfp/ui/button/Button.vue'
import { flattenObject } from '@/utils/lib'
import usePfpStore from '@/pinia/pfp'

const props = defineProps({
  data: {
    type: [Object, Array],
    required: true,
  },
  flatten: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const { copy } = useClipboard()
const store = usePfpStore()

const hovered = ref(null)

const attributes = computed(() => {
  const flat = flattenObject(props.data)

  const data = Object.keys(flat).map((key) => ({
    name: key,
    value: flat[key],
  }))

  return data
})

const getAttributeValue = (value) => {
  if (value === null || value === undefined) {
    return ''
  }

  if (typeof value === 'string') {
    return value
  }

  if (typeof value === 'object') {
    return value.join(', ')
  }

  return value.toString() || ''
}

const handleClipboard = (value) => {
  const val = getAttributeValue(value)
  copy(val)

  store.notifications.addNotification({
    id: new Date().getTime(),
    title: 'Copied to clipboard',
    message: val,
    type: 'success',
  });
}


</script>

<template>
  <div @mouseleave="hovered = null" class="tw-relative">
    <div
      v-for="(attribute, ix) in attributes"
      :key="attribute.name"
      class="tw-grid tw-grid-cols-3 tw-relative tw-group tw-transition-opacity tw-overflow-auto"
      :class="{
        'tw-opacity-40': hovered !== null && hovered !== ix,
        'tw-opacity-100': hovered === null || hovered === ix,
      }"
      @mouseenter="hovered = ix"
    >
      <div
        class="tw-bg-muted tw-col-span-1 tw-p-4 tw-text-muted-foreground tw-text-sm tw-z-10 group-hover:tw-bg-muted/30 tw-break-words"
        :class="{
          'tw-rounded-t-xl': ix === 0,
          'tw-rounded-b-xl': ix === attributes.length - 1,
        }"
      >
        {{ attribute.name }}
      </div>

      <div class="tw-col-span-2 tw-p-4 tw-text-sm tw-z-10 tw-break-words">{{ getAttributeValue(attribute.value) }}</div>

      <Button
        size="icon"
        variant="outline"
        class="tw-absolute tw-right-1.5 tw-top-1.5 tw-opacity-0 tw-pointer-events-none group-hover:tw-opacity-100 group-hover:tw-pointer-events-auto tw-transition-opacity tw-z-10"
        @click="handleClipboard(attribute.value)"
      >
        <ClipboardCopyIcon class="tw-w-5 tw-h-5" />
      </Button>

      <div class="tw-w-full tw-h-full tw-absolute tw-bg-muted tw-rounded-xl tw-opacity-0 tw-transition-opacity tw-pointer-events-none group-hover:tw-opacity-100 group-hover:tw-pointer-events-auto" />
    </div>
  </div>
</template>
