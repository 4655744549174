import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { SOURCE_LABELS, SOURCE } from '../components/pfp/const';

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export function copyToClipboard(string) {
    if (!navigator.clipboard) {
        // FALLBACK TO EXECUTING COPY COMMAND
        document.execCommand('copy', true, string);
    }

    navigator.permissions.query({name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard.writeText(string);
        }
    });
}

export function uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function snakeCaseToSentenceCase(string) {
    if (!string || string === '_') {
        return '';
    }

    if (!string.includes('_')) {
        return string[0].toUpperCase() + string.slice(1);
    }

    return string.split('_').filter(Boolean).map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
}

export function lowerCamelCaseToSentenceCase(string) {
    if (!string) {
        return '';
    }
    
    return string.split(/(?=[A-Z])/).map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
}

export function formatAddressToLines(address) {
    return address.split(',').map((line, index, array) => {
        if (index === array.length - 1) {
            return line;
        }

        return `${line},`;
    });
}

export function sourceToLabel(source) {
    const label = SOURCE_LABELS[source];

    if (!label) {
        return lowerCamelCaseToSentenceCase(source);
    }

    return label;
}

export function serverSourceToClientSource(source) {
    switch (source) {
        case 'dataleaks_two':
            return SOURCE.LEAKED_DATASETS;
        case 'dataleaks':
            return SOURCE.LEAKED_DATASETS_OLD;
        case 'gumtree':
            return SOURCE.NW_GUMTREE;
        case 'osintIndustries':
            return SOURCE.NW_OSINT;
        case 'facebook':
        case 'linkedin':
            return SOURCE.NW_DATA;
        case 'pipl':
            return SOURCE.PIPL;
        default:
            return undefined;
    }
}

export function clientSourceToServerSource(source) {
    switch (source) {
        case SOURCE.LEAKED_DATASETS:
            return 'dataleaks_two';
        case SOURCE.LEAKED_DATASETS_OLD:
            return 'dataleaks';
        case SOURCE.NW_GUMTREE:
            return 'gumtree';
        case SOURCE.NW_OSINT:
            return 'osintIndustries';
        case SOURCE.NW_DATA:
            return ['facebook', 'linkedin'];
        case SOURCE.PIPL:
            return 'pipl';
        default:
            return undefined;
    }
}

export function flattenObject(obj) {
    const flattened = {};

    function recurse(current, path = []) {
        if (Object(current) !== current) {
            flattened[path.join('.')] = current;
        } else {
            Object.entries(current).forEach(([key, value]) => {
                recurse(value, [...path, key]);
            });
        }
    }

    recurse(obj);

    return flattened;
}
