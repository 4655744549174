import { ref, computed } from 'vue';

export function useFilters() {
    const pageMeta = ref({});

    const hasNextPage = computed(() => {
        if (!pageMeta.value) {
            return false;
        }

        return pageMeta.value.last_page > pageMeta.value.current_page;
    });

    function updatePageMeta(meta) {
        pageMeta.value = meta;
    }

    return {
        // data
        pageMeta,

        // computed
        hasNextPage,

        // actions
        updatePageMeta,
    };
}
