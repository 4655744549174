<template>
    <div>
        <b-alert :show="hasMessage" variant="danger">
            {{ message }}
        </b-alert>
        <b-form @submit.prevent="search" class="py-4">
            <b-row>
                <b-col cols="6">
                    <b-form-group id="input-group__property_address"
                                  description="Address of the property to search for"
                                  label="Property Address"
                                  label-for="input__property-address">
                        <b-form-input type="text"
                                      id="input__property-address"
                                      v-model="pricePaidsForm.property_address"
                                      trim>
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-end mt-3 pr-4">
                <b-button type="submit" variant="primary">
                    Search
                </b-button>
            </div>
        </b-form>
        <template v-if="hasPricePaids">
            <b-list-group>
                <b-list-group-item v-for="(pricePaid, key) in pricePaids" :key="key">
                    <b-row>
                        <b-col cols="12" class="d-flex align-items-center">
                            <b-icon-house class="mr-3"></b-icon-house>
                            <span class="h5 m-0">{{ pricePaid.property_address }}</span>
                        </b-col>
                        <b-col cols="12">
                            <p class="my-2">{{ statement(pricePaid) }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p>
                                <b>Lease Type</b><br>
                                {{ pricePaid.duration }}
                            </p>
                        </b-col>
                        <b-col>
                            <p>
                                <b>New or Existing build</b><br>
                                {{ pricePaid.old_new }}
                            </p>
                        </b-col>
                        <b-col>
                            <p>
                                <b>Price Paid Entry Category</b><br>
                                {{ pricePaid.ppd_category_type }}
                            </p>
                        </b-col>
                        <b-col>
                            <p>
                                <b>Property Type</b><br>
                                {{ pricePaid.property_type }}
                            </p>
                        </b-col>
                        <b-col>
                            <p>
                                <b>Price Paid Record Status</b><br>
                                {{ pricePaid.record_status }}
                            </p>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </template>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'PricePaidTab',
        computed: {
            ...mapGetters({
                hasMessage: 'landRegistry/hasMessage',
                hasPricePaids: 'landRegistry/hasPricePaids',
                message: 'landRegistry/message',
                pricePaids: 'landRegistry/pricePaids',
            }),
            ...mapState({
                pricePaidsForm: state => state.landRegistry.pricePaidsForm,
            }),
        },
        methods: {
            async search () {
                return await this.$store.dispatch('landRegistry/fetchPricePaids')
            },
            statement (pricePaid) {
                const price = this.$options.filters.currency(pricePaid.price)
                return `This property is listed as having sold for ${price} on the ${pricePaid.date_of_transfer}.`
            },
        }
    }
</script>

<style scoped>

</style>
