<script setup>
import { computed, ref } from 'vue';
import { PlusIcon, MinusIcon } from 'lucide-vue'
import Label from '@/components/pfp/ui/label/Label.vue'
import Input from '@/components/pfp/ui/input/Input.vue'
import SourceFilter from '@/components/pfp/source-filter/SourceFilter.vue'
import ResultSection from '@/components/pfp/results-table/ResultSection.vue'
import ResultTitle from '@/components/pfp/results-table/ResultTitle.vue'
import { SOURCE, SOURCES } from '@/components/pfp/const'
import TransitionExpand from '../../../transition-expand/TransitionExpand.vue';
import AdditionalInfoSection from '@/components/pfp/results-table/modules/additional-info/AdditionalInfoSection.vue'
import { useDebounceFn } from '@vueuse/core';
import Button from '../../../ui/button/Button.vue';

const DEFAULT_SELECTED_SOURCES = SOURCES.reduce((acc, source) => {
  if ([SOURCE.PIPL, SOURCE.NW_GUMTREE].includes(source)) {
    return acc;
  }

  acc[source] = true;
  return acc;
}, {});

const props = defineProps({
  sources: {
    type: Array,
    required: true,
  },
  networks: {
    type: Array,
    required: true,
  },
});

const open = ref(false);
const search = ref('');
const selectedSources = ref({ ...DEFAULT_SELECTED_SOURCES })

const filteredNetworks = computed(() => {
  return props.networks.filter(network => {
    return network.sources.some(source => selectedSources.value[source]) || selectedSources.value.none;
  });
});

const filteredNetworksChunked = computed(() => {
  return filteredNetworks.value.reduce((acc, network, ix) => {
    // odd and even
    const chunk = ix % 2 ? 1 : 0;

    acc[chunk].push(network);

    return acc;
  }, [[], []]);
});

const handleSearch = useDebounceFn(function(query) {
  search.value = query;
}, 500);

function handleSelectSource(source) {
  if (!Object.keys(DEFAULT_SELECTED_SOURCES).includes(source)) {
    console.error(`Invalid source for AdditionalInfoModule: ${source}`);
    return;
  }

  console.log(source);

  selectedSources.value[source] = !selectedSources.value[source];
}

function handleClearFilters() {
  selectedSources.value = { ...DEFAULT_SELECTED_SOURCES };
  search.value = '';
}
</script>

<template>
  <ResultSection v-if="props.networks.length">
    <div class="tw-flex tw-justify-between tw-items-top tw-relative">
      <ResultTitle class="tw-cursor-pointer" @click="open = !open">
        <div class="tw-w-5 tw-h-5">
          <PlusIcon v-if="!open" class="tw-w-full tw-h-full" />
          <MinusIcon v-else class="tw-w-full tw-h-full" />
        </div>
        Additional information
      </ResultTitle>
      
      <Button
        class="opacity-100 tw-transition-opacity tw-absolute tw-top-0 tw-right-0"
        :class="{ 'tw-opacity-0 tw-pointer-events-none': !open }"
        variant="outline"
        @click="handleClearFilters"
      >
        Clear filters
      </Button>
    </div>

    <TransitionExpand>
      <div v-if="open">
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6 tw-pt-6">
          <div class="tw-basis-full lg:tw-basis-2/3">
            <Label>
              Search by reference or query
              <span class="tw-text-xs tw-block tw-text-muted-foreground tw-mt-2">
                Enter a search term below to filter the results based on the label or value, e.g. "email" or "john".
              </span>
            </Label>
            <Input placeholder="Search..." @input="handleSearch" :value="search" />
          </div>
    
          <div class="tw-basis-full lg:tw-basis-1/3">
            <Label>
              Sources matched
              <span class="tw-text-xs tw-block tw-text-muted-foreground tw-mt-2">Toggle sources below to filter results</span>
            </Label>
            
            <SourceFilter @select="handleSelectSource" :selected="selectedSources" :show-none="false" :sources="props.sources" :hide-sources="[SOURCE.PIPL, SOURCE.NW_GUMTREE]" />
          </div>
          
        </div>

        <div class="tw-gap-4 tw-mt-6 tw-grid tw-grid-cols-12">
          <p v-if="!filteredNetworksChunked[0].length" class="tw-col-span-12">No results found</p>
          <div v-for="(chunk, ix) in filteredNetworksChunked" :key="ix" class="tw-col-span-12 md:tw-col-span-6">
            <AdditionalInfoSection
              v-for="network in chunk"
              :key="network.name"
              :name="network.name"
              :data="network.raw"
              :source="network.sources[0]"
              :query="search"
              hide-on-no-results
              class="tw-mt-4"
            />
          </div>
        </div>
      </div>
    </TransitionExpand>
  </ResultSection>
</template>
