<template>
    <nav class="navbar navbar-expand-md navbar-light navbar-laravel mb-4">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{name: 'home'}">
                Netwatch Global Toolsuite
            </router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false"
                    :aria-label="$t('toggle_navigation')">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- Right Side Of Navbar -->
                <ul class="navbar-nav ml-auto">
                    <!-- Authentication Links -->
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{name: 'login'}">{{ $t('login') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{name: 'register'}">{{ $t('register') }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'NavbarGuest'
    }
</script>
