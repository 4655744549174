<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import Badge from '@/components/pfp/ui/badge/Badge.vue'
import { SOURCES } from '@/components/pfp/const'
import { sourceToLabel } from '@/utils/lib'

const emit = defineEmits(['select'])

const props = defineProps({
  selected: {
    type: Object,
    required: true,
  },
  sources: {
    type: Array,
    required: false,
  },
  hideSources: {
    type: Array,
    required: false,
    default: () => [],
  },
  showNone: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const sources = computed(() => {
  let _sources = SOURCES;

  if (props.sources) {
    _sources = props.sources;
  }

  if (props.hideSources.length === 0) {
    return _sources;
  }


  return _sources.filter(source => !props.hideSources.includes(source))
})
</script>

<template>
  <div class="tw-flex tw-gap-2 tw-flex-wrap">
    <Badge
      v-for="source in sources"
      :key="source"
      :variant="source"
      class="tw-cursor-pointer tw-transition-opacity"
      :class="{'tw-opacity-40': !selected[source] }"
      @click="emit('select', source)"
    >
      {{ sourceToLabel(source) }}
    </Badge>
    <Badge
      v-if="showNone"
      variant="default"
      class="tw-cursor-pointer tw-transition-opacity"
      :class="{'tw-opacity-40': !selected.none }"
      @click="emit('select', 'none')"
    >
      None
    </Badge>
  </div>
</template>
