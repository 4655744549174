<script setup>
import { ref, computed } from 'vue';
import { ClipboardCopyIcon, ExternalLinkIcon, ImageIcon } from 'lucide-vue';
import { copyToClipboard, uppercaseFirstLetter } from '@/utils/lib';
import usePfpStore from '@/pinia/pfp';
import Card from '@/components/pfp/ui/card/Card.vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import Tooltip from '@/components/pfp/ui/tooltip/Tooltip.vue';
import SourceTags from '@/components/pfp/source-tags/SourceTags.vue';

const props = defineProps({
  // {
  //   id: number
  //   name: string
  //   url: string
  //   sources: ('nwData' | 'nwOSINT' | 'nwGumtree' | 'pipl' | 'leakedDatasets')[]
  // }
  data: {
    type: Object,
    default: () => ({}),
  },
});

const store = usePfpStore();
const imgError = ref(false);

const attributes = computed(() => {
  if (!props.data.otherAttributes) {
    return [];
  }

  return Object.values(props.data.otherAttributes).filter((attr) => {
    return Boolean(attr.value);
  });
});

function handleClipboard(val) {
  copyToClipboard(val);
  store.notifications.addNotification({
    id: Date.now(),
    title: 'Profile attribute copied',
    message: val,
  });
}

function handleImageError(e) {
  imgError.value = true;
}
</script>

<template>
  <Card class="tw-grid tw-grid-cols-12">
    <div class="tw-col-span-4 xl:tw-col-span-3 tw-flex tw-flex-col tw-gap-3 tw-items-center tw-relative tw-p-1 tw-pr-8">
      <h4 class="tw-text-xl tw-font-semibold">{{ uppercaseFirstLetter(props.data.network) }}</h4>

      <img v-if="props.data.image && !imgError" :src="props.data.image" class="tw-w-20 tw-h-20 tw-rounded-full" @error="handleImageError" />
      <p v-else class="tw-w-20 tw-h-20 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-200 tw-text-gray-500 tw-text-2xl">
        <ImageIcon class="tw-w-7 tw-h-7" />
      </p>

      <div class="tw-flex tw-flex-col tw-items-center tw-gap-1">
        <p v-if="props.data.name" class="tw-text-lg tw-font-semibold tw-leading-none tw-break-all tw-text-center">{{ props.data.name }}</p>
        <p v-if="props.data.email" class="tw-text tw-font-light tw-leading-none tw-break-all tw-text-center">{{ props.data.email }}</p>
        <p v-if="props.data.phone" class="tw-text tw-font-light tw-leading-none tw-break-all tw-text-center">{{ props.data.phone }}</p>
      </div>

      <SourceTags :data="[props.data.source]" />

      <Button v-if="props.data.url" class="tw-w-full" as="a" :href="props.data.url">
        <ExternalLinkIcon class="tw-w-5 tw-h-5 tw-mr-2" /> View profile
      </Button>

      <div class="tw-h-full tw-w-px tw-bg-muted tw-absolute tw-right-4 tw-top-0" />
    </div>

    <div class="tw-col-span-8 xl:tw-col-span-9">
      <div class="tw-grid tw-grid-cols-2 tw-p-2 tw-gap-y-1 tw-overflow-y-auto tw-overflow-x-hidden tw-max-h-[260px]">
        <div v-for="attr in attributes" class="tw-col-span-1 tw-relative tw-group">
          <p class="tw-font-semibold">{{ attr.label }}</p>
          <p class="tw-font-light">{{ attr.value }}</p>

          <Button v-if="attr.value" class="tw-absolute tw-right-3 tw-bottom-1 tw-hidden group-hover:tw-flex" @click="handleClipboard(attr.value)" size="icon" variant="outline">
            <ClipboardCopyIcon class="tw-w-5 tw-h-5" />
          </Button>
        </div>
      </div>
    </div>
  </Card>
</template>
