<template>
    <b-form-group id="input-group__contact"
                  label-for="input__contact"
                  class="my-0">
        <b-form-input type="text"
                      id="input__contact"
                      placeholder="Mobile number or Email address"
                      v-model="input.contact"
                      :state="contactHasError">
        </b-form-input>
        <b-form-invalid-feedback>
            {{ contactErrorMessage }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
    export default {
        name: 'InputProfileFinderPlusContact',
        computed: {
            contactErrorMessage () {
                return this.form.errors.get(`contacts.${this.position}.contact`)
            },
            contactHasError () {
                return !this.form.errors.has(`contacts.${this.position}.contact`) ? null : false
            },
        },
        props: [
            'form',
            'input',
            'position',
        ],
    }
</script>
