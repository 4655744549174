<template>
    <b-container :fluid="true" style="padding: 0;">
        <b-row no-gutters>
            <b-col cols="2">
                <navigation-side></navigation-side>
            </b-col>
            <b-col cols="10">
                <navbar :show-brand="false" v-if="user"></navbar>
                <b-row class="mr-0">
                    <b-col style="margin: 15px 0 0 15px">
                        <child/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Navbar from '../components/Navbar'
import NavigationSide from '../components/NavigationSide'
import { mapGetters } from 'vuex'

export default {
    name: 'MainLayout',
    components: {
        NavigationSide,
        Navbar
    },
    computed: {
        ...mapGetters({
            user: 'auth/check'
        })
    }
}
</script>
