import { ref } from 'vue';

export function useNotifcations() {
  const active = ref([]);

  function addNotification(notification, timeout = 5000) {
    active.value.push(notification);

    setTimeout(() => {
      removeNotification(notification);
    }, timeout);
  }

  function removeNotification(notification) {
    const index = active.value.indexOf(notification);

    if (index !== -1) {
      active.value.splice(index, 1);
    }
  }

  return {
    active,
    addNotification,
    removeNotification,
  };
}