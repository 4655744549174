<template>
    <div class="tool">
        <div class="tool-body">
            <div class="alert alert-primary" v-if="hasMessage">
                {{ message }}
            </div>
            <div class="card card-default" v-else>
                <div class="card-header d-flex align-items-center justify-content-between">
                    <p class="m-0">
                        Client Reference: {{ profileFinderPlus.client_reference }} | <small>System Reference:
                        {{ profileFinderPlus.uuid }}</small>
                    </p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <dl>
                                <dt>
                                    Requested on
                                </dt>
                                <dd>
                                    {{ profileFinderPlus.created_at | localDatetime }}
                                </dd>
                            </dl>
                        </div>
                        <div class="col">
                            <dl>
                                <dt>
                                    Report Completed
                                </dt>
                                <dd>
                                    <template v-if="isComplete">
                                        {{ profileFinderPlus.updated_at | localDatetime }}
                                    </template>
                                    <template v-else>
                                        N/A
                                    </template>
                                </dd>
                            </dl>
                        </div>
                        <div class="col">
                            <dl>
                                <dt>
                                    Requested by
                                </dt>
                                <dd>
                                    {{ requestedBy }} ({{ profileFinderPlus.team.full_team_name }})
                                </dd>
                            </dl>
                        </div>
                        <div class="col">
                            <dl>
                                <dt>
                                    Current Status
                                </dt>
                                <dd>
                                    {{ status }}
                                </dd>
                            </dl>
                        </div>
                        <div class="col">
                            <dl>
                                <dt>
                                    Expires At
                                </dt>
                                <dd>
                                    {{ profileFinderPlus.expires_at | localDatetime }}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-default my-4">
                <div class="card-header">
                    Instruction Details
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <ul class="list-group">
                                <template v-if="hasSearchEmails">
                                    <li class="list-group-item" v-for="email in profileFinderPlus.search.emails">
                                        <span>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                 data-icon="envelope" class="icon-20" role="img"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor"
                                                      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                                        </span>
                                        {{ email.email }}
                                    </li>
                                </template>
                                <template v-if="hasSearchPhones">
                                    <li class="list-group-item" v-for="phone in profileFinderPlus.search.phones">
                                        <span>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                 data-icon="phone" class="icon-20" role="img"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor"
                                                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path>
                                            </svg>
                                        </span>
                                        {{ phone.phone }}
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-default">
                <div class="card-header">
                    Results
                </div>
                <div class="card-body" v-if="hasSearchResults">
                    <ul class="list-group" v-if="hasAttributedResults">
                        <li class="list-group-item flex-column align-items-start"
                            v-for="result in attributedSearchResults">
                            {{ result.network }} - <a :href="result.url">{{ result.url }}</a>
                        </li>
                    </ul>
                    <template v-if="hasUnattributedResults">
                        <hr>
                        <b-alert show class="my-2">
                            PF+ has located possible unattributed accounts based on username matches
                        </b-alert>
                        <div class="text-center">
                            <a href="#" class="btn btn-primary my-2"
                               @click.prevent="showUnattributed = !showUnattributed">
                                SHOW UNATTRIBUTED ACCOUNTS
                            </a>
                        </div>
                        <div v-if="showUnattributed">
                            <ul class="list-group">
                                <li class="list-group-item flex-column align-items-start"
                                    v-for="result in unattributedSearchResults">
                                    {{ result.network }} - <a :href="result.url">{{ result.url }}</a>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-if="hasAdditionalResults">
                        <hr>
                        <b-alert show class="my-2">
                            PF+ has located additional data within it's archives
                        </b-alert>
                        <div class="text-center">
                            <a href="#" class="btn btn-primary my-2"
                               @click.prevent="showAdditional = !showAdditional">
                                SHOW ADDITIONAL DATA
                            </a>
                        </div>
                        <div v-if="showAdditional">
                            <b-form-row>
                                <b-col cols="4" v-for="(result, key) in additionalResults" :key="key">
                                    <dl>
                                        <template v-for="(value, key) in result.contact">
                                            <dt>{{ key }}</dt>
                                            <dd>{{ value }}</dd>
                                        </template>
                                    </dl>
                                    <hr>
                                    <dl>
                                        <template v-for="(value, key) in result">
                                            <dt>{{ key }}</dt>
                                            <dd>{{ value }}</dd>
                                        </template>
                                    </dl>
                                </b-col>
                            </b-form-row>
                        </div>
                    </template>
                </div>
                <div class="card-body" v-else>
                    <h3 class="text-center">No results found</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import localDatetime from './../utils/mixins/localDatetime'
    import { mapGetters } from 'vuex'
    import { get, isNull, omitBy } from 'lodash'

    export default {
        name: 'ProfileFinderPlusUpdate',
        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
            attributedSearchResults () {
                return this.searchResults.filter(result => result.attributed)
            },
            hasAttributedResults () {
                return !!this.attributedSearchResults.length
            },
            unattributedSearchResults () {
                return this.searchResults.filter(result => !result.attributed)
            },
            hasUnattributedResults () {
                return !!this.unattributedSearchResults.length
            },
            hasMessage () {
                return !!this.message || this.message !== ''
            },
            hasSearchEmails () {
                return !!get(this.profileFinderPlus, 'search.emails', []).length
            },
            hasSearchPhones () {
                return !!get(this.profileFinderPlus, 'search.phones', []).length
            },
            isComplete () {
                return this.profileFinderPlus.status === 'complete'
            },
            additionalResults () {
                return get(this.profileFinderPlus, 'search_results.additional').map(result => {
                    return omitBy({
                        source: get(result, 'raw._source.source', null),
                        email: get(result, 'raw._source.key_value_pair.email', null),
                        fbuid: get(result, 'raw._source.key_value_pair.fbuid', null),
                        phone: get(result, 'raw._source.key_value_pair.phone', null),
                    }, isNull)
                })
            },
            hasAdditionalResults () {
                return !!get(this.profileFinderPlus, 'search_results.additional', []).length
            },
            searchResults () {
                return this.profileFinderPlus.search_results.profiles
            },
            hasSearchResults () {
                return !!get(this.profileFinderPlus, 'search_results.profiles', []).length ||
                    !!get(this.profileFinderPlus, 'search_results.additional', []).length
            },
            requestedBy () {
                if (this.profileFinderPlus.user) {
                    return this.profileFinderPlus.user.firstname + ' ' + this.profileFinderPlus.user.lastname
                }

                return 'a team member'
            },
            status () {
                if (this.profileFinderPlus.status === 'created') {
                    return 'Queued for Processing'
                } else {
                    return this.profileFinderPlus.status
                }
            }
        },
        beforeRouteEnter (to, from, next) {
            next(async vm => {
                await vm.getProfileFinderPlus()
            })
        },
        data () {
            return {
                loading: false,
                message: '',
                profileFinderPlus: {
                    search: {
                        emails: [],
                        phones: [],
                    },
                    search_results: [],
                    team: {
                        full_team_name: ''
                    }
                },
                showAdditional: false,
                showUnattributed: false,
            }
        },
        filters: {
            noResultFilter (value) {
                return value === '' || !value ? 'No result' : value
            }
        },
        methods: {
            async getProfileFinderPlus () {
                let uuid = this.$route.params.uuid

                this.loading = true

                try {
                    const { data } = await axios.get(`/api/profile-finder-plus/${uuid}`)

                    this.profileFinderPlus = data.data
                } catch (e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }
            }
        },
        mixins: [
            localDatetime,
        ]
    }
</script>

<style scoped lang="scss">
    @import '../../sass/variables';

    .btn {
        min-width: 100px;
        margin: 50px 0 25px 0;
        border: none;
        border-radius: 5px;

        &-primary {
            background: $primary-color;
        }

        &-danger {
            background: $danger-red;
        }
    }
</style>
