<template>
    <div class="side-nav sticky-top">
        <router-link :to="{name: 'home'}" class="navbar-brand">
            <img class="h-37 w-auto" src="/img/netwatch-logo-white.svg" alt="Netwatch Global Toolsuite">
        </router-link>

        <div class="navbar-items pb-3">
            <ul class="list-group list-group--navigation">
                <li class="list-group-item" :class="{active: show}">
                    <a href="#"
                       @click.prevent="toggleCollapse"
                       class="d-flex pr-0">
                        <span class="mr-3">
                            <font-awesome-icon icon="chart-line" fixed-width/>
                        </span>
                        Dashboard
                        <span class="chevron ml-auto mr-2">
                            <font-awesome-icon icon="chevron-down"/>
                        </span>
                    </a>
                </li>

                <li class="collapse" :class="{show: show}">
                    <ul class="list-group">
                        <router-link :to="{name: tool.to}"
                                     class="list-group-item"
                                     v-for="(tool, key) in tools"
                                     :key="key">
                            <font-awesome-icon :icon="tool.icon" fixed-width class="mr-3 ml-3"/>
                            <span>{{ tool.name }}</span>
                        </router-link>
                    </ul>
                </li>
            </ul>

            <ul class="list-group list-group--navigation">
                <router-link :to="{name: 'my-details'}" class="list-group-item">
                    <span class="mr-3">
                        <font-awesome-icon icon="id-badge" fixed-width/>
                    </span>
                    My Details
                </router-link>
                <li class="list-group-item">
                    <span class="mr-3">
                        <font-awesome-icon icon="sign-out-alt" fixed-width/>
                    </span>
                    <a href="#" @click.prevent="logout">Log Out</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {toLower, orderBy} from 'lodash'

export default {
    name: 'NavigationSide',

    computed: {
        ...mapGetters({
            teamTools: 'auth/teamTools'
        }),
        tools() {
            return orderBy(this.teamTools.filter(tool => tool.enabled).map((tool) => {
                let kebabCaseTool = `${toLower(tool.name.split(' ').join('-'))}`

                return {
                    name: tool.name,
                    to: kebabCaseTool,
                    icon: this.getIcon(kebabCaseTool)
                }
            }), 'name', 'asc')
        },
    },

    data() {
        return {
            show: false
        }
    },

    methods: {
        getIcon(tool) {
            switch (tool) {
                case 'privacy-checker':
                    return 'user-secret'

                case 'profile-finder':
                    return 'search'

                case 'profile-finder-plus':
                    return 'search-plus'

                case 'profile-finder-plus-two':
                    return 'search-plus'

                case 'temporary-intelligence-capture':
                    return 'history'

                case 'gumtree':
                    return 'tree'

                case 'facebook-id-lookup':
                    return 'id-card'

                case 'land-registry':
                    return 'house-user'

                case 'hlr-lookup':
                    return 'mobile-alt'

                case 'osint-industries-lookup':
                    return 'id-card'

                case 'virtual-witness':
                    return 'id-card'
            }
        },
        toggleCollapse() {
            this.show = !this.show
        },

        async logout() {
            await this.$store.dispatch('auth/logout')

            await this.$router.push({name: 'login'})
        }
    },
}
</script>

<style scoped lang="scss">
@import '../../sass/variables';

.smooth-enter-active,
.smooth-leave-active {
    transition: opacity .5s;
}

.smooth-enter,
.smooth-leave-to {
    opacity: 0;
}

.side-nav {
    display: flex;
    flex-flow: column;
    background-color: $third-color;

    .navbar-brand {
        display: flex;
        justify-content: center;
        margin-right: 0;
        min-width: 100%;
        min-height: 61px;
        background-color: $secondary-color;

        img {
            display: flex;
            align-self: center;
        }
    }

    .navbar-items {
        display: flex;
        flex: 1;
        flex-flow: column;
        justify-content: space-between;

        .list-group {
            &:nth-child(2) {
                &::before {
                    content: "";
                    display: block;
                    margin: 0 auto;
                    width: 90%;
                    padding-bottom: 15px;
                    border-top: 2px solid $primary-color;
                }
            }

            ul .list-group-item {
                display: flex;
            }
        }
    }

    .list-group--navigation {
        .list-group-item {
            color: $primary-color;
            background-color: $third-color;
            border: none;
            border-left: 3px solid transparent;
            transition: background-color 0.3s, border-left 0.3s;

            &:hover {
                border-left: 3px solid $primary-color;
                background-color: $secondary-color;
            }

            span {
                color: $primary-color;
                font-family: $primary-font;
                font-weight: 500;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .active {
            border: none;
            border-left: 3px solid $primary-color;
            background-color: $secondary-color;
        }
    }
}
</style>
