import { get } from 'lodash';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { clientSourceToServerSource, sourceToLabel } from '@/utils/lib';
import { useFilters } from '@/pinia/pfp/filters';
import { useSettings } from '@/pinia/pfp/settings';
import { useNotifcations } from '@/pinia/global/notifications';
import { mergeLinkedResults, mapResponseToResult } from '@/utils/pfp2-maps';

const usePfpStore = defineStore('pfp', () => {
    const _resultSource = ref([]);
    const _linkedResults = useLocalStorage('pfp.linked-results', {}); // [id: string]: Result

    const filters = useFilters();
    const settings = useSettings();
    const notifications = useNotifcations();

    function sortResults(a, b) {
        switch (filters.sort.column) {
            case 'reference':
                return filters.sort.direction === 'asc'
                    ? a.reference.localeCompare(b.reference)
                    : b.reference.localeCompare(a.reference);
            case 'query':
                return filters.sort.direction === 'asc'
                    ? a.query.localeCompare(b.query)
                    : b.query.localeCompare(a.query);
            case 'sources':
                return filters.sort.direction === 'asc'
                    ? a.sourcesMatched.length - b.sourcesMatched.length
                    : b.sourcesMatched.length - a.sourcesMatched.length;
            case 'started':
                return filters.sort.direction === 'asc'
                    ? a.startedDate - b.startedDate
                    : b.startedDate - a.startedDate;
            case 'completed':
                return filters.sort.direction === 'asc'
                    ? a.completedDate - b.completedDate
                    : b.completedDate - a.completedDate;
            default:
                return 0;
        }
    }

    function filterSearch(result) {
        const searchVal = filters.search.toLowerCase().trim();
        const referenceVal = result.reference.toLowerCase().trim();
        const queryVal = result.query.toLowerCase().trim();

        return referenceVal.includes(searchVal) || queryVal.includes(searchVal);
    }

    function filterSources(result) {
        if (!result.sourcesMatched) {
            return filters.sources.none;
        }

        return (
            result.sourcesMatched.some((source) => filters.sources[source]) ||
            (filters.sources.none && result.sourcesMatched.length === 0)
        );
    }

    const results = computed(() => {
        let payload = [..._resultSource.value];

        const hasSources = Object.values(filters.sources).some(Boolean);
        const hasSearch = filters.search.length > 0;
        const hasSort = filters.sort.column !== null;
        const hasStartedRange = filters.startedRange !== null;
        const hasCompletedRange = filters.completedRange !== null;

        if (hasSort) {
            payload = payload.sort(sortResults);
        }

        if (hasSearch) {
            payload = payload.filter(filterSearch);
        }

        if (hasSources) {
            payload = payload.filter(filterSources);
        }

        if (hasStartedRange) {
            payload = payload.filter(
                (result) =>
                    result.startedDate >= filters.startedRange[0] &&
                    result.startedDate <= filters.startedRange[1],
            );
        }

        if (hasCompletedRange) {
            payload = payload.filter(
                (result) =>
                    result.completedDate >= filters.completedRange[0] &&
                    result.completedDate <= filters.completedRange[1],
            );
        }

        return payload;
    });

    const availableSources = computed(() => {
        return results.value.reduce((acc, result) => {
            result.sourcesMatched.forEach((source) => {
                if (!acc.includes(source)) {
                    acc.push(source);
                }
            });

            return acc;
        }, []);
    });

    async function requestSearch(data, linkedID) {
        if (!data) {
            console.error('Invalid data passed to requestSearch()');
            console.error('Data is undefined');
            return;
        }

        if (!data.phone && !data.email) {
            console.error('Invalid data passed to requestSearch()');
            console.error('Data is missing required phone or email');
            return;
        }

        if (data.phone && data.email) {
            console.error('Invalid data passed to requestSearch()');
            console.error('Data has both phone and email');
            return;
        }

        if (!data.reference) {
            console.error('Invalid data passed to requestSearch()');
            console.error('Data is missing required reference');
            return;
        }

        if (!data.sources) {
            console.error('Invalid data passed to requestSearch()');
            console.error('Data is missing required sources');
            return;
        }

        const phoneOrEmail = {
            ...(data.phone ? { phone: data.phone } : { email: data.email }),
        };

        const body = {
            ...phoneOrEmail,
            client_reference: data.reference,
            config: {
                checks: data.sources.reduce((acc, source) => {
                    const s = clientSourceToServerSource(source);

                    if (Array.isArray(s)) {
                        return {
                            ...acc,
                            ...s.reduce(
                                (acc, source) => ({
                                    ...acc,
                                    [source]: true,
                                }),
                                {},
                            ),
                        };
                    }

                    return {
                        ...acc,
                        [clientSourceToServerSource(source)]: true,
                    };
                }, {}),
            },
        };

        const response = await fetch('/api/profile-finder-plus-two', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        const json = await response.json();

        if (json.status === 'error') {
            throw new Error(json.message);
        }

        if (linkedID) {
            _linkedResults.value[linkedID] = mapResponseToResult(json.data);

            const index = _resultSource.value.indexOf(
                _resultSource.value.find((result) => result.id === linkedID),
            );
            const source = _resultSource.value[index];

            const newResult = mergeLinkedResults(
                source,
                _linkedResults.value[linkedID],
            );

            _resultSource.value = [
                ..._resultSource.value.slice(0, index),
                newResult,
                ..._resultSource.value.slice(index + 1),
            ];

            notifications.addNotification({
                id: new Date().getTime(),
                title: 'Added new source(s) to ' + newResult.reference,
                message: data.sources.map(sourceToLabel).join(', '),
                type: 'success',
            });

            return json;
        }

        notifications.addNotification({
            id: new Date().getTime(),
            title: 'Search requested - ' + data.reference,
            message: data.phone || data.email,
            type: 'success',
        });

        _resultSource.value = [
            ..._resultSource.value,
            mapResponseToResult(json.data),
        ];

        return json;
    }

    async function fetchNextPage() {
        if (!filters.pageMeta) {
            notifications.addNotification({
                id: new Date().getTime(),
                title: 'Error fetching next page',
                message: 'There are no pages to load',
                type: 'error',
            });

            return;
        }

        const current = filters.pageMeta.current_page;
        const last = filters.pageMeta.last_page;

        if (current === last) {
            notifications.addNotification({
                id: new Date().getTime(),
                title: 'No more pages',
                message: 'You have reached the end of your results',
                type: 'success',
            });
            return;
        }

        const response = await fetch(
            `/api/profile-finder-plus-two?page=${current + 1}`,
        );
        const json = await response.json();

        _resultSource.value = [
            ..._resultSource.value,
            ...json.data.map(mapResponseToResult),
        ];

        if (json.meta) {
            filters.updatePageMeta(json.meta);
        }
    }

    async function fetchResults() {
        const response = await fetch('/api/profile-finder-plus-two');
        const json = await response.json();

        _resultSource.value = json.data.map(mapResponseToResult);

        if (json.meta) {
            filters.updatePageMeta(json.meta);
        }

        if (_linkedResults.value) {
            for (const [key, value] of Object.entries(_linkedResults.value)) {
                // remove linked results from the main results
                _resultSource.value = _resultSource.value.filter(
                    (result) => result.id !== key,
                );

                const index = _resultSource.value.indexOf(
                    _resultSource.value.find((result) => result.id === key),
                );

                _resultSource.value = [
                    ..._resultSource.value.slice(0, index),
                    mergeLinkedResults(_resultSource.value[index], value),
                    ..._resultSource.value.slice(index + 1),
                ];
            }
        }

        return json;
    }

    async function subscribe(id, attempts = 0) {
        const now = new Date().getTime();
        const MIN_WAIT = 5000;
        const response = await fetch(`/api/profile-finder-plus-two/${id}`);
        const json = await response.json();

        if (response.status === 502 || !get(json, 'data.response.results')) {
            // timeout or no results yet
            const diff = new Date().getTime() - now;

            if (diff < MIN_WAIT) {
                await new Promise((resolve) => setTimeout(resolve, MIN_WAIT - diff));

                await subscribe(id);
            } else {
                await subscribe(id);
            }
        } else if (response.status !== 200) {
            // error
            if (attempts >= 5) {
                // max fail attempts reached
                console.error('Error fetching result, max attempts reached');
                console.error(response);

                return;
            }

            console.error('Error fetching result');
            console.error(response);
            attempts++;

            // wait and retry
            await new Promise((resolve) => setTimeout(resolve, MIN_WAIT));
            await subscribe(id, attempts);
        } else {
            // complete
            handleResultResponse(json, id);
        }
    }

    async function fetchResult(id) {
        const response = await fetch(`/api/profile-finder-plus-two/${id}`);
        const json = await response.json();

        handleResultResponse(json, id);

        return json;
    }

    function handleResultResponse(json, id) {
        const resultInClient = _resultSource.value.find(
            (result) => result.id === id,
        );

        if (resultInClient) {
            const index = _resultSource.value.indexOf(resultInClient);

            _resultSource.value = [
                ..._resultSource.value.slice(0, index),
                mapResponseToResult(json.data),
                ..._resultSource.value.slice(index + 1),
            ];

            return json;
        }

        _resultSource.value = [
            ..._resultSource.value,
            mapResponseToResult(json.data),
        ];
    }

    function init() {
        fetchResults();
    }

    return {
        // actions
        requestSearch,
        fetchResults,
        fetchResult,
        subscribe,
        fetchNextPage,

        // getters
        results,
        availableSources,

        // modules
        settings,
        filters,
        notifications,

        // initialise
        init,
    };
});

export default usePfpStore;
