var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.result)?_c('div',{staticClass:"tw-w-full tw-rounded-xl tw-border tw-border-border tw-p-4 md:tw-border-none md:tw-p-0 tw-cursor-pointer tw-relative",class:{
        'tw-cursor-not-allowed': _vm.result.results_count === 0,
    },attrs:{"data-result":_vm.result.uuid},on:{"click":function($event){return _setup.emit('click')}}},[(_setup.isTeamOwner)?_c('p',{staticClass:"tw-text-xs tw-text-muted-foreground tw-absolute tw-bottom-1 tw-left-3 tw-z-10 tw-hidden md:tw-block"},[_vm._v("\n        Searched by\n        "),_c('span',[_vm._v(_vm._s(_vm.result.user.name))]),_vm._v(" ("+_vm._s(_vm.result.team.name)+")\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-grid-cols-[5fr_7fr_5fr_2fr_3fr] tw-transition-colors hover:tw-bg-muted tw-px-3 tw-py-4 tw-rounded-xl tw-items-center tw-justify-center tw-hidden md:tw-grid tw-relative"},[_c('div',{staticClass:"tw-text-sm tw-pr-1"},[_vm._v(_vm._s(_vm.result.client_reference))]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm"},[_vm._v(_vm._s(_setup.query))]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm"},[_vm._v(_vm._s(_setup.type))]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-center",class:{
                'tw-text-green-500': _vm.result.results_count > 0,
                'tw-text-red-500': _vm.result.results_count === 0,
            }},[_c(_vm.result.results_count > 0
                        ? _setup.CheckCircle2Icon
                        : _setup.XCircleIcon,{tag:"component"})],1),_vm._v(" "),_c('p',{staticClass:"tw-text-sm tw-text-center"},[_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":400},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',[_vm._v(_vm._s(_setup.format(new Date(_vm.result.created_at), 'dd/MM/yy')))])]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n                    "+_vm._s(_setup.formatRelative(
                            new Date(_vm.result.created_at),
                            new Date(),
                            {
                                locale: _setup.locale,
                            },
                        ))+"\n                ")]},proxy:true}],null,false,2877292732)}),_vm._v(" "),_c('span',{staticClass:"tw-text-sm tw-block tw-text-muted-foreground"},[_vm._v("\n                "+_vm._s(_setup.format(new Date(_vm.result.created_at), 'p'))+"\n            ")])],1)]),_vm._v(" "),_c('div',{staticClass:"md:tw-hidden tw-flex tw-justify-between tw-gap-y-6 tw-px-2 tw-py-4 tw-w-full tw-relative"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-space-y-8"},[_c('div',[_c('Label',[_vm._v("Reference")]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm tw-text-muted-foreground"},[_vm._v("\n                    "+_vm._s(_vm.result.client_reference)+"\n                ")])],1),_vm._v(" "),_c('div',[_c('Label',[_vm._v("Query")]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm tw-text-muted-foreground"},[_vm._v("\n                    "+_vm._s(_setup.query)+"\n                ")])],1),_vm._v(" "),_c('div',[_c('Label',[_vm._v("Created")]),_vm._v(" "),_c('p',[_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":400},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',[_vm._v(_vm._s(_setup.format(
                                    new Date(_vm.result.created_at),
                                    'dd/MM/yyyy',
                                )))])]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n                            "+_vm._s(_setup.formatRelative(
                                    new Date(_vm.result.created_at),
                                    new Date(),
                                    {
                                        locale: _setup.locale,
                                    },
                                ))+"\n                        ")]},proxy:true}],null,false,614484698)}),_vm._v(" "),_c('span',{staticClass:"tw-text-sm tw-block tw-text-muted-foreground"},[_vm._v(_vm._s(_setup.format(new Date(_vm.result.created_at), 'p')))])],1)],1)]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-space-y-8 tw-mb-2"},[_c('div',[_c('Label',[_vm._v("Materials")]),_vm._v(" "),_c('div',{class:{
                        'tw-text-green-500': _vm.result.results_count > 0,
                        'tw-text-red-500': _vm.result.results_count === 0,
                    }},[_c(_vm.result.results_count > 0
                                ? _setup.CheckCircle2Icon
                                : _setup.XCircleIcon,{tag:"component"})],1)],1),_vm._v(" "),_c('div',[_c('Label',[_vm._v("Type")]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm tw-text-muted-foreground"},[_vm._v("\n                    "+_vm._s(_setup.type)+"\n                ")])],1),_vm._v(" "),_c('div',[_c('Label',[_vm._v("Searched by")]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm tw-text-muted-foreground"},[_c('span',[_vm._v(_vm._s(_vm.result.user.name))]),_vm._v(" ("+_vm._s(_vm.result.team.name)+")\n                ")])],1)]),_vm._v(" "),_c('p',{staticClass:"tw-absolute -tw-bottom-2 tw-w-full tw-text-center tw-text-xs tw-text-muted-foreground"},[_vm._v("\n            Click card to view results\n        ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }