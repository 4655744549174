<template>
    <nav class="navbar navbar-light navbar-expand-md navbar-toolsuite">
        <div class="container-fluid">
            <router-link :to="{ name: user ? 'home' : 'welcome' }" class="navbar-brand">
            </router-link>
            <b-navbar-toggle class="navbar-toggler" type="button" data-toggle="collapse" target="navbar-collapse"
                             aria-controls="navbarSupportedContent" aria-expanded="false"
                             :aria-label="$t('toggle_navigation')">
                <span class="navbar-toggler-icon"></span>
            </b-navbar-toggle>

            <b-collapse :is-nav="true">
                <!--<a class="notification-pill mb-3 mb-md-0 mr-md-0">
                    <font-awesome-icon icon="bell"/>
                </a>-->
                <b-navbar-nav class="ml-auto">
                    <!-- Authenticated -->
                    <b-nav-item-dropdown>
                        <template #button-content>
                            <img :src="user.photo_url"
                                 :alt="user.name"
                                 class="rounded-circle nav-profile-photo mr-1"/>
                            {{ user.name }}
                            <template v-if="onTeam"> - {{ team.full_team_name }}</template>
                        </template>
                        <template v-if="user.is_developer">
                            <h6 class="dropdown-header">
                                Developer
                            </h6>
                            <router-link :to="{name: 'kiosk.announcements'}" class="dropdown-item pl-3">
                                <font-awesome-icon icon="store-alt" fixed-width/>
                                Kiosk
                            </router-link>
                            <div class="dropdown-divider"/>
                        </template>
                        <h6 class="dropdown-header">
                            Settings
                        </h6>
                        <router-link :to="{name: 'settings'}" class="dropdown-item pl-3">
                            <font-awesome-icon icon="cog" fixed-width/>
                            Your Settings
                        </router-link>
                        <div class="dropdown-divider"/>
                        <h6 class="dropdown-header">
                            Teams
                        </h6>
                        <router-link :to="{name: 'teams.create-team'}" class="dropdown-item pl-3">
                            <font-awesome-icon icon="plus-circle" fixed-width/>
                            Create Team
                        </router-link>
                        <router-link :to="{name: 'settings.teams.switch', params: {id: team.id}}"
                                     class="dropdown-item pl-3"
                                     v-for="(team, key) in teams" :key="key">
                            <font-awesome-icon icon="check"
                                               v-if="user.current_team_id === team.id"
                                               fixed-width
                                               style="color: green;"/>
                            <font-awesome-icon icon="circle"
                                               v-else
                                               fixed-width
                                               style="color: black;"/>
                            {{ team.full_team_name }}
                        </router-link>
                        <div class="dropdown-divider"/>
                        <h6 class="dropdown-header">Support</h6>
                        <router-link :to="{name: 'support'}" class="dropdown-item pl-3">
                            <font-awesome-icon icon="paper-plane" fixed-width/>
                            Email Us
                        </router-link>
                        <div class="dropdown-divider"/>
                        <a href="#" class="dropdown-item pl-3" @click.prevent="logout">
                            <font-awesome-icon icon="sign-out-alt" fixed-width/>
                            Logout
                        </a>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </nav>
</template>

<script>
    import { mapGetters } from 'vuex'
    import LocaleDropdown from './LocaleDropdown'
    import { get } from 'lodash'

    export default {
        components: {
            LocaleDropdown
        },

        data: () => ({
            appName: window.config.appName
        }),

        computed: {
            ...mapGetters({
                unreadAnnouncementsCount: 'announcements/unreadAnnouncementsCount',
                unreadNotificationsCount: 'announcements/unreadNotificationsCount',
                team: 'auth/team',
                onTeam: 'auth/onTeam',
                isTeamOwner: 'auth/isTeamOwner',
                user: 'auth/user'
            }),
            isAdmin () {
                return get(this.user, 'roles.admin', false)
            },
            isAnalyst () {
                return get(this.user, 'roles.analyst', false)
            },
            isDeveloper () {
                return this.user.isDeveloper
            },
            teams () {
                return this.user.teams
            }
        },

        methods: {
            async logout () {
                // Log out the user.
                await this.$store.dispatch('auth/logout')

                // Redirect to login.
                await this.$router.push({ name: 'login' })
            }
        },
        props: {
            showBrand: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
