<script setup>
import {
    default as parsePhoneNumberLib,
    parsePhoneNumberWithError,
    ParseError,
    Metadata,
} from 'libphonenumber-js';
import { SearchIcon, MailIcon, PhoneIcon } from 'lucide-vue';
import { defineProps, ref, computed } from 'vue';
import { MinusIcon, PlusIcon } from 'lucide-vue';
import usePfpStore from '@/pinia/pfp';
import Button from '@/components/pfp/ui/button/Button.vue';
import Card from '@/components/pfp/ui/card/Card.vue';
import ResultSection from '@/components/pfp/results-table/ResultSection.vue';
import ResultTitle from '@/components/pfp/results-table/ResultTitle.vue';
import TransitionExpand from '@/components/pfp/transition-expand/TransitionExpand.vue';

const props = defineProps({
    emails: {
        type: Array,
        default: () => [],
    },
    phones: {
        type: Array,
        default: () => [],
    },
    query: {
        type: String,
        default: '',
    },
    reference: {
        type: String,
        default: '',
    },
    sources: {
        type: Array,
        default: () => [],
    },
});

const store = usePfpStore();

const open = ref(false);

const metadata = ref(new Metadata());

const loadingButtons = ref([]);

const countryCodesBeginningNumbers = ref(
    Object.keys(metadata.value.metadata.country_calling_codes).sort((a, b) => {
        return Number(b) - Number(a);
    }),
);

function parsePhoneNumber(phone) {
    const phoneStartsWithCountryCode = countryCodesBeginningNumbers.value.find(
        (countryCode) => {
            return props.phones.some((phone) => {
                return phone.startsWith(countryCode);
            });
        },
    );

    const countryCode =
        metadata.value.metadata.country_calling_codes[
            phoneStartsWithCountryCode
        ][0] || 'GB';

    try {
        const parsed = parsePhoneNumberWithError(phone, countryCode);

        return parsed.number;
    } catch (error) {
        if (error instanceof ParseError) {
            console.log('error', error.message);
        }
    }
}

const queryParsed = computed(() => {
    const isPhone = !props.query.includes('@');

    if (isPhone) {
        return parsePhoneNumber(props.query);
    }

    return props.query;
});

const parsedEmails = computed(() => {
    return props.emails.filter((email) => {
        return email !== queryParsed.value;
    });
});

const parsedPhones = computed(() => {
    if (!props.phones.length) {
        return [];
    }

    return [
        ...new Set(
            props.phones.map((phone) => {
                return parsePhoneNumber(phone);
            }),
        ),
    ].filter((phone) => {
        return phone !== queryParsed.value;
    });
});

const dataCount = computed(() => {
    return (parsedEmails.value.length || 0) + (parsedPhones.value.length || 0);
});

const title = computed(() => {
    const hasEmails = parsedEmails.value.length > 0;
    const hasPhones = parsedPhones.value.length > 0;

    const email = hasEmails
        ? `${parsedEmails.value.length} email${
              parsedEmails.value.length > 1 ? 's' : ''
          }`
        : '';
    const and = hasEmails && hasPhones ? ' and ' : '';
    const phone = hasPhones
        ? `${parsedPhones.value.length} phone number${
              parsedPhones.value.length > 1 ? 's' : ''
          }`
        : '';

    return `Additional ${email}${and}${phone}`;
});

async function handleSearch(query, type) {
    loadingButtons.value.push(query);

    const data = {
        ...(type === 'phoneNumber' && {
            phone: parsePhoneNumberLib(query).format('E.164').replace('+', ''),
        }),
        ...(type === 'emailAddress' && {
            email: query,
        }),
        reference: props.reference,
        sources: props.sources,
    };

    try {
        await store.requestSearch(data);
    } catch (e) {
        console.error(e);
    } finally {
        loadingButtons.value = loadingButtons.value.filter((button) => {
            return button !== query;
        });
    }
}
</script>

<template>
    <ResultSection v-if="dataCount > 0">
        <div class="tw-flex tw-justify-between tw-items-top tw-relative">
            <ResultTitle class="tw-cursor-pointer" @click="open = !open">
                <div class="tw-w-5 tw-h-5">
                    <PlusIcon v-if="!open" class="tw-w-full tw-h-full" />
                    <MinusIcon v-else class="tw-w-full tw-h-full" />
                </div>
                {{ title }}
            </ResultTitle>
        </div>

        <TransitionExpand>
            <TransitionGroup
                name="fade"
                tag="div"
                mode="out-in"
                appear
                class="tw-relative"
                v-if="open"
            >
                <div class="tw-h-8 tw-w-full" key="spacer" />

                <ul
                    key="list"
                    class="tw-m-0 tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 2xl:tw-grid-cols-4"
                >
                    <li
                        v-for="email in parsedEmails"
                        :key="email"
                        class="tw-mb-2 tw-inline-block"
                    >
                        <Card
                            class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-between tw-px-2"
                        >
                            <div class="tw-flex tw-flex-col tw-items-center">
                                <MailIcon
                                    class="tw-text-muted-foreground tw-w-7 tw-h-7 tw-mt-2"
                                />
                                <span
                                    class="tw-text-sm tw-font-semibold tw-text-foreground tw-mt-2 tw-text-center"
                                >
                                    {{ email }}
                                </span>
                            </div>
                            <Button
                                class="tw-w-full tw-mt-4"
                                @click="handleSearch(email, 'emailAddress')"
                                :loading="
                                    loadingButtons.find(
                                        (button) => button === email,
                                    )
                                "
                                :loading-text="'Searching...'"
                            >
                                <SearchIcon class="tw-mr-2 tw-w-5 tw-h-5" />
                                Search with this query
                            </Button>
                        </Card>
                    </li>
                    <li
                        v-for="phone in parsedPhones"
                        :key="phone"
                        class="tw-mb-2 tw-inline-block"
                    >
                        <Card
                            class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-between tw-px-2"
                        >
                            <div class="tw-flex tw-flex-col tw-items-center">
                                <PhoneIcon
                                    class="tw-text-muted-foreground tw-w-7 tw-h-7 tw-mt-2"
                                />
                                <span
                                    class="tw-text-sm tw-font-semibold tw-text-foreground tw-mt-2 tw-text-center"
                                >
                                    {{ phone }}
                                </span>
                            </div>
                            <Button
                                class="tw-w-full tw-mt-4"
                                @click="handleSearch(phone, 'phoneNumber')"
                                :loading="
                                    loadingButtons.find(
                                        (button) => button === phone,
                                    )
                                "
                                :loading-text="'Searching...'"
                            >
                                <SearchIcon class="tw-mr-2 tw-w-5 tw-h-5" />
                                Search with this query
                            </Button>
                        </Card>
                    </li>
                </ul>
            </TransitionGroup>
        </TransitionExpand>
    </ResultSection>
</template>
