<template>
    <b-card class="my-4">
        <div class="d-flex flex-row justify-content-between mb-0 px-3">
            <small class="text-muted mt-1">Price</small>
            <p class="h6">{{ payAsYouGo.price / 100 | currency }}</p>
        </div>
        <hr class="mt-2 mx-3"/>
        <div class="d-flex flex-row justify-content-between p-3 mid">
            <div class="d-flex flex-column">
                <small class="text-muted mb-1">ACTIONS</small>
                <p class="h6 ml-1">{{ payAsYouGo.actions }}</p>
            </div>
            <div class="d-flex flex-column">
                <small class="text-muted mb-1">COST PER ACTION</small>
                <p class="h6 ml-1">{{ payAsYouGo.cost_per_action / 100 | currency }}</p>
            </div>
        </div>
        <div class="mx-3 mt-3 mb-2">
            <template v-if="billableHasPaymentMethod">
                <a class="btn btn-primary btn-block" href="#" @click.prevent="confirmPayAsYouGoPurchase">
                    <small>Buy</small>
                </a>
            </template>
            <template v-else>
                <router-link class="btn btn-outline-info btn-block"
                             :to="{name: 'settings.team.payment-methods', params: {id: team.id}}">
                    <small>Add Payment Method</small>
                </router-link>
            </template>
            <b-modal v-model="showConfirmationModal"
                     title="Confirm"
                     @ok.prevent="purchasePayAsYouGo(payAsYouGo.id)"
                     :ok-only="this.success">
                <b-alert :show="hasMessage" variant="danger">
                    {{ message }}
                </b-alert>
                <template v-if="purchasing">
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Purchasing...</strong>
                    </div>
                </template>
                <template v-else-if="success">
                    {{ actionPool }}
                </template>
                <template v-else>
                    Are you sure that you wish to buy {{ payAsYouGo.actions }} actions for
                    {{ payAsYouGo.price / 100 | currency }}?
                    <b-form @submit.prevent="purchasePayAsYouGo(payAsYouGo.id)">
                        <b-form-group id="input-group__password"
                                      description="Please re-enter your current password to authorize purchase"
                                      label-for="input__password">
                            <b-form-input type="password"
                                          v-model="form.password"
                                          placeholder="Current Password"
                                          id="input__password"
                                          :class="{'is-invalid': form.errors.has('password')}"
                                          trim>
                            </b-form-input>
                            <has-error :form="form" field="password"></has-error>
                        </b-form-group>
                    </b-form>
                </template>
            </b-modal>
        </div>
    </b-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Form from 'vform'
import { get } from 'lodash'

export default {
    name: 'PayAsYouGoCard',
    computed: {
        ...mapGetters({
            team: 'auth/team'
        }),
        billableHasPaymentMethod () {
            return !!this.team.card_last_four
        },
        hasMessage () {
            return this.message !== null
        }
    },
    data: () => ({
        actionPool: null,
        message: null,
        purchasing: false,
        showConfirmationModal: false,
        success: false,
        form: new Form({
            password: ''
        })
    }),
    props: [
        'payAsYouGo'
    ],
    methods: {
        confirmPayAsYouGoPurchase () {
            this.showConfirmationModal = true
        },
        async purchasePayAsYouGo (id) {
            this.resetMessage()

            if (this.success) {
                this.showConfirmationModal = false

                return
            }

            try {
                this.purchasing = true

                const { data } = await this.form.put(`/api/settings/teams/${this.team.id}/pay-as-you-go/${id}`)

                this.actionPool = data.data
                this.success = true
            } catch (e) {
                this.success = false

                this.message = get(e.response, 'data.message', null)
            } finally {
                this.purchasing = false
            }
        },
        resetMessage () {
            this.message = null
        }
    }
}
</script>

<style scoped lang="scss">
.mid {
    background-color: #ECEDF1;
}
</style>
