
export const SOURCE = {
  NW_DATA: 'nwData',
  NW_OSINT: 'nwOSINT',
  NW_GUMTREE: 'nwGumtree',
  PIPL: 'pipl',
  LEAKED_DATASETS_OLD: 'leakedDatasetsOld',
  LEAKED_DATASETS: 'leakedDatasets'
}

export const SOURCES = Object.values(SOURCE);

export const SOURCE_LABELS = {
  [SOURCE.NW_DATA]: 'NW Data',
  [SOURCE.NW_OSINT]: 'NW OSINT',
  [SOURCE.NW_GUMTREE]: 'NW Gumtree',
  [SOURCE.PIPL]: 'PIPL',
  [SOURCE.LEAKED_DATASETS]: 'Leaked datasets',
  [SOURCE.LEAKED_DATASETS_OLD]: 'Leaked datasets (deprecated)',
};

export const DEFAULT_SOURCE_QUERY = {
  [SOURCE.NW_DATA]: true,
  [SOURCE.NW_OSINT]: true,
  [SOURCE.NW_GUMTREE]: false,
  [SOURCE.PIPL]: false,
  [SOURCE.LEAKED_DATASETS]: false,
}

export const DEFAULT_SOURCE_FILTERS = {
  ...SOURCES.reduce((acc, source) => {
    acc[source] = false;
    return acc;
  }, {}),
  'none': false,
};

export const COLUMNS = [
  'reference',
  'query',
  'sources',
  'started',
  'completed',
];

export const COLUMN_LABELS = {
  reference: 'Reference',
  query: 'Query',
  sources: 'Sources matched',
  started: 'Started',
  completed: 'Completed',
};

export const SORT_DIRECTIONS = [
  'asc',
  'desc',
  null,
];

export const SORT_LABELS = {
  asc: 'Ascending',
  desc: 'Descending',
  [null]: 'None',
};

export const PROFILE_LAYOUTS = [
  'card',
  'row',
];

export const PROFILE_LAYOUT_LABELS = {
  card: 'Card',
  row: 'Row',
};

export const THEMES = [
  'light',
  'dark',
];

export const THEME_LABELS = {
  light: 'Light',
  dark: 'Dark',
};

export const MOCK_PROFILES = [
  {
    id: 1,
    name: 'Linkedin',
    url: 'https://linkedin.com/first-last-1asofljbnaskfjba',
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 2,
    name: 'X',
    url: 'https://x.com/first-last-1asofljbnaskfjba',
    sources: ['nwData', 'nwOSINT', 'pipl', 'leakedDatasets'],
  },
  {
    id: 3,
    name: 'Tiktok',
    url: 'https://tiktok.com/first-last-1asofljbnaskfjba',
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 4,
    name: 'Youtube',
    url: 'https://youtube.com/first-last-1asofljbnaskfjba',
    sources: ['nwData', 'nwOSINT', 'leakedDatasets'],
  },
]

export const MOCK_ATTRIBUTES = [
  {
    id: 1,
    name: 'Email address',
    value: 'person.email@example.com',
    networks: ['paddypower', 'skype', 'x'],
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 2,
    name: 'Username',
    value: 'person.username',
    networks: ['paddypower', 'skype', 'x', 'tiktok', 'youtube'],
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 3,
    name: 'Phone number',
    value: '44123456789',
    networks: ['paddypower', 'skype', 'x', 'tiktok', 'youtube'],
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 4,
    name: 'Address',
    value: '1 Example Street, Example Town, EX4 MPL',
    networks: ['paddypower', 'skype', 'x', 'tiktok', 'youtube'],
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 5,
    name: 'Date of birth',
    value: '01/01/2000',
    networks: ['paddypower', 'skype', 'x', 'tiktok', 'youtube'],
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
  {
    id: 6,
    name: 'Name',
    value: 'Person Surname',
    networks: ['paddypower', 'skype', 'x', 'tiktok', 'youtube'],
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
  },
]

export const MOCK_NETWORKS = [
  {
    id: 1,
    name: 'Skype',
    sources: ['nwData', 'nwOSINT', 'nwGumtree', 'pipl', 'leakedDatasets'],
    attributes: [
      {
        name: 'Username',
        value: 'p.surname123',
      },
      {
        name: 'Email address',
        value: 'person.surname@example.com',
      },
      {
        name: 'Phone number',
        value: '44123456789',
      },
      {
        name: 'Address',
        value: '1 Example Street, Example Town, EX4 MPL',
      },
      {
        name: 'Date of birth',
        value: '01/01/2000',
      },
      {
        name: 'Full name',
        value: 'Person Surname',
      },
      {
        name: 'Gender',
        value: 'Male'
      },
    ]
  },
  {
    id: 2,
    name: 'X',
    sources: ['nwData', 'nwOSINT', 'nwGumtree'],
    attributes: [
      {
        name: 'Username',
        value: 'p.surname123',
      },
      {
        name: 'Email address',
        value: 'person.surname@example.com',
      },
      {
        name: 'Phone number',
        value: '44123456789',
      },
      {
        name: 'Address',
        value: '1 Example Street, Example Town, EX4 MPL',
      },
      {
        name: 'Date of birth',
        value: '01/01/2000',
      },
      {
        name: 'Full name',
        value: 'Person Surname',
      },
      {
        name: 'Gender',
        value: 'Male'
      },
    ],
  },
]

export const MOCK_RESULTS = [
  {
    id: 1,
    reference: 'ID-123456',
    query: '+4471234567891',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: ['nwData', 'nwOSINT', 'nwGumtree'],
    startedDate: new Date('2021-09-01 11:00:00'),
    completedDate: new Date('2021-09-02 12:00:00'),
    data: {
      profiles: MOCK_PROFILES,
      attributes: MOCK_ATTRIBUTES,
      networks: MOCK_NETWORKS,
      addSource: ['pipl', 'leakedDatasets'],
      gumtree: true,
    }
  },
  {
    id: 2,
    reference: 'ID-123457',
    query: '+4471253523522',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: [],
    startedDate: new Date('2022-10-11 11:00:00'),
    completedDate: new Date('2022-10-11 12:00:00'),
    data: {
      addSource: ['pipl', 'leakedDatasets'],
    }
  },
  {
    id: 3,
    reference: 'ID-123458',
    query: '+4471231234564',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: ['nwOSINT'],
    startedDate: new Date('2021-09-01 11:01:00'),
    completedDate: new Date('2021-09-02 12:01:00'),
    data: {
      profiles: MOCK_PROFILES,
      attributes: MOCK_ATTRIBUTES,
      networks: MOCK_NETWORKS,
      addSource: ['pipl', 'leakedDatasets'],
    }
  },
  {
    id: 4,
    reference: 'ID-123459',
    query: '+4479967474467',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: ['nwData', 'nwOSINT', 'nwGumtree'],
    startedDate: new Date(),
    completedDate: new Date(),
    data: {
      profiles: MOCK_PROFILES,
      attributes: MOCK_ATTRIBUTES,
      networks: MOCK_NETWORKS,
      addSource: ['pipl', 'leakedDatasets'],
    }
  },
  {
    id: 5,
    reference: 'ID-123460',
    query: '+4477123483838',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: ['nwData', 'nwOSINT', 'nwGumtree'],
    startedDate: new Date(),
    completedDate: new Date(),
    data: {
      profiles: MOCK_PROFILES,
      attributes: MOCK_ATTRIBUTES,
      networks: MOCK_NETWORKS,
      addSource: ['pipl', 'leakedDatasets'],
    }
  },
  {
    id: 6,
    reference: 'ID-123461',
    query: '+4473457237999',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: ['nwData', 'nwOSINT', 'nwGumtree'],
    startedDate: new Date(),
    completedDate: new Date(),
    data: {
      profiles: MOCK_PROFILES,
      attributes: MOCK_ATTRIBUTES,
      networks: MOCK_NETWORKS,
      addSource: ['pipl', 'leakedDatasets'],
    }
  },
  {
    id: 7,
    reference: 'ID-123462',
    query: '+4475555599099',
    sourcesSearched: ['nwData', 'nwOSINT', 'nwGumtree'],
    sourcesMatched: ['nwData', 'nwOSINT', 'nwGumtree'],
    startedDate: new Date(),
    completedDate: new Date(),
    data: {
      profiles: MOCK_PROFILES,
      attributes: MOCK_ATTRIBUTES,
      networks: MOCK_NETWORKS,
      addSource: ['pipl', 'leakedDatasets'],
    }
  }
]

export const DEFAULT_SETTINGS = {
  profileLayout: 'card', // 'card' | 'row'
  mode: 'light', // 'light' | 'dark'
  workingReference: null,
  workingSources: {
    nwData: true,
    nwOSINT: true,
    nwGumtree: false,
    pipl: false,
    leakedDatasets: false,
  }
}
