<template>
    <b-card>
        <template v-slot:header>
            <div class="d-flex align-items-center mb-2">
                <b-icon-house class="mr-3"></b-icon-house>
                <a class="h5 my-0" href="#" @click.prevent="showMore = !showMore">
                    {{ proprietor.title_number }} - {{ proprietor.property_address }}
                </a>
            </div>
        </template>
        <b-row v-if="showMore">
            <b-col cols="3">
                <p>
                    <b>Additional Proprietor Indicator</b><br>
                    {{ proprietor.additional_proprietor_indicator }}
                </p>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>County</b><br>
                    {{ proprietor.county }}
                </p>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>Date Proprietor Added</b><br>
                    {{ proprietor.date_proprietor_added }}
                </p>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>District</b><br>
                    {{ proprietor.district }}
                </p>
            </b-col>
            <b-col cols="3" v-if="proprietor.location">
                <p>
                    <b>Location (Approximate!)</b>
                </p>
                <div style="height: 250px; width: 100%;">
                    <l-map v-if="proprietor.location"
                           :zoom="15"
                           :center="latLon(proprietor.location.lat, proprietor.location.lon)"
                           style="height: 100%; width: 100%;">
                        <l-tile-layer :url="url"
                                      :attribution="attribution">
                        </l-tile-layer>
                    </l-map>
                </div>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>Multiple Addresses?</b><br>
                    {{ proprietor.multiple_address_indicator }}
                </p>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>Price Paid</b><br>
                    {{ proprietor.price_paid | currency }}
                </p>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>Region</b><br>
                    {{ proprietor.region }}
                </p>
            </b-col>
            <b-col cols="3">
                <p>
                    <b>Tenure</b><br>
                    {{ proprietor.tenure }}
                </p>
            </b-col>
        </b-row>
        <template v-slot:footer v-if="showMore">
            <proprietor-section
                v-for="(proprietorship, key) in proprietor.proprietors"
                :key="key"
                :proprietor="proprietorship">
            </proprietor-section>
        </template>
    </b-card>
</template>

<script>
import ProprietorSection from '../../pages/LandRegistry/ProprietorSection'
import { latLng } from 'leaflet'
import { LMap, LTileLayer } from 'vue2-leaflet'

export default {
    name: 'LandRegistryProprietor',
    components: {
        ProprietorSection,
        LMap,
        LTileLayer
    },
    data () {
        return {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            showMore: false,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        }
    },
    methods: {
        latLon (lat, lon) {
            return latLng(lat, lon)
        },
    },
    props: [
        'proprietor'
    ]
}
</script>

<style scoped>

</style>
