<script setup>
import axios from 'axios';
import { get } from 'lodash';
import { defineProps, ref, computed } from 'vue';
import { PaperclipIcon } from 'lucide-vue';
import useVWStore from '@/pinia/vw';
import Button from '@/components/pfp/ui/button/Button.vue';
import TransitionExpand from '@/components/pfp/transition-expand/TransitionExpand.vue';

const props = defineProps({
    assetName: {
        type: String,
        required: true,
    },
    asset: {
        type: Object,
        required: true,
    },
    vwId: {
        type: String,
        required: true,
    },
    resultId: {
        type: String,
        required: true,
    },
    sourceId: {
        type: String,
        required: true,
    },
});

const store = useVWStore();

const showMaterial = ref(false);
const downloadLoading = ref(false);
const streamLoading = ref(false);

const assetUrl = ref();
const mimeType = ref();

const endpoint = computed(() => {
    const nameEscaped = encodeURIComponent(props.assetName);

    return `/api/virtual-witness/${props.vwId}/events/${props.resultId}/sources/${props.sourceId}/download/"${nameEscaped}"`;
});

async function forceFileDownload(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);

    link.click();
}

async function requestAssetURL() {
    if (assetUrl.value) {
        return;
    }

    const response = await axios.get(endpoint.value);
    
    if (response.status === 200) {
        assetUrl.value = response.data.data.media.url;
        mimeType.value = response.data.data.media.mime_type;
    }
}

async function handleDownload() {
    downloadLoading.value = true;

    await requestAssetURL();

    if (!assetUrl.value) {
        downloadLoading.value = false;

        store.notifications.addNotification({
            id: Date.now(),
            title: 'Error requesting download',
            message: 'No download URL available.'
        });

        downloadLoading.value = false;

        return;
    }

    try {
        const response = await axios.get(assetUrl.value, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        });
    
        forceFileDownload(response.data, props.assetName);
    
        store.notifications.addNotification({
            id: Date.now(),
            title: 'Download started',
            message: 'Your download has started.',
        });
    
        downloadLoading.value = false;
    } catch (error) {
        store.notifications.addNotification({
            id: Date.now(),
            title: 'Error requesting download',
            message: 'An error occurred while downloading the file.'
        });
    
        downloadLoading.value = false;
    }
}

async function handleShowMaterial() {
    if (showMaterial.value) {
        showMaterial.value = false;
        
        return;
    }

    streamLoading.value = true;

    await requestAssetURL();

    if (!assetUrl.value) {
        streamLoading.value = false;

        store.notifications.addNotification({
            id: Date.now(),
            title: 'Error requesting material',
            message: 'No material URL available.'
        });

        streamLoading.value = true;

        return;
    }

    showMaterial.value = true;
    streamLoading.value = false;
}
</script>

<template>
    <li class="tw-p-3">
        <div
            class="tw-flex tw-items-center tw-justify-between tw-text-sm tw-leading-6"
        >
            <div class="tw-flex w-0 tw-flex-1 tw-items-center">
                <PaperclipIcon
                    class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                    aria-hidden="true"
                />
                <div class="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                    <span class="tw-truncate tw-font-semibold">
                        {{ props.assetName }}
                    </span>
                </div>
            </div>
            <div class="tw-ml-4 tw-flex-shrink-0">
                <Button
                    @click="handleDownload"
                    variant="link"
                    class="tw-font-semibold tw-text-sky-600 hover:tw-text-sky-500"
                    :loading="downloadLoading"
                    loading-text="Requesting download..."
                >
                    Download
                </Button>
                <Button
                    @click="handleShowMaterial"
                    variant="secondary"
                    class="tw-font-semibold"
                    :loading="streamLoading"
                    loading-text="Requesting material..."
                >
                    {{ showMaterial ? 'Hide' : 'Show' }} material
                </Button>
            </div>
        </div>
        <TransitionExpand>
            <div
                v-if="showMaterial"
                class="tw-rounded tw-overflow-hidden tw-mt-4"
            >
                <video v-if="mimeType.includes('video')" controls class="tw-w-full tw-max-h-96">
                    <source :src="assetUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <img v-else :src="assetUrl" alt="Material" class="tw-w-full tw-max-h-96 tw-object-contain" />
            </div>
        </TransitionExpand>
    </li>
</template>
