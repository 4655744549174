<script setup>
import { MoonIcon, SunIcon } from 'lucide-vue';
import usePfpStore from '@/pinia/pfp';
import Button from '@/components/pfp/ui/button/Button.vue';

const store = usePfpStore();

function toggleMode() {
    if (store.settings.mode === 'light') {
        store.settings.updateMode('dark');
        document.body.classList.add('tw-dark');
    } else {
        store.settings.updateMode('light');
        document.body.classList.remove('tw-dark');
    }
}
</script>

<template>
    <Button variant="outline" size="icon" @click="toggleMode">
        <SunIcon v-if="store.settings.mode === 'dark'" />
        <MoonIcon v-if="store.settings.mode === 'light'" />
    </Button>
</template>
