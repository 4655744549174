<template>
    <b-row>
        <b-col v-for="(payAsYouGo,key) in payAsYouGos"
               :key="key">
            <pay-as-you-go-card :pay-as-you-go="payAsYouGo"
                                :key="key"
            />
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import PayAsYouGoCard from './PayAsYouGoCard'
import { orderBy } from 'lodash'

export default {
    name: 'PayAsYouGos',
    components: {
        PayAsYouGoCard
    },
    async created () {
        if (!this.hasPayAsYouGos) {
            await this.$store.dispatch('payAsYouGos/fetchPayAsYouGos')
        }
    },
    computed: {
        ...mapGetters({
            loading: 'payAsYouGos/loading',
            hasPayAsYouGos: 'payAsYouGos/hasPayAsYouGos'
        }),
        payAsYouGos() {
            return orderBy(this.$store.state.payAsYouGos.payAsYouGos, 'price', 'asc')
        }
    }
}
</script>
