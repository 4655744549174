<template>
    <b-container :fluid="true">
        <b-row>
            <b-col class="px-0">
                <div class="tool">
                    <div class="tool-header">
                        <h2>
                            <font-awesome-icon icon="user-secret"/>
                            Privacy Checker
                        </h2>
                        <small>Check the privacy of Instagram and Twitter accounts</small>
                    </div>
                    <b-col class="tool-body">
                        <b-row>
                            <b-col cols="12">
                                <form-privacy-check></form-privacy-check>
                            </b-col>
                            <b-col cols="12">
                                <list-privacy-check type="instagram"></list-privacy-check>
                            </b-col>
                        </b-row>
                    </b-col>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import FormPrivacyCheck from '../components/FormPrivacyCheck'
    import ListPrivacyCheck from '../components/ListPrivacyCheck'
    import { has } from 'lodash'
    import Form from 'vform'
    import { mapGetters } from 'vuex'

    export default {
        name: 'PrivacyChecker',
        beforeRouteEnter (to, from, next) {
            next(async vm => {
                if (!vm.hasPrivacyCheckers) {
                    await vm.$store.dispatch('privacyChecker/fetchPrivacyCheckers')
                }
            })
        },
        components: {
            ListPrivacyCheck,
            FormPrivacyCheck
        },
        computed: {
            ...mapGetters({
                hasPrivacyCheckers: 'privacyChecker/hasPrivacyCheckers'
            })
        },
        middleware: 'auth',
        metaInfo () {
            return { title: this.$t('privacy_checker') }
        },
    }
</script>
