<script setup>
import { CalendarIcon, XIcon } from 'lucide-vue';
import { ref, watch } from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const props = defineProps({
    value: {
        type: [Date, Date],
        default: null,
    },
    range: {
        type: Boolean,
        default: true,
    },
    placeholder: {
        type: String,
        default: 'Select a date',
    },
});

const emit = defineEmits(['range:selected', 'selected']);

const selected = ref(null);

watch(
    () => props.value,
    (val) => {
        selected.value = val;
    },
);

watch(
    () => selected.value,
    (val) => {
        if (props.range) {
            emit('range:selected', val);
        } else {
            emit('selected', val);
        }
    },
);
</script>

<template>
    <div class="range-picker">
        <DatePicker
            :range="props.range"
            v-model="selected"
            format="DD MMMM YYYY"
            :placeholder="props.placeholder"
        >
            <template #icon-calendar>
                <CalendarIcon />
            </template>
            <template #icon-clear>
                <XIcon />
            </template>
        </DatePicker>
    </div>
</template>

<style lang="scss">
.mx-datepicker {
    @apply tw-w-full;
}

.mx-input {
    @apply tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 hover:tw-border-border tw-text-foreground;
}

.mx-btn,
.mx-date-row .cell,
.mx-table th {
    @apply tw-text-foreground;
}

.mx-calendar-content .cell:hover {
    @apply tw-bg-foreground/30 tw-rounded tw-font-bold tw-text-white;
}

.mx-calendar-content .cell.in-range:hover {
    @apply tw-bg-foreground/30 tw-rounded-none tw-font-bold tw-text-background;
}

.mx-calendar-content .cell.active {
    @apply tw-bg-prime tw-rounded-tl tw-rounded-bl tw-font-bold tw-text-background;
}

.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
    @apply tw-bg-prime/20 tw-font-bold tw-text-white;
}

.mx-calendar-content .cell.hover-in-range ~ .cell:not(.hover-in-range) {
    @apply tw-rounded-tl-none tw-rounded-bl-none;
}

.cell.in-range ~ .cell.active {
    @apply tw-rounded-tl-none tw-rounded-bl-none tw-rounded-tr tw-rounded-br;
}

.mx-datepicker svg {
    @apply tw-fill-none;
}

.mx-datepicker-main {
    @apply tw-bg-background tw-border tw-border-border tw-rounded-md tw-shadow;
}

.mx-calendar + .mx-calendar {
    @apply tw-border-l tw-border-border;
}
</style>
