import { computed, onMounted } from "vue";
import { useLocalStorage, useMediaQuery } from "@vueuse/core";
import { DEFAULT_SETTINGS, PROFILE_LAYOUTS, DEFAULT_SOURCE_QUERY } from "@/components/pfp/const";

const VERSION = '2.1.1';

export function useSettings() {
  const settings = useLocalStorage('pfp.settings', { ...DEFAULT_SETTINGS, version: VERSION });

  onMounted(() => {
    // Ensure that updated settings always exist when updates go live
    if (settings.value.version !== VERSION) {
      settings.value = {
        ...DEFAULT_SETTINGS,
        version: VERSION,
      }
    }
  });

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const profileLayout = computed(() => settings.value.profileLayout);
  const mode = computed(() => settings.value.mode);
  const workingSources = computed(() => settings.value.workingSources);
  const workingReference = computed(() => settings.value.workingReference);
  const hasWorkingReference = computed(() => settings.value.workingReference !== null);

  function updateProfileLayout(layout) {
    if (PROFILE_LAYOUTS.includes(layout) === false) {
      console.error('Invalid profile layout passed to updateProfileLayout()');
      console.error(`Expected one of ${JSON.stringify(PROFILE_LAYOUTS)}, got '${layout}'`);
      return;
    }

    if (layout !== 'card' && layout !== 'row') {
      console.error('Invalid profile layout passed to updateProfileLayout()');
      console.error(`Expected 'card' or 'row', got '${layout}'`);
      return;
    }

    settings.value.profileLayout = layout;
  }

  function updateMode(mode) {
    if (mode !== 'light' && mode !== 'dark') {
      console.error('Invalid mode passed to updateMode()');
      console.error(`Expected 'light' or 'dark', got '${mode}'`);
      return;
    }

    settings.value.mode = mode;
  }

  function updateWorkingReference(reference) {
    settings.value.workingReference = reference;
  }

  function updateWorkingSources(sources) {
    const requiredSources = Object.entries(DEFAULT_SOURCE_QUERY).filter(([_, value]) => value)
    const newSources = {
      ...sources,
      ...Object.fromEntries(requiredSources),
    }

    settings.value.workingSources = newSources;
  }

  return {
    // state
    settings,

    // getters
    mode,
    isMobile,
    profileLayout,
    workingSources,
    workingReference,
    hasWorkingReference,

    // actions
    updateMode,
    updateProfileLayout,
    updateWorkingSources,
    updateWorkingReference,
  }
}