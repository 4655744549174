var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-relative",on:{"mouseleave":function($event){_setup.hovered = null}}},_vm._l((_setup.attributes),function(attribute,ix){return _c('div',{key:attribute.name,staticClass:"tw-grid tw-grid-cols-3 tw-relative tw-group tw-transition-opacity tw-overflow-auto",class:{
      'tw-opacity-40': _setup.hovered !== null && _setup.hovered !== ix,
      'tw-opacity-100': _setup.hovered === null || _setup.hovered === ix,
    },on:{"mouseenter":function($event){_setup.hovered = ix}}},[_c('div',{staticClass:"tw-bg-muted tw-col-span-1 tw-p-4 tw-text-muted-foreground tw-text-sm tw-z-10 group-hover:tw-bg-muted/30 tw-break-words",class:{
        'tw-rounded-t-xl': ix === 0,
        'tw-rounded-b-xl': ix === _setup.attributes.length - 1,
      }},[_vm._v("\n      "+_vm._s(attribute.name)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"tw-col-span-2 tw-p-4 tw-text-sm tw-z-10 tw-break-words"},[_vm._v(_vm._s(_setup.getAttributeValue(attribute.value)))]),_vm._v(" "),_c(_setup.Button,{staticClass:"tw-absolute tw-right-1.5 tw-top-1.5 tw-opacity-0 tw-pointer-events-none group-hover:tw-opacity-100 group-hover:tw-pointer-events-auto tw-transition-opacity tw-z-10",attrs:{"size":"icon","variant":"outline"},on:{"click":function($event){return _setup.handleClipboard(attribute.value)}}},[_c(_setup.ClipboardCopyIcon,{staticClass:"tw-w-5 tw-h-5"})],1),_vm._v(" "),_c('div',{staticClass:"tw-w-full tw-h-full tw-absolute tw-bg-muted tw-rounded-xl tw-opacity-0 tw-transition-opacity tw-pointer-events-none group-hover:tw-opacity-100 group-hover:tw-pointer-events-auto"})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }