<script setup>
import { onUnmounted, ref, watch } from 'vue';
import useVWStore from '@/pinia/vw';
import { Portal } from 'portal-vue';
import Card from '@/components/pfp/ui/card/Card.vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import Separator from '@/components/pfp/ui/separator/Separator.vue';
import VirtualWitnessListingRow from '@/components/pfp/virtual-witness-listing-table/VirtualWitnessListingRow.vue';
import VirtualWitnessListingHeader from '@/components/pfp/virtual-witness-listing-table/VirtualWitnessListingHeader.vue';
import VirtualWitnessModal from '../virtual-witness-modal/VirtualWitnessModal.vue';

const store = useVWStore();

const showModal = ref(false);
const selectedResult = ref(null);

watch(showModal, (value) => {
    if (value) {
        document.body.classList.add('tw-overflow-hidden');
    } else {
        document.body.classList.remove('tw-overflow-hidden');
    }
});

onUnmounted(() => {
    document.body.classList.remove('tw-overflow-hidden');
});

function handleClick(result) {
    if (result.results_count === 0) {
        return;
    }

    selectedResult.value = result;
    showModal.value = true;
}
</script>

<template>
    <div class="tw-flex tw-flex-col tw-gap-y-6 tw-w-full">
        <div class="tw-flex tw-justify-between tw-gap-x-4">
            <div>
                <h1 class="tw-font-bold tw-text-3xl">Results</h1>
                <p class="tw-font-light">
                    View previous results from your virtual witness searches.
                </p>
            </div>
        </div>

        <div>
            <!-- Header -->
            <VirtualWitnessListingHeader class="tw-hidden md:tw-grid" />
            <Separator class="tw-my-2 tw-opacity-50 tw-hidden md:tw-block" />
            <div class="tw-flex tw-flex-col tw-gap-y-2">
                <!-- Rows -->
                <VirtualWitnessListingRow
                    v-for="result in store.results"
                    :key="result.uuid"
                    :result="result"
                    @click="handleClick(result)"
                />
                <p
                    v-if="store.results.length === 0"
                    class="tw-text-center tw-text-muted-foreground tw-mt-2"
                >
                    No results available, have you created a search yet? 👆
                </p>
            </div>
        </div>

        <Portal to="overlay">
            <VirtualWitnessModal
                v-if="showModal"
                @close="showModal = false"
                :result="selectedResult"
            />
        </Portal>
        <div class="tw-flex tw-justify-center" v-if="store.filters.hasNextPage">
            <Button @click="store.fetchNextPage">Load more results</Button>
        </div>
    </div>
</template>
