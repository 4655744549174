import Form from 'vform'

export const state = {
    profile: new Form({
        photo: null
    }),
    team: {
        createTeam: new Form({
            name: '',
        }),
        currentTeams: []
    },
    api: {
        oauthClients: [],
        createOauthClient: new Form({
            name: '',
            redirectUrl: '',
        }),
        personalAccessTokens: [],
        createPersonalAccessToken: new Form({
            name: ''
        })
    }
}
