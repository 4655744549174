import { Form } from 'vform'

export const state = {
    announcements: {
        createAnnouncement: new Form({
            announcement: '',
            actionButtonText: '',
            actionButtonUrl: '',
        }),
        recentAnnouncements: []
    },
    oauth: {
        clients: [],
        createClient: new Form({
            clientType: null,
            clientName: '',
        })
    },
    users: {
        search: new Form({
            text: '',
        }),
        searchResults: [],
        user: null,
    }
}

export const getters = {
    recentAnnouncements: state => state.announcements.recentAnnouncements
}
