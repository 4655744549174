<template>
    <div id="app">
        <loading ref="loading" />

        <transition name="page" mode="out-in">
            <component :is="layout" v-if="layout"></component>
        </transition>
    </div>
</template>

<script>
import Loading from './Loading';
import axios from 'axios';
import moment from 'moment';
import { each, filter, map } from 'lodash';

const requireContext = require.context('../layouts', false, /.*\.vue$/);

const layouts = requireContext
    .keys()
    .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
    .reduce((components, [name, component]) => {
        components[name] = component.default || component;
        return components;
    }, {});

export default {
    el: '#app',
    components: {
        Loading,
    },
    data: () => ({
        layout: null,
        defaultLayout: 'default',
        loadingNotifications: false,
        notifications: null,
        tokenRefresh: null,
        lastRefreshedApiTokenAt: null,
    }),
    metaInfo() {
        const { appName } = window.config;

        return {
            title: appName,
            titleTemplate: `%s · ${appName}`,
        };
    },
    mounted() {
        this.$loading = this.$refs.loading;
    },
    async created() {
        await this.refreshApiTokenEveryFewMinutes();
    },
    methods: {
        setLayout(layout) {
            if (!layout || !layouts[layout]) {
                layout = this.defaultLayout;
            }

            this.layout = layouts[layout];
        },
        loadDataForAuthenticatedUser() {
            this.getNotifications();
        },
        async refreshApiTokenEveryFewMinutes() {
            this.lastRefreshedApiTokenAt = moment();

            try {
                this.tokenRefresh = setInterval(async () => {
                    if (
                        moment().diff(
                            this.lastRefreshedApiTokenAt,
                            'minutes',
                        ) >= 1
                    ) {
                        await this.refreshApiToken();
                    }
                }, 5000);
            } catch (e) {
                clearInterval(this.tokenRefresh);
            }
        },
        async refreshApiToken() {
            this.lastRefreshedApiTokenAt = moment();

            await axios.get('/api/user');
        },
        getNotifications() {
            this.loadingNotifications = true;

            axios.get('/notifications/recent').then((response) => {
                this.notifications = response.data;

                this.loadingNotifications = false;
            });
        },
        markNotificationsAsRead() {
            if (!this.hasUnreadNotifications) {
                return;
            }

            axios.put('/notifications/read', {
                notifications: map(this.notifications.notifications, 'id'),
            });

            each(this.notifications.notifications, (notification) => {
                notification.read = 1;
            });
        },
    },
    computed: {
        unreadAnnouncementsCount() {
            if (this.notifications && this.user) {
                if (
                    this.notifications.announcements.length &&
                    !this.user.last_read_announcements_at
                ) {
                    return this.notifications.announcements.length;
                }

                return filter(
                    this.notifications.announcements,
                    (announcement) => {
                        return moment
                            .utc(this.user.last_read_announcements_at)
                            .isBefore(moment.utc(announcement.created_at));
                    },
                ).length;
            }

            return 0;
        },
        unreadNotificationsCount() {
            if (this.notifications) {
                return filter(
                    this.notifications.notifications,
                    (notification) => {
                        return !notification.read;
                    },
                ).length;
            }

            return 0;
        },
        hasUnreadAnnouncements() {
            return this.unreadAnnouncementsCount > 0;
        },
        hasUnreadNotifications() {
            return this.unreadNotificationsCount > 0;
        },
    },
};
</script>
