import Form from 'vform'

export const state = {
    form: new Form({
        from: '',
        to: '',
        actions: null,
        cost_per_action: null
    }),
    loading: false,
}

export const getters = {
    form: state => state.form,
    loading: state => state.loading
}

export const mutations = {
    SET_FORM_FROM (state, payload) {
        state.form.from = payload
    },
    SET_FORM_TO (state, payload) {
        state.form.to = payload
    },
    SET_FORM_ACTIONS (state, payload) {
        state.form.actions = payload
    },
    SET_FORM_COST_PER_ACTION (state, payload) {
        state.form.cost_per_action = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    }
}

export const actions = {
    async saveActionPool ({ commit, state }, teamId) {
        commit('SET_LOADING', true)

        try {
            await state.form.post(`/api/kiosk/action-pools/${teamId}`)

            state.form.reset()
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
