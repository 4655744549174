import moment from 'moment'
import Vue from 'vue'

/**
 * Format the given date.
 */
Vue.filter('date', value => {
    if (!value) {
        return ''
    }

    return moment(value).format('YYYY/MM/DD')
})

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', value => {
    if (!value) {
        return ''
    }

    return moment(value).format('YYYY/MM/DD HH:mm Z')
})

/**
 * Format the given date into a relative time.
 */
Vue.filter('relative', value => {
    if (!value) {
        return ''
    }

    return moment(value).locale('en-short').fromNow()
})

/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
Vue.filter('capitalize', value => {
    if (!value && value !== 0) {
        return ''
    }

    return value.toString().charAt(0).toUpperCase()
        + value.slice(1)
})
