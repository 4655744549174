<script setup>
import { ref } from "vue";
import { cn } from "@/utils/lib";

const props = defineProps({
  defaultChecked: { type: Boolean, required: false },
  checked: { type: Boolean, required: false },
  disabled: { type: Boolean, required: false },
  name: { type: String, required: false },
  id: { type: String, required: false },
  value: { type: String, required: false },
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  size: { type: String, required: false },
});

const emit = defineEmits(["update:checked"]);

const toggled = ref(props.checked || props.defaultChecked || false);

function handleClick() {
  toggled.value = !toggled.value;
  emit("update:checked", toggled.value);
}
</script>

<template>
  <button
    @click="handleClick"
    :disabled="props.disabled"
    :class="
      cn(
        'peer tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-background disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-bg-input',
        props.size === 'sm' && 'tw-h-[20px] tw-w-[34px]',
        props.size !== 'sm' && 'tw-h-[24px] tw-w-[44px]',
        toggled && 'tw-bg-prime',
        $attrs.class
      )
    "
  >
    <span
      :class="
        cn(
          'tw-pointer-events-none tw-block tw-rounded-full tw-bg-background tw-shadow-lg tw-ring-0 tw-transition-transform tw-translate-x-0',
          props.size === 'sm' && toggled && 'tw-translate-x-[14px]',
          toggled && props.size !== 'sm' && 'tw-translate-x-[20px]',
          props.size === 'sm' && 'tw-h-4 tw-w-4',
          props.size !== 'sm' && 'tw-h-5 tw-w-5',
        )
      "
    />
  </button>
</template>
