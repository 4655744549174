<script setup>
import { watch, ref } from 'vue';
import { onClickOutside } from '@vueuse/core'
import { computePosition, flip, offset, shift, arrow } from '@floating-ui/dom';

const props = defineProps({
  placement: {
    type: String,
    default: 'top',
  },

  hover: {
    type: Boolean,
    default: false,
  },

  hoverIntent: {
    type: Number,
    default: 0,
  },
});
 
const parent = ref(null);
const reference = ref(null);
const floating = ref(null);
const arr = ref(null);
const styles = ref({});
const arrowStyles = ref({});
const open = ref(false);
const hovered = ref(false);

function openTooltip() {
  computePosition(reference.value, floating.value, {
    placement: props.placement,
    middleware: [
      flip(),
      shift({ padding: 6 }),
      offset(12),
      arrow({ element: arr.value })
    ]
  }).then(({ x, y, placement, middlewareData }) => {
    styles.value = { left: `${x}px`, top: `${y}px`, visibility: 'visible' };
    
    const { x: arrowX, y: arrowY } = middlewareData.arrow;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    arrowStyles.value = {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      [staticSide]: '-4px',
    }
  });
}

function closeTooltip() {
  styles.value = { visibility: 'hidden' };
}

function toggleTooltip() {
  open.value = !open.value;
}

function handleMouseOver() {
  hovered.value = true;

  if (props.hover) {
    // if hoverIntent is set, wait for that amount of time, if the user is still hovering, open the tooltip
    if (props.hoverIntent) {
      setTimeout(() => {
        if (hovered.value) {
          open.value = true;
        }
      }, props.hoverIntent);
    } else {
      open.value = true;
    }
  }
}

function handleMouseOut() {
  hovered.value = false;

  if (props.hover) {
    open.value = false;
  }
}

watch(open, (value) => {
  if (value) {
    openTooltip();
  } else {
    closeTooltip();
  }
});

onClickOutside(parent, () => {
  open.value = false;
});
</script>
 
<template>
  <div ref="parent" class="tw-inline">
    <button ref="reference" @click="toggleTooltip" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
      <slot name="trigger">
        Open tooltip
      </slot>
    </button>
    <div ref="floating" class="tw-bg-foreground tw-text-background tw-p-2 tw-rounded-md tw-shadow-md tw-absolute tw-left-0 tw-top-0 tw-invisible tw-z-10 tw-text-sm" :style="styles" role="tooltip">
      <slot name="content">
        This is a tooltip
      </slot>
      
      <div ref="arr" class="tw-absolute tw-bg-foreground tw-w-2 tw-h-2 tw-rotate-45" :style="arrowStyles" />
    </div>
  </div>
</template>
