import axios from 'axios'
import Form from 'vform'
import {get} from 'lodash';

export const state = {
    form: new Form({
        client_reference: null,
        type: null,
        query: null
    }),
    loading: false,
    osintIndustriesLookup: {},
    osintIndustriesLookups: [],
    types: [
        'email',
        'phone'
    ],
    message: null
}

export const getters = {
    loading: state => state.loading,
    form: state => state.form,
    hasOsintIndustriesLookups: state => !!state.osintIndustriesLookups,
    osintIndustriesLookups: state => state.osintIndustriesLookups,
    osintIndustriesLookup: state => state.osintIndustriesLookup,
    message: state => state.message,
    hasMessage: state => state.message !== null,
    types: state => state.types
}

export const mutations = {
    RESET_FORM(state) {
        state.form.reset()
    },
    RESET_MESSAGE(state) {
        state.message = null
    },
    SET_MESSAGE(state, message) {
        state.message = message
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_FORM_CLIENT_REFERENCE(state, clientReference) {
        state.form.client_reference = clientReference
    },
    SET_FORM_TYPE(state, type) {
        state.form.type = type
    },
    SET_FORM_QUERY(state, query) {
        state.form.query = query
    },
    SET_OSINT_INDUSTRIES_LOOKUPS(state, lookups) {
        state.osintIndustriesLookups = lookups
    },
    SET_OSINT_INDUSTRIES_LOOKUP(state, lookup) {
        state.osintIndustriesLookup = lookup
    }
}

export const actions = {
    async fetchOsintIndustriesLookups({commit}) {
        commit('SET_LOADING', true)

        try {
            const {data} = await axios.get('/api/osint-industries-lookups')
            commit('SET_OSINT_INDUSTRIES_LOOKUPS', data.data)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async fetchOsintIndustriesLookup({commit}, uuid) {
        commit('SET_LOADING', true)

        try {
            const {data} = await axios.get(`/api/osint-industries-lookups/${uuid}`)

            commit('SET_OSINT_INDUSTRIES_LOOKUP', data.data)

            return data
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async lookup({commit}) {
        commit('SET_LOADING', true)

        try {
            const {data} = await state.form.post(`/api/osint-industries-lookups`)

            commit('SET_OSINT_INDUSTRIES_LOOKUP', data.data)

            return data.data
        } catch (e) {
            console.error(e)

            if (get(e, 'response.data.message', null)) {
                commit('SET_MESSAGE', get(e, 'response.data.message'))
            }
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
