import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.PROFILE_URLS;

export const PU_KEY = {
  ...TABLE_KEY,
};

export function getProfileURLs(result) {
  const profileURLs = {};

  if (result.raw[TABLE_KEY.FACEBOOK_URL]) {
      profileURLs[PU_KEY.FACEBOOK_URL] = result.raw[TABLE_KEY.FACEBOOK_URL];
  }

  if (result.raw[TABLE_KEY.INSTAGRAM_URL]) {
      profileURLs[PU_KEY.INSTAGRAM_URL] = result.raw[TABLE_KEY.INSTAGRAM_URL];
  }

  if (result.raw[TABLE_KEY.LINKEDIN_URL]) {
      profileURLs[PU_KEY.LINKEDIN_URL] = result.raw[TABLE_KEY.LINKEDIN_URL];
  }

  if (result.raw[TABLE_KEY.NAUGHTYAMERICA_BEDROOM_URL]) {
      profileURLs[PU_KEY.NAUGHTYAMERICA_BEDROOM_URL] = result.raw[TABLE_KEY.NAUGHTYAMERICA_BEDROOM_URL];
  }

  if (result.raw[TABLE_KEY.WEBSITE]) {
      profileURLs[PU_KEY.WEBSITE] = result.raw[TABLE_KEY.WEBSITE];
  }

  if (result.raw[TABLE_KEY.YOUTUBE_URL]) {
      profileURLs[PU_KEY.YOUTUBE_URL] = result.raw[TABLE_KEY.YOUTUBE_URL];
  }

  return profileURLs;
}
