import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.LOCATION;

export const LOC_KEY = {
    ADDRESS_1: TABLE_KEY.ADDRESS_1,
    ADDRESS_2: TABLE_KEY.ADDRESS_2,
    ADDRESS_3: TABLE_KEY.ADDRESS_3,
    POSTCODE: TABLE_KEY.POSTCODE,
    ZIP: TABLE_KEY.ZIP,
    CITY: TABLE_KEY.CITY,
    COUNTY: TABLE_KEY.COUNTY,
    STATE: TABLE_KEY.STATE,
    PROVINCE: TABLE_KEY.PROVINCE,
    REGION: TABLE_KEY.REGION,
    COUNTRY: TABLE_KEY.COUNTRY,
    COUNTRY_CODE: TABLE_KEY.COUNTRY_CODE,
    LATITUDE: TABLE_KEY.LATITUDE,
    LONGITUDE: TABLE_KEY.LONGITUDE,
    LOCATION: TABLE_KEY.LOCATION,
};

export function getLocations(result) {

  const locations = {};

  if (
      result.raw[TABLE_KEY.ADDRESS_1] ||
      result.raw[TABLE_KEY.BILL_ADDRESS_1]
  ) {
      locations[LOC_KEY.ADDRESS_1] =
          result.raw[TABLE_KEY.ADDRESS_1] ||
          result.raw[TABLE_KEY.BILL_ADDRESS_1];
  }

  if (result.raw[TABLE_KEY.ADDRESS_2]) {
      locations[LOC_KEY.ADDRESS_2] = result.raw[TABLE_KEY.ADDRESS_2];
  }

  if (result.raw[TABLE_KEY.ADDRESS_3]) {
      locations[LOC_KEY.ADDRESS_3] = result.raw[TABLE_KEY.ADDRESS_3];
  }

  if (
      result.raw[TABLE_KEY.POSTCODE] ||
      result.raw[TABLE_KEY.BILL_POST_CODE]
  ) {
      locations[LOC_KEY.POSTCODE] =
          result.raw[TABLE_KEY.POSTCODE] ||
          result.raw[TABLE_KEY.BILL_POST_CODE];
  }

  if (
      result.raw[TABLE_KEY.ZIP_CODE] ||
      result.raw[TABLE_KEY.ZIP] ||
      result.raw[TABLE_KEY.ZIP_4]
  ) {
      locations[LOC_KEY.ZIP] =
          result.raw[TABLE_KEY.ZIP_CODE] ||
          result.raw[TABLE_KEY.ZIP] ||
          result.raw[TABLE_KEY.ZIP_4];
  }

  if (result.raw[TABLE_KEY.CITY]) {
      locations[LOC_KEY.CITY] = result.raw[TABLE_KEY.CITY];
  }

  if (result.raw[TABLE_KEY.COUNTY]) {
      locations[LOC_KEY.COUNTY] = result.raw[TABLE_KEY.COUNTY];
  }

  if (result.raw[TABLE_KEY.STATE]) {
      locations[LOC_KEY.STATE] = result.raw[TABLE_KEY.STATE];
  }

  if (result.raw[TABLE_KEY.PROVINCE]) {
      locations[LOC_KEY.PROVINCE] = result.raw[TABLE_KEY.PROVINCE];
  }

  if (result.raw[TABLE_KEY.REGION]) {
      locations[LOC_KEY.REGION] = result.raw[TABLE_KEY.REGION];
  }

  if (result.raw[TABLE_KEY.COUNTRY]) {
      locations[LOC_KEY.COUNTRY] = result.raw[TABLE_KEY.COUNTRY];
  }

  if (result.raw[TABLE_KEY.COUNTRY_CODE]) {
      locations[LOC_KEY.COUNTRY_CODE] =
          result.raw[TABLE_KEY.COUNTRY_CODE];
  }

  if (result.raw[TABLE_KEY.LATITUDE]) {
      locations[LOC_KEY.LATITUDE] = result.raw[TABLE_KEY.LATITUDE];
  }

  if (result.raw[TABLE_KEY.LONGITUDE]) {
      locations[LOC_KEY.LONGITUDE] = result.raw[TABLE_KEY.LONGITUDE];
  }

  if (result.raw[TABLE_KEY.LOCATION]) {
      locations[LOC_KEY.LOCATION] = result.raw[TABLE_KEY.LOCATION];
  }

  return locations;
}
