<script>
import {defineComponent} from 'vue'
import FormOsintIndustriesLookup from '../../components/FormOsintIndustriesLookup.vue';

export default defineComponent({
    name: 'OsintIndustriesLookupLookup',
    components: {
        FormOsintIndustriesLookup
    }
})
</script>

<template>
    <b-card class="default">
        <form-osint-industries-lookup/>
    </b-card>
</template>
