<script setup>
import { computed, ref, watch, nextTick } from 'vue';
import { AtSignIcon, InfoIcon, PhoneIcon } from 'lucide-vue';
import { useDebounceFn } from '@vueuse/core';
import parsePhoneNumber from 'libphonenumber-js';
import usePfpStore from '@/pinia/pfp';
import Card from '@/components/pfp/ui/card/Card.vue';
import Label from '@/components/pfp/ui/label/Label.vue';
import Input from '@/components/pfp/ui/input/Input.vue';
import Separator from '@/components/pfp/ui/separator/Separator.vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import Toggle from '@/components/pfp/ui/toggle/Toggle.vue';
import Tooltip from '@/components/pfp/ui/tooltip/Tooltip.vue';
import SourceTags from '../source-tags/SourceTags.vue';

const store = usePfpStore();

// state
const step = ref(0);
const selectedInput = ref(''); // phoneNumber | emailAddress
const phoneNumber = ref('');
const emailAddress = ref('');
const reference = ref(store.settings.workingReference || '');
const sources = ref({ ...store.settings.workingSources });

const phoneInput = ref(null);
const emailInput = ref(null);

const phoneError = ref(false);
const emailError = ref(false);
const referenceError = ref(false);
const sourcesError = ref(false);

const requestLoading = ref(false);

const createdResult = ref(null);

const subtitle = computed(() => {
    switch (step.value) {
        case 0:
            return 'Please enter an email or phone number to get started.';
        case 1:
            return 'Add a reference and select which sources you wish to search from.';
        case 2:
            return 'Now you can view your result or search again.';
    }
});

// step zero
const showPhoneNumber = computed(
    () =>
        (step.value === 0 && emailAddress.value === '') ||
        (step.value === 1 && selectedInput.value === 'phoneNumber'),
);
const showEmailAddress = computed(
    () =>
        (step.value === 0 && phoneNumber.value === '') ||
        (step.value === 1 && selectedInput.value === 'emailAddress'),
);
const showSeparator = computed(
    () => showPhoneNumber.value && showEmailAddress.value,
);

// step one
const showEmailButton = computed(() => !showEmailAddress.value);
const showPhoneButton = computed(() => !showPhoneNumber.value);
const showReference = computed(
    () =>
        (phoneNumber.value && !emailAddress.value) ||
        (emailAddress.value && !phoneNumber.value) ||
        step.value === 1,
);
const showSources = computed(() => step.value === 1);
const showSearchButton = computed(() => step.value === 1);

const sourcesSelected = computed(() => {
    return Object.keys(sources.value).filter((key) => sources.value[key]);
});

// setting step one
watch(showReference, (value) => {
    if (!value) return;

    step.value = 1;
    selectedInput.value = phoneNumber.value ? 'phoneNumber' : 'emailAddress';

    if (selectedInput.value === 'emailAddress') {
        sources.value.nwGumtree = false;
    }
});

watch(
    () => store.settings.workingSources,
    (value) => {
        sources.value = { ...value };
    },
);

const handleInput = useDebounceFn(function (which, value) {
    switch (which) {
        case 'phoneNumber':
            phoneNumber.value = value;

            ensureInternational();
            break;
        case 'emailAddress':
            emailAddress.value = value;
            break;
        case 'reference':
            reference.value = value;
            break;
        case 'nwData':
            sources.value.nwData = !sources.value.nwData;
            store.settings.updateWorkingSources({ ...sources.value });
            break;
        case 'nwOSINT':
            sources.value.nwOSINT = !sources.value.nwOSINT;
            store.settings.updateWorkingSources({ ...sources.value });
            break;
        case 'nwGumtree':
            sources.value.nwGumtree = !sources.value.nwGumtree;
            store.settings.updateWorkingSources({ ...sources.value });
            break;
        case 'pipl':
            sources.value.pipl = !sources.value.pipl;
            store.settings.updateWorkingSources({ ...sources.value });
            break;
        case 'leakedDatasets':
            sources.value.leakedDatasets = !sources.value.leakedDatasets;
            store.settings.updateWorkingSources({ ...sources.value });
            break;
    }
}, 250);

async function toggleSelectedInput() {
    switch (selectedInput.value) {
        case 'phoneNumber':
            selectedInput.value = 'emailAddress';
            phoneNumber.value = '';

            sources.value.nwGumtree = false;

            await nextTick();

            emailInput.value.$el.focus();
            break;
        case 'emailAddress':
            selectedInput.value = 'phoneNumber';
            emailAddress.value = '';

            if (store.settings.workingSources.nwGumtree) {
                sources.value.nwGumtree = true;
            }

            await nextTick();

            phoneInput.value.$el.focus();
            break;
    }
}

async function ensureInternational() {
    const stripped = new String(phoneNumber.value).replace(/[^0-9]/g, '');

    if (stripped.startsWith('0')) {
        // if begins with 0 replace with +44
        phoneNumber.value = stripped.replace(/^0/, '+44');
        return;
    }

    if (stripped.length > 0 && !stripped.startsWith('+')) {
        // if doesn't begin with + add +44
        phoneNumber.value = `+${stripped}`;
    }
}

function validate() {
    let valid = true;

    phoneError.value = false;
    emailError.value = false;
    referenceError.value = false;
    sourcesError.value = false;

    if (selectedInput.value === '') {
        step.value = 0;
        valid = false;
    }

    ensureInternational();

    const phoneParsed = parsePhoneNumber(phoneNumber.value);
    const emailParsed = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(
        emailAddress.value,
    );

    if (
        selectedInput.value === 'phoneNumber' &&
        (phoneParsed.nationalNumber.length < 10 ||
            phoneParsed.nationalNumber.length > 10)
    ) {
        phoneError.value = true;
        valid = false;
    }

    if (!phoneParsed && selectedInput.value === 'phoneNumber') {
        phoneError.value = true;
        valid = false;
    }

    if (!emailParsed && selectedInput.value === 'emailAddress') {
        emailError.value = true;
        valid = false;
    }

    if (reference.value === '') {
        referenceError.value = true;
        valid = false;
    }

    return valid;
}

async function handleSearchProfile() {
    const valid = validate();

    if (!valid) return;

    requestLoading.value = true;

    const validatedSources = Object.keys(sources.value).filter((key) => {
        if (sources.value[key]) return true;

        if (selectedInput.value === 'emailAddress' && key === 'nwGumtree')
            return false;
    });

    // send to api
    const data = {
        ...(selectedInput.value === 'phoneNumber' && {
            phone: parsePhoneNumber(phoneNumber.value)
                .format('E.164')
                .replace('+', ''),
        }),
        ...(selectedInput.value === 'emailAddress' && {
            email: emailAddress.value,
        }),
        reference: reference.value,
        sources: validatedSources,
    };

    // handle results
    try {
        const json = await store.requestSearch(data);

        createdResult.value = json.data;
    } catch (e) {
        if (selectedInput.value === 'phoneNumber') {
            phoneError.value = true;
        } else {
            emailError.value = true;
        }

        return;
    } finally {
        requestLoading.value = false;
    }

    // show final step
    step.value = 2;
}

function handleSearchAgain() {
    // reset values
    phoneNumber.value = '';
    emailAddress.value = '';
    selectedInput.value = '';
    reference.value = store.settings.workingReference || '';
    createdResult.value = null;

    for (const key in sources.value) {
        sources.value[key] = store.settings.workingSources[key];
    }

    step.value = 0;
}

function handleViewResult() {
    const row = document.querySelector(
        `[data-result="${createdResult.value.uuid}"]`,
    );

    if (row) {
        window.scrollTo({
            top: row.offsetTop + window.innerHeight / 4,
            behavior: 'smooth',
        });
    }
}

function handlePaste(event, which) {
    if (step.value > 0) return;

    if (!['phoneNumber', 'emailAddress'].includes(which)) return;

    const value = event.clipboardData.getData('text/plain').trim();

    if (!value) return;

    event.preventDefault();

    const isEmail = value.includes('@');

    if (isEmail) {
        phoneNumber.value = '';
        emailAddress.value = value;
        return;
    }

    emailAddress.value = '';
    phoneNumber.value = value;

    ensureInternational();
}

function handleSetWorkingReference() {
    if (store.settings.hasWorkingReference) {
        store.settings.updateWorkingReference(null);
        reference.value = '';
        return;
    }

    if (reference.value === '') {
        referenceError.value = true;
        return;
    }

    store.settings.updateWorkingReference(reference.value);
}
</script>

<template>
    <div class="tw-flex tw-flex-col tw-items-center">
        <h1 class="tw-font-bold tw-text-3xl">Profile Finder +</h1>
        <p class="tw-font-light tw-text-muted-foreground">{{ subtitle }}</p>

        <Card class="tw-p-6 tw-w-[360px] tw-mt-5 tw-relative">
            <transition-group
                name="query-fade"
                tag="div"
                class="tw-flex tw-flex-col tw-gap-y-4"
                mode="out-in"
                appear
                v-if="step !== 2"
            >
                <!-- step-zero -->
                <div v-if="showPhoneNumber" key="phone">
                    <Label for="phone-number"> Phone number </Label>
                    <div>
                        <div class="tw-flex tw-items-center tw-gap-x-1">
                            <Input
                                key="phone-input"
                                ref="phoneInput"
                                id="phone-number"
                                type="tel"
                                size="sm"
                                class="tw-basis-full"
                                placeholder="+447123456789"
                                autocomplete="off"
                                :value="phoneNumber"
                                :disabled="!!emailAddress"
                                @input="handleInput('phoneNumber', $event)"
                                @paste="handlePaste($event, 'phoneNumber')"
                            />
                            <!-- step-one -->
                            <Button
                                key="phone-swap"
                                size="icon"
                                variant="secondary"
                                class="tw-w-10 tw-h-9 tw-border tw-border-secondary"
                                v-if="showEmailButton"
                                @click="toggleSelectedInput"
                            >
                                <AtSignIcon class="tw-w-5 tw-h-5" />
                            </Button>
                        </div>
                        <p
                            v-if="phoneError"
                            class="tw-text-xs tw-text-destructive mt-1"
                        >
                            Please enter a valid phone number.
                        </p>
                    </div>
                </div>

                <!-- step-zero -->
                <div
                    class="tw-relative tw-flex tw-items-center tw-h-6"
                    v-if="showSeparator"
                    key="or-separator"
                >
                    <span
                        class="tw-bg-background tw-text-muted-foreground tw-text-sm tw-absolute tw-left-1/2 tw--translate-x-1/2 px-2"
                        >OR</span
                    >
                    <Separator class="tw-w-full" />
                </div>

                <div v-if="showEmailAddress" key="email">
                    <Label for="email-address"> Email address </Label>
                    <div>
                        <div class="tw-flex tw-items-center tw-gap-x-1">
                            <Input
                                key="email-input"
                                ref="emailInput"
                                type="email"
                                id="email-address"
                                size="sm"
                                class="tw-basis-full"
                                placeholder="person@example.com"
                                autocomplete="off"
                                :value="emailAddress"
                                :disabled="!!phoneNumber"
                                @input="handleInput('emailAddress', $event)"
                                @paste="handlePaste($event, 'emailAddress')"
                            />
                            <!-- step-one -->
                            <Button
                                key="btn"
                                size="icon"
                                variant="secondary"
                                class="tw-w-10 tw-h-9 tw-border tw-border-secondary"
                                v-if="showPhoneButton"
                                @click="toggleSelectedInput"
                            >
                                <PhoneIcon class="tw-w-5 tw-h-5" />
                            </Button>
                        </div>
                        <p
                            v-if="emailError"
                            class="tw-text-xs tw-text-destructive mt-1"
                        >
                            Please enter a valid email address.
                        </p>
                    </div>
                </div>

                <!-- step-one -->
                <div v-if="showReference" key="reference">
                    <Label for="reference"> Reference </Label>
                    <Input
                        id="reference"
                        size="sm"
                        class="tw-basis-full"
                        placeholder="ID-12345"
                        autocomplete="off"
                        :value="reference"
                        :disabled="store.settings.hasWorkingReference"
                        @input="handleInput('reference', $event)"
                    />
                    <p
                        v-if="referenceError"
                        class="tw-text-xs tw-text-destructive mt-1"
                    >
                        Please enter a reference.
                    </p>

                    <div class="tw-flex tw-justify-end tw-gap-x-1 tw-mt-2">
                        <button
                            class="tw-text-sm tw-underline"
                            @click="handleSetWorkingReference"
                        >
                            {{
                                store.settings.hasWorkingReference
                                    ? 'Remove'
                                    : 'Set as'
                            }}
                            working reference
                        </button>
                        <Tooltip placement="bottom">
                            <template #trigger>
                                <InfoIcon
                                    class="tw-w-4 tw-h-4 tw-text-foreground"
                                />
                            </template>
                            <template #content>
                                By setting this reference as your working
                                reference, it will be automatically added to
                                your search queries until you remove it.
                            </template>
                        </Tooltip>
                    </div>
                </div>

                <!-- step-one -->
                <div v-if="showSources" key="sources">
                    <Label for="sources"> Sources </Label>
                    <ul class="tw-flex tw-flex-wrap tw-gap-y-2">
                        <li class="tw-flex tw-items-center tw-basis-1/2">
                            <Toggle
                                id="nwData"
                                :key="`nwData-${sources.nwData}`"
                                size="sm"
                                :checked="sources.nwData"
                                @update:checked="handleInput('nwData', $event)"
                                disabled
                            />
                            <span class="tw-ml-2 tw-text-sm tw-font-medium"
                                >NW Data</span
                            >
                        </li>
                        <li class="tw-flex tw-items-center tw-basis-1/2">
                            <Toggle
                                id="nwOSINT"
                                :key="`nwOSINT-${sources.nwOSINT}`"
                                size="sm"
                                :checked="sources.nwOSINT"
                                @update:checked="handleInput('nwOSINT', $event)"
                                disabled
                            />
                            <span class="tw-ml-2 tw-text-sm tw-font-medium"
                                >NW OSINT</span
                            >
                        </li>
                        <li class="tw-flex tw-items-center tw-basis-1/2">
                            <Toggle
                                id="nwGumtree"
                                :key="`nwGumtree-${sources.nwGumtree}`"
                                size="sm"
                                :checked="sources.nwGumtree"
                                @update:checked="
                                    handleInput('nwGumtree', $event)
                                "
                                :disabled="selectedInput === 'emailAddress'"
                            />
                            <span
                                class="tw-ml-2 tw-text-sm tw-font-medium tw-flex tw-items-center tw-gap-x-1"
                            >
                                NW Gumtree
                                <Tooltip
                                    v-if="selectedInput === 'emailAddress'"
                                >
                                    <template #trigger>
                                        <InfoIcon
                                            class="tw-w-4 tw-h-4 tw-text-foreground"
                                        />
                                    </template>
                                    <template #content>
                                        <div
                                            class="tw-flex tw-flex-col tw-items-center"
                                        >
                                            <span>
                                                Gumtree is only available for
                                                phone number searches.
                                            </span>
                                            <Button
                                                variant="secondary"
                                                class="tw-w-fit mt-1"
                                                size="sm"
                                                @click="toggleSelectedInput"
                                            >
                                                <PhoneIcon
                                                    class="w-4 h-4 mr-2"
                                                />
                                                Switch to phone number
                                            </Button>
                                        </div>
                                    </template>
                                </Tooltip>
                            </span>
                        </li>
                        <li class="tw-flex tw-items-center tw-basis-1/2">
                            <Toggle
                                id="pipl"
                                :key="`pipl-${sources.pipl}`"
                                size="sm"
                                :checked="sources.pipl"
                                @update:checked="handleInput('pipl', $event)"
                            />
                            <span class="tw-ml-2 tw-text-sm tw-font-medium"
                                >PIPL</span
                            >
                        </li>
                        <li class="tw-flex tw-items-center tw-basis-1/2">
                            <Toggle
                                id="leakedDatasets"
                                :key="`leakedDatasets-${sources.leakedDatasets}`"
                                size="sm"
                                :checked="sources.leakedDatasets"
                                @update:checked="
                                    handleInput('leakedDatasets', $event)
                                "
                            />
                            <span class="tw-ml-2 tw-text-sm tw-font-medium"
                                >Leaked datasets</span
                            >
                        </li>
                    </ul>
                </div>

                <div v-if="showSearchButton" key="step-one-btn">
                    <Separator class="tw-w-full mb-4" />
                    <Button
                        class="tw-w-full"
                        variant="default"
                        @click="handleSearchProfile"
                        :loading="requestLoading"
                        loading-text="Requesting profile"
                    >
                        Search for profiles
                    </Button>
                </div>
            </transition-group>

            <!-- step-two -->
            <transition-group
                name="query-fade"
                tag="div"
                class="tw-flex tw-flex-col tw-gap-y-4"
                mode="out-in"
                appear
                v-else
            >
                <div key="step-2-title">
                    <h3 class="tw-text-2xl tw-font-semibold">
                        Finding profile...
                    </h3>
                    <p class="tw tw-text-muted-foreground">
                        We've started the process, so sit tight!
                    </p>
                </div>

                <div key="reference">
                    <Label for="reference"> Reference </Label>
                    <p>{{ reference }}</p>
                </div>

                <div key="query">
                    <div v-if="phoneNumber">
                        <Label for="phone-number"> Phone number </Label>
                        <p>{{ phoneNumber }}</p>
                    </div>
                    <div v-if="emailAddress">
                        <Label for="email-address"> Email address </Label>
                        <p>{{ emailAddress }}</p>
                    </div>
                </div>

                <div key="sources">
                    <Label for="sources"> Sources </Label>
                    <div class="tw-flex tw-flex-wrap tw-gap-y-2">
                        <SourceTags :data="sourcesSelected" />
                    </div>
                </div>

                <div key="btn-group">
                    <Separator class="tw-w-full mt-2 mb-4" />
                    <div class="tw-flex tw-gap-x-2">
                        <Button variant="outline" @click="handleViewResult"
                            >View result</Button
                        >
                        <Button
                            class="tw-w-3/5"
                            variant="default"
                            @click="handleSearchAgain"
                            >Search again</Button
                        >
                    </div>
                </div>
            </transition-group>
        </Card>
    </div>
</template>

<style>
.query-fade {
    backface-visibility: hidden;
    z-index: 1;
}

/* moving */
.query-fade-move {
    transition: all 300ms ease-in-out 50ms;
}

/* appearing */
.query-fade-enter-active {
    transition: all 300ms ease-out;
}

/* disappearing */
.query-fade-leave-active {
    width: calc(100% - 48px);
    transition: all 300ms ease-in, opacity 150ms;
    position: absolute;
    z-index: 0;
}

/* appear at / disappear to */
.query-fade-enter,
.query-fade-leave-to {
    opacity: 0;
}
</style>
