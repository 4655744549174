<script setup>
import { defineEmits, ref, watch } from 'vue';
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-vue';

const props = defineProps({
  // {
  //    column: 'reference' | 'query' | 'sources' | 'started' | 'completed'
  //    direction: 'asc' | 'desc'
  // }
  sort: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['sort']);

const selected = ref(props.sort.column || ''); // 'reference' | 'query' | 'sources' | 'started' | 'completed'
const direction = ref(props.sort.direction || ''); // 'asc' | 'desc'

watch(() => props.sort, (sort) => {
  selected.value = sort.column;
  direction.value = sort.direction;
});

function handleClick(column) {
  if (selected.value === column) {
    switch (direction.value) {
      case 'asc': // asc -> desc
        direction.value = 'desc';
        break;
      case 'desc': // desc -> null
        direction.value = null;
        selected.value = false;
        break;
      case null: // null -> asc
        direction.value = 'asc';
        break;
    }

    emit('sort', column, direction.value);
    return;
  }

  selected.value = column;
  direction.value = 'asc';
  emit('sort', column, direction.value);
}
</script>

<template>
  <div class="tw-grid tw-grid-cols-[1fr_4fr_8fr_5fr_3fr_3fr] tw-px-3 tw-py-2 tw-h-10">
    <div />
    
    <div class="tw-text-sm tw-select-none tw-text-muted-foreground tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2" @click="handleClick('reference')">
      Reference
      <div v-if="selected === 'reference'">
        <ArrowDownIcon v-if="direction === 'desc'" />
        <ArrowUpIcon v-else />
      </div>
    </div>

    <div class="tw-text-sm tw-select-none tw-text-muted-foreground tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2" @click="handleClick('query')">
      Query
      <div v-if="selected === 'query'">
        <ArrowDownIcon v-if="direction === 'desc'" />
        <ArrowUpIcon v-else />
      </div>
    </div>

    <div class="tw-text-sm tw-select-none tw-text-muted-foreground tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2 tw-justify-center" @click="handleClick('sources')">
    Sources matched
      <div v-if="selected === 'sources'">
        <ArrowDownIcon v-if="direction === 'desc'" />
        <ArrowUpIcon v-else />
      </div>
    </div>

    <div class="tw-text-sm tw-select-none tw-text-muted-foreground tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2 tw-justify-center" @click="handleClick('started')">
      Started
      <div v-if="selected === 'started'">
        <ArrowDownIcon v-if="direction === 'desc'" />
        <ArrowUpIcon v-else />
      </div>
    </div>

    <div class="tw-text-sm tw-select-none tw-text-muted-foreground tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2 tw-justify-center" @click="handleClick('completed')">
      Completed
      <div v-if="selected === 'completed'">
        <ArrowDownIcon v-if="direction === 'desc'" />
        <ArrowUpIcon v-else />
      </div>
    </div>
  </div>
</template>