import Form from 'vform'
import axios from 'axios'
import { isEmpty } from 'lodash'

export const state = {
    loading: false,
    form: new Form({
        client_reference: '',
        phone: '',
        country: 'GB'
    }),
    hlrLookups: [],
    error: null,
    message: null,
    currentPage: 1,
    total: 1,
    perPage: 100,
}

export const getters = {
    loading: state => state.loading,
    hlrLookups: state => state.hlrLookups,
    hasHlrLookups: state => !!state.hlrLookups.length,
    form: state => state.form,
    message: state => state.message,
    hasMessage: state => state.message !== null,
    currentPage: state => state.currentPage,
    total: state => state.total,
    perPage: state => state.perPage,
    hasError: state => !isEmpty(state.error),
    error: state => state.error
}

export const mutations = {
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_MESSAGE (state, payload) {
        state.message = payload
    },
    SET_FORM_CLIENT_REFERENCE (state, payload) {
        state.form.client_reference = payload
    },
    SET_FORM_PHONE (state, payload) {
        state.form.phone = payload
    },
    SET_FORM_COUNTRY (state, payload) {
        state.form.country = payload
    },
    SET_HLR_LOOKUPS (state, payload) {
        state.hlrLookups = payload
    },
    SET_CURRENT_PAGE (state, payload) {
        state.currentPage = payload
    },
    SET_TOTAL (state, payload) {
        state.total = payload
    },
    SET_PER_PAGE (state, payload) {
        state.perPage = payload
    },
    SET_ERROR (state, payload) {
        state.error = payload
    }
}

export const actions = {
    async fetchHlrLookups ({ commit, getters }) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(
                `/api/hlr-lookups?page=${getters.currentPage}&per_page=${getters.perPage}`
            )
            commit('SET_HLR_LOOKUPS', data.data)
            commit('SET_TOTAL', data.meta.total)
            commit('SET_PER_PAGE', data.meta.per_page)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async lookup ({ commit }) {
        try {
            const { data } = await state.form.post(`/api/hlr-lookups`)

            return data
        } catch (e) {
            commit('SET_ERROR', e.response.data.message)
        }
    }
}
