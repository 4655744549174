import axios from 'axios'

export const state = {
    loading: false,
    instructions: [],
    links: {},
    message: '',
    meta: {},
    results: [],
    page: 1,
    tab: 'all',
}

export const getters = {
    filteredInstructions: (state) => {
        if (state.tab === 'all') {
            return state.instructions
        }

        if (state.tab === 'successful') {
            return state.instructions.filter(
                instruction => instruction.search_results.profiles.length)
        }

        if (state.tab === 'failed') {
            return state.instructions.filter(
                instruction => !instruction.search_results.profiles.length &&
                    instruction.status === 'complete')
        }

        if (state.tab === 'pending') {
            return state.instructions.filter(
                instruction => instruction.status !== 'complete')
        }
    },
    instructions: (state) => {
        return state.instructions
    },
    hasInstructions: (state) => {
        return !!state.instructions.length
    },
    hasMessage: (state) => {
        return state.message !== ''
    },
    message: (state) => {
        return state.message
    },
    page: (state) => {
        return state.page
    },
    tab: (state) => {
        return state.tab
    },
    meta: (state) => {
        return state.meta
    },
    loading: state => state.loading,
}

export const mutations = {
    setInstructions (state, instructions) {
        state.instructions = instructions
    },
    setLinks (state, links) {
        state.links = links
    },
    setPage (state, page) {
        state.page = page
    },
    setMeta (state, meta) {
        state.meta = meta
    },
    setTab (state, tab) {
        state.tab = tab
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_MESSAGE (state, value) {
        state.message = value
    }
}

export const actions = {
    async fetchInstructions ({ commit, getters }) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(
                `/api/profile-finder-plus?page=${getters.page}`
            )
            commit('setInstructions', data.data)
            commit('setLinks', data.links)
            commit('setMeta', data.meta)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    updatePage ({ commit, dispatch }, value) {
        commit('setPage', value)
        dispatch('fetchInstructions')
    },
    updateTab ({ commit, dispatch }, value) {
        commit('setTab', value)
    }
}
