import axios from 'axios'
import Form from 'vform'

export const state = {
    loading: false,
    profileFinders: [],
    form: new Form({
        email: '',
        phone: '',
        first: '',
        middle: '',
        last: '',
        country: 'GB',
        state: '',
        city: '',
        username: '',
        age: '',
    }),
    results: [],
    pagination: {
        total: 0,
        currentPage: 1
    },
    countries: [
        {
            value: 'GB',
            text: 'United Kingdom (GB)',
        },
        {
            value: 'US',
            text: 'United States (US)',
        },
        {
            value: 'AU',
            text: 'Australia (AU)',
        },
        {
            value: 'CA',
            text: 'Canada (CA)',
        },
    ],
    states: {
        AU: [
            {
                value: 'WA',
                text: 'State of Western Australia',
            },
            {
                value: 'SA',
                text: 'State of South Australia',
            },
            {
                value: 'NT',
                text: 'Northern Territory',
            },
            {
                value: 'VIC',
                text: 'State of Victoria',
            },
            {
                value: 'TAS',
                text: 'State of Tasmania',
            },
            {
                value: 'QLD',
                text: 'State of Queensland',
            },
            {
                value: 'NSW',
                text: 'State of New South Wales',
            },
            {
                value: 'ACT',
                text: 'Australian Capital Territory'
            },
        ],
        CA: [
            {
                value: 'AB',
                text: 'Alberta',
            },
            {
                value: 'BC',
                text: 'British Columbia',
            },
            {
                value: 'MB',
                text: 'Manitoba',
            },
            {
                value: 'NB',
                text: 'New Brunswick',
            },
            {
                value: 'NT',
                text: 'Northwest Territories',
            },
            {
                value: 'NS',
                text: 'Nova Scotia',
            },
            {
                value: 'NU',
                text: 'Nunavut',
            },
            {
                value: 'ON',
                text: 'Ontario',
            },
            {
                value: 'PE',
                text: 'Prince Edward Island',
            },
            {
                value: 'QC',
                text: 'Quebec',
            },
            {
                value: 'SK',
                text: 'Saskatchewan',
            },
            {
                value: 'YU',
                text: 'Yukon',
            },
            {
                value: 'NL',
                text: 'Newfoundland and Labrador'
            },
        ],
        GB: [
            {
                value: 'WLS',
                text: 'Wales'
            },
            {
                value: 'SCT',
                text: 'Scotland'
            },
            {
                value: 'NIR',
                text: 'Northern Ireland'
            },
            {
                value: 'ENG',
                text: 'England'
            }
        ],
        US: [
            {
                value: 'WA',
                text: 'Washington',
            },
            {
                value: 'VA',
                text: 'Virginia',
            },
            {
                value: 'DE',
                text: 'Delaware',
            },
            {
                value: 'DC',
                text: 'District Of Columbia',
            },
            {
                value: 'WI',
                text: 'Wisconsin',
            },
            {
                value: 'WV',
                text: 'West Virginia',
            },
            {
                value: 'HI',
                text: 'Hawaii',
            },
            {
                value: 'FL',
                text: 'Florida',
            },
            {
                value: 'YT',
                text: 'Yukon',
            },
            {
                value: 'WY',
                text: 'Wyoming',
            },
            {
                value: 'PR',
                text: 'Puerto Rico',
            },
            {
                value: 'NJ',
                text: 'New Jersey',
            },
            {
                value: 'NM',
                text: 'New Mexico',
            },
            {
                value: 'TX',
                text: 'Texas',
            },
            {
                value: 'LA',
                text: 'Louisiana',
            },
            {
                value: 'NC',
                text: 'North Carolina',
            },
            {
                value: 'ND',
                text: 'North Dakota',
            },
            {
                value: 'NE',
                text: 'Nebraska',
            },
            {
                value: 'FM',
                text: 'Federated States Of Micronesia',
            },
            {
                value: 'TN',
                text: 'Tennessee',
            },
            {
                value: 'NY',
                text: 'New York',
            },
            {
                value: 'PA',
                text: 'Pennsylvania',
            },
            {
                value: 'CT',
                text: 'Connecticut',
            },
            {
                value: 'RI',
                text: 'Rhode Island',
            },
            {
                value: 'NV',
                text: 'Nevada',
            },
            {
                value: 'NH',
                text: 'New Hampshire',
            },
            {
                value: 'GU',
                text: 'Guam',
            },
            {
                value: 'CO',
                text: 'Colorado',
            },
            {
                value: 'VI',
                text: 'Virgin Islands',
            },
            {
                value: 'AK',
                text: 'Alaska',
            },
            {
                value: 'AL',
                text: 'Alabama',
            },
            {
                value: 'AS',
                text: 'American Samoa',
            },
            {
                value: 'AR',
                text: 'Arkansas',
            },
            {
                value: 'VT',
                text: 'Vermont',
            },
            {
                value: 'IL',
                text: 'Illinois',
            },
            {
                value: 'GA',
                text: 'Georgia',
            },
            {
                value: 'IN',
                text: 'Indiana',
            },
            {
                value: 'IA',
                text: 'Iowa',
            },
            {
                value: 'MA',
                text: 'Massachusetts',
            },
            {
                value: 'AZ',
                text: 'Arizona',
            },
            {
                value: 'CA',
                text: 'California',
            },
            {
                value: 'ID',
                text: 'Idaho',
            },
            {
                value: 'PW',
                text: 'Pala',
            },
            {
                value: 'ME',
                text: 'Maine',
            },
            {
                value: 'MD',
                text: 'Maryland',
            },
            {
                value: 'OH',
                text: 'Ohio',
            },
            {
                value: 'OK',
                text: 'Oklahoma',
            },
            {
                value: 'UT',
                text: 'Utah',
            },
            {
                value: 'MO',
                text: 'Missouri',
            },
            {
                value: 'MN',
                text: 'Minnesota',
            },
            {
                value: 'MI',
                text: 'Michigan',
            },
            {
                value: 'MH',
                text: 'Marshall Islands',
            },
            {
                value: 'KS',
                text: 'Kansas',
            },
            {
                value: 'MT',
                text: 'Montana',
            },
            {
                value: 'MP',
                text: 'Northern Mariana Islands',
            },
            {
                value: 'MS',
                text: 'Mississippi',
            },
            {
                value: 'SC',
                text: 'South Carolina',
            },
            {
                value: 'KY',
                text: 'Kentucky',
            },
            {
                value: 'OR',
                text: 'Oregon',
            },
            {
                value: 'SD',
                text: 'South Dakota'
            },
        ]
    }
}

export const getters = {
    loading: state => state.loading,
    hasProfileFinders: state => !!state.profileFinders.length,
    profileFinders: state => state.profileFinders,
    form: state => state.form,
    showResults: state => state.form.successful,
    results: state => state.results,
    hasResults: state => state.results,
    currentPage: state => state.pagination.currentPage,
    total: state => state.pagination.currentPage,
    perPage: state => state.pagination.perPage,
    countries: state => state.countries,
    states: state => state.states
}

export const mutations = {
    SET_RESULTS (state, payload) {
        state.results = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_PROFILE_FINDERS (state, payload) {
        state.profileFinders = payload
    },
    SET_PAGINATION (state, payload) {
        state.pagination = payload
    },
    SET_CURRENT_PAGE (state, payload) {
        state.pagination.currentPage = payload
    },
    RESET_RESULTS (state) {
        state.results = []
    },
    RESET_FORM (state) {
        state.form.email = ''
        state.form.phone = ''
        state.form.first = ''
        state.form.middle = ''
        state.form.last = ''
        state.form.country = 'GB'
        state.form.state = ''
        state.form.city = ''
        state.form.username = ''
        state.form.age = ''
    }
}

export const actions = {
    async fetchProfileFinders ({ commit }) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(`/api/profile-finder`)
            commit('SET_PROFILE_FINDERS', data.data)
            commit('SET_PAGINATION', {
                currentPage: data.meta.current_page,
                perPage: data.meta.per_page,
                total: data.meta.total
            })
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async search ({ commit, dispatch, state }) {
        commit('SET_LOADING', true)

        try {
            const {data} = await state.form.post(`/api/profile-finder`)
            console.log(data)
            commit('SET_RESULTS', data.data.response.results)

            await dispatch('fetchProfileFinders')
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
