import { ref, computed } from 'vue';
import { DEFAULT_SOURCE_FILTERS, COLUMNS, SORT_DIRECTIONS } from '@/components/pfp/const';

function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function useFilters() {
  const pageMeta = ref({});
  const search = ref('');
  const sources = ref({ ...DEFAULT_SOURCE_FILTERS });
  const startedRange = ref(null);
  const completedRange = ref(null);
  const sort = ref({
    column: 'started',
    direction: 'desc',
  });
  
  const hasNextPage = computed(() => {
    if (!pageMeta.value) {
      return false;
    }

    return pageMeta.value.last_page > pageMeta.value.current_page;
  });

  function updatePageMeta(meta) {
    pageMeta.value = meta;
  }

  function updateSearch(newSearch) {
    if (typeof newSearch !== 'string') {
      console.error('Invalid search passed to updateSearch()');
      console.error(`Expected string, got ${typeof newSearch}`);
      return;
    }

    search.value = newSearch.trim();
  }

  function updateSort(column, direction) {
    if (typeof column !== 'string') {
      console.error('Invalid column passed to updateSort()');
      console.error(`Expected string, got ${typeof column}`);
      return;
    }

    if (!COLUMNS.includes(column)) {
      console.error('Invalid column passed to updateSort()');
      console.error(`Column '${column}' is not a valid sort column`);
      return;
    }

    if (!SORT_DIRECTIONS.includes(direction)) {
      console.error('Invalid direction passed to updateSort()');
      console.error(`Direction '${direction}' is not a valid sort direction`);
      return;
    }

    if (direction === null) {
      sort.value = {
        column: null,
        direction: null,
      }
  
      return;
    }
  
    sort.value = {
      column,
      direction,
    }
  }

  function updateSources(newSources) {
    if (typeof newSources !== 'object') {
      console.error('Invalid sources passed to updateSources()');
      console.error(`Expected object, got ${typeof newSources}`);
      return;
    }
    
    if (Object.keys(newSources).length === 0) {
      console.error('Invalid sources passed to updateSources()');
      console.error('Object cannot be empty');
      return;
    }
    
    for (const SOURCE in DEFAULT_SOURCE_FILTERS) {
      if (!Object.keys(newSources).includes(SOURCE)) {
        console.error('Invalid sources passed to updateSources()');
        console.error(`Source '${SOURCE}' is not a valid source`);
        return;
      }
    }

    if (Object.keys(newSources).some((source) => typeof newSources[source] !== 'boolean')) {
      console.error('Invalid sources passed to updateSources()');
      console.error('Object values must be booleans');
      return;
    }

    sources.value = {
      ...sources.value,
      ...newSources,
    }
  }

  function updateRange(type, range) {
    if (type !== 'started' && type !== 'completed') {
      console.error('Invalid type passed to updateRange()');
      console.error(`Expected 'started' or 'completed', got '${type}'`);
      return;
    }

    if (isNullOrUndefined(range) || isNullOrUndefined(range[0]) || isNullOrUndefined(range[1])) {
      if (type === 'started') {
        startedRange.value = null;

        return
      }

      if (type === 'completed') {
        completedRange.value = null;

        return
      }

      return;
    }

    if (range.length !== 2) {
      console.error('Invalid range passed to updateRange()');
      console.error(`Expected array of length 2, got array of length ${range.length}`);
      return;
    }

    if (range[0] > range[1]) {
      console.error('Invalid range passed to updateRange()');
      console.error('Range cannot have start value greater than end value');
      return;
    }

    if (type === 'started') {
      startedRange.value = range;
    }

    if (type === 'completed') {
      completedRange.value = range;
    }

    return;
  }

  return {
    // state
    search,
    sources,
    startedRange,
    completedRange,
    sort,

    pageMeta,
    hasNextPage,

    // actions
    updateSearch,
    updateSources,
    updateRange,
    updateSort,
    updatePageMeta,
  }
}