import { DATALEAK_KEYS } from '@/const/dataleak-key';

export function getIPAddresses(result) {
  const TABLE_KEY = DATALEAK_KEYS.IP_ADDRESS;

  return [
      result.raw[TABLE_KEY.IP_ADDRESS],
      result.raw[TABLE_KEY.IP_ADDRESS_2],
      result.raw[TABLE_KEY.INITIAL_IP],
  ].filter(Boolean);
}
