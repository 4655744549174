export const DATALEAK_KEYS = {
  /**
  * Personal information
  **/
  PERSONAL_INFORMATION: {
    AGE: 'age',
    AGE_RANGE: 'age_range',
    CREDIT_RATING: 'credit_rating',
    CREDIT_SCORE: 'credit_score',
    DOB: 'dob',
    DOB_DAY: 'dob_day',
    DOB_MONTH: 'dob_month',
    DOB_YEAR: 'dob_year',
    GENDER: 'gender',
    INCOME: 'income',
    JOB_TYPE: 'job_type',
    LANGUAGES: 'languages',
    LOCALE: 'locale',
    NATIONALITY_CODE: 'nationality_code',
    NET_WORTH: 'net_worth',
    ORG: 'org',
    PERSONAL_BRAND: 'personal_brand',
    PERSONAL_BRAND_ID: 'personal_brand_id',
    RELATIONSHIP_STATUS: 'relationship_status',
    TIMEZONE: 'timezone',
    UI_INFO: 'ui_info',
    VRN: 'vrn',
    WORK_PLACE: 'work_place',
  },
  
  /**
  * Names
  **/
  NAMES: {
    DISPLAY_NAME: 'display_name',
    FACEBOOK_NAME: 'facebook_name',
    FIRST_NAME: 'first_name',
    FULL_NAME: 'full_name',
    FULL_NAME_2: 'full_name_2',
    INITIALS: 'initials',
    LAST_NAME: 'last_name',
    MIDDLE_INITIALS: 'middle_initials',
    MIDDLE_NAME: 'middle_name',
    NAME: 'name',
    NAME_PREFIX: 'name_prefix',
    TITLE: 'title',
  },

  

  /**
  * Contact information
  **/
  CONTACT_INFORMATION: {
    DIAL_CODE: 'dial_code',
    EMAIL: 'email',
    EMAIL_2: 'email_2',
    EMAIL_3: 'email_3',
    EMAIL_4: 'email_4',
    EMAIL_ID: 'email_id',
    EMAIL_ORIGINAL: 'email_original',
    FAX: 'fax',
    PHONE_NUMBER: 'phone_number',
    PHONE_NUMBER_2: 'phone_number_2',
    PHONE_NUMBER_3: 'phone_number_3',
    PHONE_NUMBER_E164: 'phone_number_e164',
    PHONE_NUMBER_E164_UK: 'phone_number_e164_uk',
    PHONE_NUMBER_E164_UK_ID: 'phone_number_e164_uk_id',
    TELEPHONE_NUMBER: 'telephone_number',
    TELEPHONE_NUMBER_2: 'telephone_number_2',
    TELEPHONE_NUMBER_AREA_CODE: 'telephone_number_area_code',
    COUNTRY_CODE: 'country_code',
    PHONE_NUMBER_COUNTRY_CODE: 'phone_number_country_code',
    TELEPHONE_NUMBER_COUNTRY_CODE: 'telephone_number_country_code',
  },

  /**
  * Profile URLs
  **/
  PROFILE_URLS: {
    FACEBOOK_URL: 'facebook_url',
    INSTAGRAM_URL: 'instagram_url',
    LINKEDIN_URL: 'linkedin_url',
    NAUGHTYAMERICA_BEDROOM_URL: 'naughtyamerica_bedroom_url',
    WEBSITE: 'website',
    YOUTUBE_URL: 'youtube_url',
  },
  
  /**
  * Usernames
  **/
  USERNAMES: {
    INSTAGRAM_USERNAME: 'instagram_username',
    TWITTER_USERNAME: 'twitter_username',
    SKYPE: 'skype',
    USERNAME: 'username',
    USERNAME_MD5: 'username_md5',
    YAHOO: 'yahoo',
  },

  /**
  * IP Address
  **/
  IP_ADDRESS: {
    INITIAL_IP: 'initial_ip',
    IP_ADDRESS: 'ip_address',
    IP_ADDRESS_2: 'ip_address_2',
  },

  /**
  * Household information
  **/
  HOUSEHOLD_INFORMATION: {
    DWELLING_AGE: 'dwelling_age',
    HOME_OWNER: 'home_owner',
    HOME_OWNERSHIP: 'home_ownership',
    HOUSEHOLD_INCOME: 'household_income',
    HOUSEHOLD_MARITAL_STATUS: 'household_marital_status',
    HOUSEHOLD_SIZE: 'household_size',
    NUM_ADULTS: 'num_adults',
    NUM_KIDS: 'num_kids',
  },

  /**
  * Location
  **/
  LOCATION: {
    ADDRESS_1: 'address_1',
    ADDRESS_2: 'address_2',
    ADDRESS_3: 'address_3',
    APARTMENT: 'apartment',
    BILL_ADDRESS_1: 'bill_address_1',
    BILL_POST_CODE: 'bill_post_code',
    CITY: 'city',
    COUNTRY: 'country',
    COUNTY: 'county',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    LOCATION: 'location',
    LOCATION_CODE: 'location_code',
    LOCATION_ID: 'location_id',
    POSTCODE: 'postcode',
    PROVINCE: 'province',
    REGION: 'region',
    STATE: 'state',
    STATE_CODE: 'state_code',
    STATE_OTHER: 'state_other',
    ZIP: 'zip',
    ZIP_4: 'zip_4',
    ZIP_CODE: 'zip_code',
  },

  /**
  * Identifiers
  **/
  IDENTIFIERS: {
    ACNE_ID: 'acne_id',
    ANET_CUSTOMER_ID: 'anet_customer_id',
    APPLE_ID: 'apple_id',
    BHINNEKA_ID: 'bhinneka_id',
    BLACKHAT_USER_ID: 'blackhat_user_id',
    BRAINTREE_ID: 'braintree_id',
    CANNABIS_FORUM_USER_ID: 'cannabis_forum_user_id',
    CANVA_USER_ID: 'canva_user_id',
    CHATBOOKS_USER_ID: 'chatbooks_user_id',
    DRIZLY_ID: 'drizly_id',
    DUNZO_USER_ID: 'dunzo_user_id',
    EATSTREET_USER_ID: 'eatstreet_user_id',
    EBAY_USER_ID: 'ebay_user_id',
    EVITE_USER_ID: 'evite_user_id',
    FACEBOOK_USER_ID: 'facebook_user_id',
    FACEPUNCH_USER_ID: 'facepunch_user_id',
    FFSHRINE_USER_ID: 'ffshrine_user_id',
    FIREBASE_USER_ID: 'firebase_user_id',
    FLING_USER_ID: 'fling_user_id',
    GAADI_USER_ID: 'gaadi_user_id',
    GAMETUTS_USER_ID: 'gametuts_user_id',
    GCM_ID: 'gcm_id',
    GOOGLE_USER_ID: 'google_user_id',
    GUNTRADER_USER_ID: 'guntrader_user_id',
    HAVENLY_USER_ID: 'havenly_user_id',
    HOUZZ_USER_ID: 'houzz_user_id',
    INSTAGRAM_USER_ID: 'instagram_user_id',
    LINKEDIN_USER_ID: 'linkedin_user_id',
    LIVE_AUCTIONEERS_USER_ID: 'live_auctioneers_user_id',
    MACFORUMS_USER_ID: 'macforums_user_id',
    MATHWAY_USER_ID: 'mathway_user_id',
    MINDJOLT_USER_ID: 'mindjolt_user_id',
    MORELE_USER_ID: 'morele_user_id',
    MORTAL_ONLINE_USER_ID: 'mortal_online_user_id',
    NAUGHTYAMERICA_BEDROOM_USER_ID: 'naughtyamerica_bedroom_user_id',
    PARKMOBILE_US_USER_ID: 'parkmobile_us_user_id',
    PROCTORU_ACCOUNT_UUID: 'proctoru_account_uuid',
    PROCOTRU_RECEIVABLES_ACCOUNT_ID: 'proctoru_receivables_account_id',
    PROCTORU_USER_ID: 'proctoru_user_id',
    SHARETHIS_USER_ID: 'sharethis_user_id',
    STUDENT_CARD_ID: 'student_card_id',
    TORRENTINVITES_USER_ID: 'torrentinvites_user_id',
    TRUEFIRE_USER_ID: 'truefire_user_id',
    TWITTER_USER_ID: 'twitter_user_id',
    VAKINHA_ID: 'vakinha_id',
    VAKINHA_PAY_ID: 'vakinha_pay_id',
    WISHBONE_USER_ID: 'wishbone_user_id',
    ZYNGA_USER_ID: 'zynga_user_id',
  },

  /**
  * Miscellaneous
  **/
  MISC: {
    CNPJ: 'cnpj',
    CPF: 'cpf',
    INTERESTED_IN: 'interested_in',
    INSTALLED_DAYS_AGO: 'installed_days_ago',
    JOIN_DATE: 'join_date',
    LAST_UPDATE: 'last_update',
    UPDATED_DAYS_AGO: 'updated_days_ago',
  }
}

export const DATALEAK_KEYS_FLAT = {
  ...DATALEAK_KEYS.PERSONAL_INFORMATION,
  ...DATALEAK_KEYS.NAMES,
  ...DATALEAK_KEYS.CONTACT_INFORMATION,
  ...DATALEAK_KEYS.PROFILE_URLS,
  ...DATALEAK_KEYS.USERNAMES,
  ...DATALEAK_KEYS.IP_ADDRESS,
  ...DATALEAK_KEYS.HOUSEHOLD_INFORMATION,
  ...DATALEAK_KEYS.LOCATION,
  ...DATALEAK_KEYS.IDENTIFIERS,
  ...DATALEAK_KEYS.MISC,
}
