import Form from 'vform'
import axios from 'axios'

export const state = {
    loading: false,
    form: new Form({
        code: ''
    }),
    response: null
}

export const getter = {
    code: state => state.form.code,
    loading: state => state.loading,
    form: state => state.form
}

export const mutations = {
    RESET_FORM (state) {
        state.form.code = ''
    },
    SET_CODE (state, payload) {
        state.form.code = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_RESPONSE (state, payload) {
        state.response = payload
    }
}

export const actions = {
    async redeemVoucher ({ commit }, team_id) {
        commit('SET_LOADING', true)

        const { data } = await axios.get(
            `/api/teams/${team_id}/vouchers/redeem`
        )

        commit('SET_RESPONSE', data)
        commit('SET_LOADING', false)
    }
}
