<template>
    <b-container :fluid="true">
        <b-row>
            <b-col cols="12">
                <b-card :no-body="true">
                    <template v-slot:header>
                        <h2>
                            Land Registry
                        </h2>
                    </template>
                    <h1>
                        The Land Registry tool has been temporarily disabled while upgrades are made
                    </h1>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import { mapGetters } from 'vuex'
    import PricePaidTab from './LandRegistry/PricePaidTab'
    import ProprietorTab from './LandRegistry/ProprietorTab'

    export default {
        name: 'LandRegistryPage',
        components: {
            PricePaidTab,
            ProprietorTab,
        },
        computed: {
            ...mapGetters({
                loading: 'landRegistry/loading',
            }),
        },
        middleware: 'auth',
    }
</script>
