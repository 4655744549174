import { get } from 'lodash'
import Form from 'vform'

export const state = {
    loading: false,
    message: null,
    pricePaids: [],
    pricePaidsForm: new Form({
        property_address: '',
        duration: null,
        old_new: null,
        ppd_category_type: null,
        property_type: null,
        record_status: null,
        search_limit: 10,
    }),
    proprietors: [],
    proprietorsForm: new Form({
        property_address: '',
        title_number: '',
        company_name: '',
        search_limit: 10,
    }),
}
export const getters = {
    hasMessage: (state) => {
        return state.message !== null
    },
    hasPricePaids: (state) => {
        return !!state.pricePaids.length
    },
    hasProprietors: (state) => {
        return !!state.proprietors.length
    },
    message: (state) => {
        return state.message
    },
    pricePaids: (state) => {
        return state.pricePaids
    },
    proprietors: (state) => {
        return state.proprietors
    },
    loading: state => state.loading
}

export const mutations = {
    SET_ADDRESS (state, address) {
        state.form.address = address
    },
    SET_PRICE_PAIDS (state, pricePaids) {
        state.pricePaids = pricePaids
    },
    SET_PROPRIETORS (state, proprietors) {
        state.proprietors = proprietors
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_MESSAGE (state, payload) {
        state.message = payload
    }
}

export const actions = {
    async fetchPricePaids ({ commit, state }) {
        commit('SET_LOADING', true)
        commit('SET_MESSAGE', null)

        try {
            const { data } = await state.pricePaidsForm.post(
                `/api/land-registry/price-paids/search`
            )
            commit('SET_PRICE_PAIDS', data.data)
        } catch (e) {
            commit('SET_MESSAGE', get(e.response, 'data.message', null))
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async fetchProprietors ({ commit, state }) {
        commit('SET_LOADING', true)
        commit('SET_MESSAGE', null)

        try {
            const { data } = await state.proprietorsForm.post(
                `/api/land-registry/proprietors/search`
            )
            commit('SET_PROPRIETORS', data.data)
        } catch (e) {
            commit('SET_MESSAGE', get(e.response, 'data.message', null))
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
