import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('fa', FontAwesomeIcon)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import {
    faUser,
    faUsers,
    faLock,
    faSignOutAlt,
    faCog,
    faPuzzlePiece,
    faTrashAlt,
    faTimes,
    faUserCircle,
    faCheckCircle,
    faPlusCircle,
    faCheck,
    faPaperPlane,
    faMicrochip,
    faNetworkWired,
    faPalette,
    faFileAlt,
    faFilePdf,
    faEnvelope,
    faBan,
    faBell,
    faCoins,
    faSearch,
    faBullhorn,
    faLaptopCode,
    faStoreAlt,
    faSearchPlus,
    faIdCard,
    faHistory,
    faUserSecret,
    faTree,
    faChartLine,
    faIdBadge,
    faChevronDown,
    faTicketAlt,
    faCashRegister,
    faCreditCard,
    faHouseUser,
    faCircle,
    faMapMarkedAlt,
    faPlus,
    faMobileAlt,
} from '@fortawesome/free-solid-svg-icons'

import {} from '@fortawesome/free-regular-svg-icons'

import {
    faGithub,
    faFortAwesome,
    faAndroid,
    faLinux,
    faWindows,
    faChrome,
    faEdge,
    faInternetExplorer,
    faSafari,
    faInstagram,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faUser,
    faUsers,
    faLock,
    faSignOutAlt,
    faCog,
    faGithub,
    faPuzzlePiece,
    faTrashAlt,
    faTimes,
    faUserCircle,
    faCheckCircle,
    faFortAwesome,
    faPlusCircle,
    faCheck,
    faPaperPlane,
    faAndroid,
    faBan,
    faChrome,
    faEdge,
    faInternetExplorer,
    faLinux,
    faMicrochip,
    faNetworkWired,
    faPalette,
    faSafari,
    faWindows,
    faFileAlt,
    faFilePdf,
    faEnvelope,
    faBell,
    faCoins,
    faSearch,
    faBullhorn,
    faLaptopCode,
    faStoreAlt,
    faSearchPlus,
    faIdCard,
    faHistory,
    faUserSecret,
    faTree,
    faChartLine,
    faIdBadge,
    faChevronDown,
    faTicketAlt,
    faCashRegister,
    faCreditCard,
    faHouseUser,
    faInstagram,
    faTwitter,
    faCircle,
    faMapMarkedAlt,
    faPlus,
    faMobileAlt
)
