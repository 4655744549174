<script setup>
import { defineProps, ref, computed } from 'vue';
import { Grid2X2Icon, RowsIcon, MinusIcon, PlusIcon } from 'lucide-vue';
import { uppercaseFirstLetter } from '@/utils/lib';
import usePfpStore from '@/pinia/pfp';
import Button from '@/components/pfp/ui/button/Button.vue';
import ResultSection from '@/components/pfp/results-table/ResultSection.vue';
import ResultTitle from '@/components/pfp/results-table/ResultTitle.vue';
import ProfileCard from '@/components/pfp/results-table/modules/profile/ProfileCard.vue';
import ProfileRow from '@/components/pfp/results-table/modules/profile/ProfileRow.vue';
import TransitionExpand from '@/components/pfp/transition-expand/TransitionExpand.vue';
import Card from '../../../ui/card/Card.vue';
import SourceTags from '../../../source-tags/SourceTags.vue';

const props = defineProps({
  profiles: {
    type: Object,
    default: () => ({}),
  },
});

const store = usePfpStore();
const open = ref(true);

const profileList = computed(() => {
  return Object.values(props.profiles).flat();
});

const fullList = computed(() => {
  return profileList.value.filter((profile) => {
    return !profile.accountExists;
  });
});

const checkList = computed(() => {
  return profileList.value.filter((profile) => {
    return profile.accountExists;
  });
});

function toggleMode() {
  if (store.settings.profileLayout === 'card') {
    store.settings.updateProfileLayout('row');
    return;
  }
  
  store.settings.updateProfileLayout('card');
}
</script>

<template>
  <ResultSection v-if="profileList.length">
    <div class="tw-flex tw-justify-between tw-items-top tw-relative">
      <ResultTitle class="tw-cursor-pointer" @click="open = !open">
        <div class="tw-w-5 tw-h-5">
          <PlusIcon v-if="!open" class="tw-w-full tw-h-full" />
          <MinusIcon v-else class="tw-w-full tw-h-full" />
        </div>
        {{ fullList.length }} profiles matched

        <template #subtitle>
          Below will be any profiles we have matched against your search query.
        </template>
      </ResultTitle>
      
      <Button @click="toggleMode" variant="outline" size="icon" class="opacity-100 tw-transition-opacity tw-absolute tw-top-0 tw-right-0" :class="{ 'tw-opacity-0 tw-pointer-events-none': !open || store.settings.isMobile }">
        <RowsIcon v-if="store.settings.profileLayout === 'card'" />
        <Grid2X2Icon v-if="store.settings.profileLayout === 'row'" />
      </Button>
    </div>

    <TransitionExpand>
      <TransitionGroup name="fade" tag="div" mode="out-in" appear class="tw-relative" v-if="open">

        <div class="tw-h-8 tw-w-full" key="spacer" />

        <div v-if="store.settings.profileLayout === 'card' && !store.settings.isMobile" key="card" class="tw-flex tw-flex-col tw-gap-3">
          <ProfileCard v-for="profile in fullList" :key="profile.id" :data="profile" class="tw-w-full" />
        </div>
      
        <div v-if="store.settings.profileLayout === 'row' || store.settings.isMobile" key="row" class="tw-flex tw-flex-col tw-gap-3 tw-w-full">
          <ProfileRow v-for="profile in fullList" :key="profile.id" :data="profile" />
        </div>

        <div v-if="checkList.length" key="check" class="tw-my-3">
          <h3 class="tw-text-lg tw-font-medium tw-mb-2">{{ checkList.length }} networks have accounts registered</h3>
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <Card v-for="profile in checkList" :key="profile.id" class="tw-flex tw-flex-col tw-gap-y-2 tw-p-3">
              <h4 class="tw-text-xl tw-font-semibold">{{ uppercaseFirstLetter(profile.network) }}</h4>
  
              <SourceTags :data="[profile.source]" />
            </Card>
          </div>
        </div>
      </TransitionGroup>
    </TransitionExpand>
  </ResultSection>
</template>
