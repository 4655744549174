var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:_setup.cn(
      'peer tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-background disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-bg-input',
      _setup.props.size === 'sm' && 'tw-h-[20px] tw-w-[34px]',
      _setup.props.size !== 'sm' && 'tw-h-[24px] tw-w-[44px]',
      _setup.toggled && 'tw-bg-prime',
      _vm.$attrs.class
    ),attrs:{"disabled":_setup.props.disabled},on:{"click":_setup.handleClick}},[_c('span',{class:_setup.cn(
        'tw-pointer-events-none tw-block tw-rounded-full tw-bg-background tw-shadow-lg tw-ring-0 tw-transition-transform tw-translate-x-0',
        _setup.props.size === 'sm' && _setup.toggled && 'tw-translate-x-[14px]',
        _setup.toggled && _setup.props.size !== 'sm' && 'tw-translate-x-[20px]',
        _setup.props.size === 'sm' && 'tw-h-4 tw-w-4',
        _setup.props.size !== 'sm' && 'tw-h-5 tw-w-5',
      )})])
}
var staticRenderFns = []

export { render, staticRenderFns }