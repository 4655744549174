import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.PERSONAL_INFORMATION;

export const PI_KEY = {
    AGE: TABLE_KEY.AGE,
    AGE_RANGE: TABLE_KEY.AGE_RANGE,
    CREDIT: 'credit',
    DOB: TABLE_KEY.DOB,
    GENDER: TABLE_KEY.GENDER,
    INCOME: TABLE_KEY.INCOME,
    JOB_TYPE: TABLE_KEY.JOB_TYPE,
    LANGUAGES: TABLE_KEY.LANGUAGES,
    LOCALE: TABLE_KEY.LOCALE,
    NATIONALITY_CODE: TABLE_KEY.NATIONALITY_CODE,
    NET_WORTH: TABLE_KEY.NET_WORTH,
    ORG: TABLE_KEY.ORG,
    PERSONAL_BRAND: TABLE_KEY.PERSONAL_BRAND,
    PERSONAL_BRAND_ID: TABLE_KEY.PERSONAL_BRAND_ID,
    RELATIONSHIP_STATUS: TABLE_KEY.RELATIONSHIP_STATUS,
    TIMEZONE: TABLE_KEY.TIMEZONE,
    UI_INFO: TABLE_KEY.UI_INFO,
    VRN: TABLE_KEY.VRN,
    WORK_PLACE: TABLE_KEY.WORK_PLACE,
};

export function getPersonalInformation(result) {
  const personalInfo = {};

  if (result.raw[TABLE_KEY.AGE]) {
      personalInfo[PI_KEY.AGE] = result.raw[TABLE_KEY.AGE];
  }

  if (result.raw[TABLE_KEY.AGE_RANGE]) {
      personalInfo[PI_KEY.AGE_RANGE] = result.raw[TABLE_KEY.AGE_RANGE];
  }

  if (result.raw[TABLE_KEY.CREDIT_RATING] || result.raw[TABLE_KEY.CREDIT_SCORE]) {
      personalInfo[PI_KEY.CREDIT] =
          result.raw[TABLE_KEY.CREDIT_RATING] || result.raw[TABLE_KEY.CREDIT_SCORE];
  }

  const dobExists =
      result.raw[TABLE_KEY.DOB] ||
      result.raw[TABLE_KEY.DOB_DAY] ||
      result.raw[TABLE_KEY.DOB_MONTH] ||
      result.raw[TABLE_KEY.DOB_YEAR];

  if (dobExists) {
      if (result.raw[TABLE_KEY.DOB]) {
          personalInfo[PI_KEY.DOB] = result.raw[TABLE_KEY.DOB];
      } else if (
          result.raw[TABLE_KEY.DOB_DAY] &&
          result.raw[TABLE_KEY.DOB_MONTH] &&
          result.raw[TABLE_KEY.DOB_YEAR]
      ) {
          personalInfo[PI_KEY.DOB] = `${result.raw[TABLE_KEY.DOB_DAY]}/${
              result.raw[TABLE_KEY.DOB_MONTH]
          }/${result.raw[TABLE_KEY.DOB_YEAR]}`;
      }
  }

  if (result.raw[TABLE_KEY.GENDER]) {
    personalInfo[PI_KEY.GENDER] = result.raw[TABLE_KEY.GENDER];
  }

  if (result.raw[TABLE_KEY.INCOME]) {
    personalInfo[PI_KEY.INCOME] = result.raw[TABLE_KEY.INCOME];
  }

  if (result.raw[TABLE_KEY.JOB_TYPE]) {
    personalInfo[PI_KEY.JOB_TYPE] = result.raw[TABLE_KEY.JOB_TYPE];
  }

  if (result.raw[TABLE_KEY.LANGUAGES]) {
    personalInfo[PI_KEY.LANGUAGES] = result.raw[TABLE_KEY.LANGUAGES];
  }

  if (result.raw[TABLE_KEY.LOCALE]) {
    personalInfo[PI_KEY.LOCALE] = result.raw[TABLE_KEY.LOCALE];
  }

  if (result.raw[TABLE_KEY.NATIONALITY_CODE]) {
    personalInfo[PI_KEY.NATIONALITY_CODE] = result.raw[TABLE_KEY.NATIONALITY_CODE];
  }

  if (result.raw[TABLE_KEY.NET_WORTH]) {
    personalInfo[PI_KEY.NET_WORTH] = result.raw[TABLE_KEY.NET_WORTH];
  }

  if (result.raw[TABLE_KEY.ORG]) {
    personalInfo[PI_KEY.ORG] = result.raw[TABLE_KEY.ORG];
  }

  if (result.raw[TABLE_KEY.PERSONAL_BRAND]) {
    personalInfo[PI_KEY.PERSONAL_BRAND] = result.raw[TABLE_KEY.PERSONAL_BRAND];
  }

  if (result.raw[TABLE_KEY.PERSONAL_BRAND_ID]) {
    personalInfo[PI_KEY.PERSONAL_BRAND_ID] = result.raw[TABLE_KEY.PERSONAL_BRAND_ID];
  }

  if (result.raw[TABLE_KEY.RELATIONSHIP_STATUS]) {
    personalInfo[PI_KEY.RELATIONSHIP_STATUS] = result.raw[TABLE_KEY.RELATIONSHIP_STATUS];
  }

  if (result.raw[TABLE_KEY.TIMEZONE]) {
    personalInfo[PI_KEY.TIMEZONE] = result.raw[TABLE_KEY.TIMEZONE];
  }

  if (result.raw[TABLE_KEY.UI_INFO]) {
    personalInfo[PI_KEY.UI_INFO] = result.raw[TABLE_KEY.UI_INFO];
  }

  if (result.raw[TABLE_KEY.VRN]) {
    personalInfo[PI_KEY.VRN] = result.raw[TABLE_KEY.VRN];
  }

  if (result.raw[TABLE_KEY.WORK_PLACE]) {
    personalInfo[PI_KEY.WORK_PLACE] = result.raw[TABLE_KEY.WORK_PLACE];
  }

  return personalInfo;
}
