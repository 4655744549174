<template>
    <div>
        <div class="card card-default mb-4">
            <div class="card-header">
                New monitoring instruction
            </div>
            <div class="card-body">
                <b-form row @submit.prevent="createTic" @reset.prevent="resetForm">
                    <b-form-row>
                        <b-col cols="12">
                            <b-alert :show="hasMessage" variant="danger">
                                {{ message }}
                            </b-alert>
                        </b-col>
                        <div class="col-6">
                            <b-form-group id="input-group__platform"
                                          description="Which social media site would you like to monitor?">
                                <b-form-select id="input__platform"
                                               :options="platforms"
                                               required
                                               v-model="form.platform"/>
                            </b-form-group>
                            <b-form-group id="input-group__profile"
                                          description="Social Media Username of the profile you wish to monitor">
                                <b-form-input type="text"
                                              id="input__profile"
                                              placeholder="Username"
                                              required
                                              v-model="form.profile"/>
                            </b-form-group>
                            <b-form-group id="input-group__client-reference"
                                          description="Your reference for this instruction">
                                <b-form-input type="text"
                                              id="input__client-reference"
                                              placeholder="Client reference"
                                              required
                                              v-model="form.client_reference"/>
                            </b-form-group>
                        </div>
                        <div class="col-6">
                            <b-form-group id="input-group__duration"
                                          description="How long to monitor the profile (months)">
                                <b-form-input type="number"
                                              id="input__duration"
                                              required
                                              v-model="form.duration"
                                              min="0"/>
                            </b-form-group>
                            <b-form-group id="input-group__max-stories"
                                          description="Maximum number of stories to capture">
                                <b-form-input type="number"
                                              id="input__max-stories"
                                              v-model="form.max_stories"/>
                            </b-form-group>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary">
                                Create TIC job
                            </button>
                            <button type="reset" class="btn btn-danger">
                                Reset Form
                            </button>
                        </div>
                    </b-form-row>
                </b-form>
            </div>
        </div>
        <temporary-intelligence-capture-list :tics="tics"/>
    </div>
</template>

<script>
    import TemporaryIntelligenceCaptureList from '../../components/partials/TemporaryIntelligenceCaptureList'
    import Form from 'vform'
    import axios from 'axios'
    import { get } from 'lodash'
    import { mapGetters } from 'vuex'

    export default {
        name: 'TemporaryIntelligenceCaptureIndex',
        beforeRouteEnter (to, from, next) {
            next(async vm => {
                await vm.$store.dispatch('temporaryIntelligenceCaptures/fetchTics')
            })
        },
        components: {
            TemporaryIntelligenceCaptureList
        },
        computed: {
            ...mapGetters({
                loading: 'temporaryIntelligenceCaptures/loading',
                tics: 'temporaryIntelligenceCaptures/tics'
            }),
            hasMessage () {
                return this.message !== null
            }
        },
        data () {
            return {
                form: new Form({
                    duration: null,
                    max_stories: null,
                    platform: 'instagram',
                    profile: '',
                    client_reference: '',
                }),
                message: null,
                platforms: [
                    {
                        value: 'instagram',
                        text: 'Instagram',
                    }
                ],
            }
        },
        methods: {
            createTic () {
                axios.post(`/api/temporary-intelligence-capture`, this.form)
                    .catch(e => {
                        console.error(e.response)

                        this.message = get(e.response.data, 'message', null)
                    })
                    .finally(() => {
                        this.$store.dispatch('temporaryIntelligenceCaptures/fetchTics')
                    })
            },
            resetForm () {
                this.form.duration = 0
                this.form.platform = 'instagram'
                this.form.profile = ''
                this.form.client_reference = ''
            },
        },
    }
</script>
