import { onMounted, onUnmounted } from 'vue';
import usePfpStore from '@/pinia/pfp';
import '../../sass/pfp2.scss';

/**
 * useTheme options
 * @property {boolean} [noDarkMode] - Disable dark mode
 **/
export default function useTheme(options = {}) {
    const store = usePfpStore();

    onMounted(() => {
        document.body.classList.add('pfp2');

        if (store.settings.mode === 'dark' && !options.noDarkMode) {
            document.body.classList.add('tw-dark');
        }
    });

    onUnmounted(() => {
        document.body.classList.remove('pfp2');
    });
}
