<template>
    <div>
        <b-card class="card-default">
            <template #header>
                <div class="d-flex align-items-center justify-content-between">
                    <span>New search instruction</span>
                    <b-btn tag="a" :disabled="true" href="#" @click.prevent="showModal = !showModal">
                        Bulk Import
                    </b-btn>
                </div>
            </template>
            <b-modal v-model="showModal" @ok.prevent="uploadBulk">
                <template v-slot:modal-title>
                    Bulk Import
                </template>
                <template v-slot:default>
                    <a href="/downloads/templates/profile-finder-plus-import" class="text-center">
                        Download Import Template
                    </a>
                    <hr>
                    <b-form @submit.prevent="uploadBulk">
                        <b-form-file v-model="upload.file"
                                     :state="Boolean(upload.file)"
                        />
                    </b-form>
                </template>
            </b-modal>
            <b-alert :show="hasMessage" variant="danger">
                {{ message }}
            </b-alert>
            <b-form @submit.prevent="submit" @reset.prevent="reset" class="row">
                <form-profile-finder-plus-contact :form="form"
                                                  @pfp-add-contact="addContact"
                />
                <div class="col-12 text-center">
                    <button type="submit" class="btn btn-primary mt-2">Submit</button>
                </div>
            </b-form>
        </b-card>
        <template>
            <div class="card card-default mt-4">
                <div class="card-header card-header__primary">
                    <ul class="jobs-filter">
                        <li>
                            <a :class="{'selected-filter': tab === 'all'}" href="#" @click.prevent="tab = 'all'">
                                All
                            </a>
                        </li>
                        <li>
                            <a :class="{'selected-filter': tab === 'successful'}" href="#"
                               @click.prevent="tab = 'successful'">
                                Successful
                            </a>
                        </li>
                        <li>
                            <a :class="{'selected-filter': tab === 'failed'}" href="#" @click.prevent="tab = 'failed'">
                                Failed
                            </a>
                        </li>
                        <li>
                            <a :class="{'selected-filter': tab === 'pending'}" href="#"
                               @click.prevent="tab = 'pending'">
                                Pending
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <b-overlay :show="loading">
                        <div class="row">
                            <div class="col-4" v-for="(result, key) in filteredInstructions" :key="key"
                                 v-if="hasInstructions">
                                <card-profile-finder-plus-search :result="result"/>
                            </div>
                            <div class="col-12 d-flex justify-content-around" v-if="hasInstructions">
                                <b-pagination v-model="page"
                                              :total-rows="meta.total"
                                              :per-page="meta.per_page">
                                </b-pagination>
                            </div>
                            <div class="col-12" v-if="!hasInstructions">
                                <div class="alert alert-success text-center">
                                    Your submitted Profile Finder Plus instructions will load here
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import axios from 'axios'
    import CardProfileFinderPlusSearch from './partials/CardProfileFinderPlusSearch'
    import FormProfileFinderPlusContact from './partials/FormProfileFinderPlusContact'
    import { mapGetters } from 'vuex'
    import Form from 'vform'
    import { get } from 'lodash'

    export default {
        name: 'ProfileFinderPlusSearch',
        components: {
            FormProfileFinderPlusContact,
            CardProfileFinderPlusSearch
        },
        created () {
            this.$store.dispatch('profileFinderPlus/fetchInstructions')
        },
        computed: {
            ...mapGetters({
                filteredInstructions: 'profileFinderPlus/filteredInstructions',
                instructions: 'profileFinderPlus/instructions',
                hasInstructions: 'profileFinderPlus/hasInstructions',
                hasMessage: 'profileFinderPlus/hasMessage',
                message: 'profileFinderPlus/message',
                meta: 'profileFinderPlus/meta',
                loading: 'profileFinderPlus/loading'
            }),
            message: {
                get () {
                    return this.$store.state.profileFinderPlus.message
                },
                set (value) {
                    this.$store.commit('profileFinderPlus/SET_MESSAGE', value)
                }
            },
            page: {
                get () {
                    return this.$store.state.profileFinderPlus.page
                },
                set (value) {
                    this.$store.dispatch('profileFinderPlus/updatePage', value)
                }
            },
            tab: {
                get () {
                    return this.$store.state.profileFinderPlus.tab
                },
                set (value) {
                    this.$store.dispatch('profileFinderPlus/updateTab', value)
                }
            }
        },
        data () {
            return {
                form: new Form({
                    firstname: '',
                    middlenames: '',
                    lastname: '',
                    contacts: [
                        {
                            contact: '',
                        }
                    ],
                    reference: '',
                }),
                showModal: false,
                upload: new Form({
                    file: null
                })
            }
        },
        methods: {
            addContact () {
                this.form.contacts.push({
                    contact: '',
                })
            },
            reset () {
                this.form.firstname = ''
                this.form.middlenames = ''
                this.form.lastname = ''
                this.form.contacts = [
                    {
                        contact: ''
                    },
                ]
                this.form.reference = ''
            },
            async submit () {
                this.$store.commit('profileFinderPlus/SET_LOADING', true)
                this.message = ''

                try {
                    await this.form.post(`/api/profile-finder-plus`)
                    this.reset()
                } catch (e) {
                    this.message = get(e.response, 'data.message', null)
                } finally {
                    await this.$store.dispatch('profileFinderPlus/fetchInstructions')
                }
            },
            async uploadBulk () {
                this.upload.startProcessing()

                axios.post(`/uploads/bulk/profile-finder-plus-import`, this.gatherFormData())
                    .then(() => {
                        Bus.$emit('uploadPfpBulkCsv')

                        this.upload.finishProcessing()
                    }, error => {
                        this.upload.setErrors(error.response.data.errors)
                    })
                    .finally(() => {
                        this.page = 1
                        this.$store.dispatch('profileFinderPlus/fetchInstructions')
                        this.showModal = false
                        this.upload.file = null
                    })
            },

            /**
             * Gather the form data for the file upload.
             */
            gatherFormData () {
                const data = new FormData()

                data.append('file', this.upload.file)

                return data
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '../../sass/variables';

    .primary-color {
        background-color: $primary-color;
        color: $white;
    }

    .btn-circle {
        padding: 10px 14px;
        border: none;
        border-radius: 50%;
        color: $white;
        background-color: $primary-color;
    }

    .jobs-filter {
        font-weight: 400;
        margin-block-end: 0;
        padding-inline-start: 0;
        border-bottom: 1px solid $white;

        li {
            display: inline-block;
            margin-right: 40px;
            position: relative;
            padding-bottom: 6px;

            a {
                color: $white;
                text-decoration: none;
            }

            .selected-filter {
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    position: absolute;
                    bottom: -3px;
                    height: 6px;
                    background-color: $white;
                    border-radius: 3px;
                }
            }
        }
    }
</style>
