function page(path) {
    return () => import(/* webpackChunkName: '' */ `../pages/${path}`).then(
        m => m.default || m)
}

import HomeDashboard from '../pages/Home/HomeDashboard'
import PrivacyChecker from '../pages/PrivacyChecker'
import ProfileFinder from '../pages/ProfileFinder'
import ProfileFinderPlus from '../pages/ProfileFinderPlus'
import ProfileFinderPlusSearch from '../components/ProfileFinderPlusSearch'
import ProfileFinderPlusUpdate from '../components/ProfileFinderPlusUpdate'
import TemporaryIntelligenceCapture from '../pages/TemporaryIntelligenceCapture'
import TemporaryIntelligenceCaptureIndex
    from '../pages/TemporaryIntelligenceCapture/TemporaryIntelligenceCaptureIndex'
import TemporaryIntelligenceCaptureProfile
    from '../pages/TemporaryIntelligenceCapture/TemporaryIntelligenceCaptureProfile'
import LandRegistryPage from '../pages/LandRegistryPage'
import Lookup from '../pages/OsintIndustriesLookup/Lookup.vue'
import View from '../pages/OsintIndustriesLookup/View.vue'
import Index from '../pages/OsintIndustriesLookup/Index.vue'
import ProfileFinderPlusTwo from '../pages/ProfileFinderPlusTwo.vue';
import VirtualWitnessPage from '@/pages/VirtualWitnessPage.vue';
import VirtualWitnessIndex from '@/pages/VirtualWitness/VirtualWitnessIndex.vue';

export default [
    {
        path: '/',
        name: 'welcome',
        component: page('WelcomePage.vue')
    },
    {
        path: '/login',
        name: 'login', component: page('Auth/LoginPage.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: page('Auth/RegisterPage.vue')
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset',
        component: page('Auth/ResetPasswordPage.vue')
    },
    {
        path: '/password/reset',
        name: 'password.request',
        component: page('Auth/ForgotPasswordPage.vue')
    },
    {
        path: '/email/verify/:id',
        name: 'verification.verify',
        component: page('Auth/VerifyEmailPage.vue')
    },
    {
        path: '/email/resend',
        name: 'verification.resend',
        component: page('Auth/ResendPage.vue')
    },
    {
        path: '/home',
        component: page('AppHome.vue'),
        children: [
            {
                path: '/home',
                component: HomeDashboard,
                name: 'home',
            }
        ]
    },
    {
        path: '/settings',
        component: page('SettingsPage.vue'),
        children: [
            {
                path: '',
                name: 'settings',
                redirect: {
                    name: 'settings.teams'
                }
            },
            {
                path: 'teams',
                name: 'settings.teams',
                component: page('Settings/Teams.vue')
            },
            {
                path: 'teams/:id/switch',
                name: 'settings.teams.switch',
                component: page('Settings/SwitchTeams.vue')
            },
            {
                path: 'api',
                name: 'settings.api',
                component: page('Settings/Api.vue')
            }
        ]
    },
    {
        path: '*',
        component: page('Errors/404Page.vue')
    },
    {
        path: '/support',
        name: 'support',
        component: page('Support/Contact.vue')
    },
    {
        path: '/my-details',
        name: 'my-details',
        component: page('MyDetails.vue'),
    },
    {
        path: '/kiosk',
        component: page('KioskPage.vue'),
        children: [
            {
                path: '/kiosk/announcements',
                name: 'kiosk.announcements',
                component: page('Kiosk/Announcements.vue')
            },
            {
                path: '/kiosk/billing',
                name: 'kiosk.billing',
                component: page('Kiosk/Billing.vue')
            },
            {
                path: '/kiosk/oauth',
                name: 'kiosk.oauth',
                component: page('Kiosk/Oauth.vue')
            },
            {
                path: '/kiosk/pay-as-you-gos',
                name: 'kiosk.pay-as-you-gos',
                component: page('Kiosk/PayAsYouGos.vue')
            },
            {
                path: '/kiosk/users',
                name: 'kiosk.users',
                component: page('Kiosk/Users.vue')
            },
            {
                path: '/kiosk/vouchers',
                name: 'kiosk.vouchers',
                component: page('Kiosk/Vouchers.vue')
            }
        ]
    },
    {
        path: '/gumtree',
        name: 'gumtree',
        component: page('GumtreePage.vue')
    },
    {
        path: '/gumtree/:id',
        name: 'gumtree.show',
        component: page('Gumtree/Show.vue')
    },
    {
        path: '/gumtree-bulk',
        name: 'gumtree.bulk',
        component: page('Gumtree/Bulk.vue')
    },
    {
        path: '/privacy-checker',
        name: 'privacy-checker',
        component: PrivacyChecker,
    },
    {
        path: '/profile-finder',
        name: 'profile-finder',
        component: ProfileFinder,
    },
    {
        path: '/profile-finder-plus',
        component: ProfileFinderPlus,
        children: [
            {
                path: '/profile-finder-plus',
                name: 'profile-finder-plus',
                component: ProfileFinderPlusSearch
            },
            {
                path: '/profile-finder-plus/:uuid',
                name: 'profile-finder-plus-update',
                component: ProfileFinderPlusUpdate
            }
        ]
    },
    {
        path: '/profile-finder-plus-2',
        name: 'profile-finder-plus-two',
        component: ProfileFinderPlusTwo,
    },
    {
        path: '/temporary-intelligence-capture',
        component: TemporaryIntelligenceCapture,
        children: [
            {
                path: '/temporary-intelligence-capture',
                name: 'temporary-intelligence-capture',
                component: TemporaryIntelligenceCaptureIndex
            },
            {
                path: '/temporary-intelligence-capture/:uuid',
                name: 'temporary-intelligence-capture-profile',
                component: TemporaryIntelligenceCaptureProfile
            }
        ],
    },
    {
        path: '/land-registry',
        component: LandRegistryPage,
        name: 'land-registry'
    },
    {
        path: '/facebook-id-lookup',
        name: 'facebook-id-lookup',
        component: page('FacebookIdLookup.vue')
    },
    {
        path: '/system',
        name: 'system.teams-administration',
        component: page('System/TeamsAdministration.vue')
    },

    //teams
    {
        path: '/teams',
        component: page('TeamsPage'),
        children: [
            {
                path: 'create',
                name: 'teams.create-team',
                component: page('Teams/Create')
            },
            {
                path: 'settings',
                name: 'teams.settings',
                component: page('Teams/Settings')
            }
        ]
    },
    {
        path: '/team/settings/:id',
        component: page('TeamPage'),
        children: [
            {
                path: 'profile',
                name: 'settings.team.profile',
                component: page('Team/Settings')
            },
            {
                path: 'membership',
                name: 'settings.team.membership',
                component: page('Team/Membership')
            },
            {
                path: 'vouchers',
                name: 'settings.team.vouchers',
                component: page('Team/Vouchers')
            }
        ]
    },
    {
        path: '/hlr-lookup',
        component: page('HlrLookupPage.vue'),
        children: [
            {
                path: '/',
                name: 'hlr-lookup',
                component: page('HlrLookup/Index')
            },
            {
                path: 'lookup',
                name: 'hlr-lookup.lookup',
                component: page('HlrLookup/Lookup')
            },
            {
                path: ':uuid',
                name: 'hlr-lookup.view',
                component: page('HlrLookup/View')
            }
        ]
    },
    {
        path: '/osint-industries-lookup',
        component: page('OsintIndustriesLookupPage.vue'),
        children: [
            {
                path: '/',
                name: 'osint-industries-lookup',
                component: Index
            },
            {
                path: 'lookup',
                name: 'osint-industries-lookup.lookup',
                component: Lookup
            },
            {
                path: ':uuid',
                name: 'osint-industries-lookup.view',
                component: View
            }
        ]
    },
    {
        path: '/virtual-witness',
        component: VirtualWitnessPage,
        children: [
            {
                path: '/',
                name: 'virtual-witness',
                component: VirtualWitnessIndex
            }
        ]
    },
    {
        path: '/terms',
        component: page('Terms')
    },
]
