<template>
    <div>
        <b-alert :show="hasMessage" variant="danger">
            {{ message }}
        </b-alert>
        <b-form @submit.prevent="search" class="py-4">
            <b-row>
                <b-col cols="12">
                    <b-form-group id="input-group__property_address"
                                  description="Address of the property to search for"
                                  label="Property Address"
                                  label-for="input__property-address">
                        <b-form-input type="text"
                                      id="input__property-address"
                                      v-model="proprietorsForm.property_address"
                                      trim>
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group id="input-group__title"
                                  description="Title of the property to search for"
                                  label="Property Title"
                                  label-for="input__title">
                        <b-form-input type="text"
                                      id="input__title-address"
                                      v-model="proprietorsForm.title_number"
                                      trim>
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group id="input-group__company_name"
                                  description="Name of owning company"
                                  label="Company Name"
                                  label-for="input__company-name">
                        <b-form-input type="text"
                                      id="input__company-name"
                                      v-model="proprietorsForm.company_name"
                                      trim>
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-end mt-3 pr-4">
                <b-button type="submit" variant="primary">
                    Search
                </b-button>
            </div>
        </b-form>
        <template v-if="hasProprietors">
            <land-registry-proprietor v-for="proprietor in proprietors"
                                      :key="proprietor.title_number"
                                      :proprietor="proprietor">
            </land-registry-proprietor>
        </template>
    </div>
</template>

<script>
    import ProprietorSection from '../LandRegistry/ProprietorSection'
    import { mapGetters, mapState } from 'vuex'
    import LandRegistryProprietor from '../../components/partials/LandRegistryProprietor'

    export default {
        name: 'ProprietorTab',
        components: {
            LandRegistryProprietor,
            ProprietorSection,
        },
        computed: {
            ...mapGetters({
                hasMessage: 'landRegistry/hasMessage',
                hasProprietors: 'landRegistry/hasProprietors',
                message: 'landRegistry/message',
                proprietors: 'landRegistry/proprietors',
            }),
            ...mapState({
                proprietorsForm: state => state.landRegistry.proprietorsForm,
            }),
        },
        methods: {
            async search () {
                return await this.$store.dispatch('landRegistry/fetchProprietors')
            },
        }
    }
</script>

<style scoped>

</style>
