import moment from 'moment'

export default {
    filters: {
        localDate (value) {
            if (!value) {
                return ''
            }

            return moment.unix(value).toISOString(false)
        },
        localDatetime (value) {
            if (!value) {
                return ''
            }

            return moment.unix(value).toISOString(false)
        }
    },
    methods: {
        localDateTimeFormatter (value) {
            if (!value) {
                return ''
            }

            return moment.unix(value).toISOString(false)
        }
    }
}
