<script>
import { onMounted } from "vue";
import { InfoIcon } from "lucide-vue";
import { PortalTarget } from "portal-vue";
import useVWStore from "@/pinia/vw";
import useTheme from "@/composables/useTheme";
import ModeToggle from "@/components/pfp/mode-toggle/ModeToggle.vue";
import VirtualWitnessSearcher from "@/components/pfp/virtual-witness-searcher/VirtualWitnessSearcher.vue";

export default {
    name: "VirtualWitnessPage",
    middleware: "auth",
    components: {
        ModeToggle,
        VirtualWitnessSearcher,
        PortalTarget,
        InfoIcon,
    },
    setup() {
        const store = useVWStore();

        useTheme();

        onMounted(() => {
            store.fetchResults();
        });

        return {
            store,
        };
    },
};
</script>

<template>
    <div class="tw-flex tw-flex-col tw-items-center tw-pb-10">
        <ModeToggle class="tw-absolute tw-right-2 tw-top-0" />

        <div class="tw-mt-6">
            <h1 class="tw-font-bold tw-text-3xl text-center">
                Virtual Witness
            </h1>
            <p
                class="tw-font-light tw-text-muted-foreground text-center tw-max-w-[350px]"
            >
                Search for publicly available footage and images of Road Traffic
                Accidents.
            </p>
        </div>

        <div
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full"
        >
            <VirtualWitnessSearcher />

            <div class="tw-relative tw-w-full">
                <div
                    class="tw-relative tw-overflow-visible tw-w-full -tw-z-10 tw-flex tw-items-center tw-justify-center"
                >
                    <div
                        class="tw-aspect-square tw-w-full md:tw-w-4/5 tw-fixed tw-bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(56,189,248,0.12)_6.77%,_rgba(124,201,235,0.05)_46.35%,_rgba(217,217,217,0)_100%)] tw-dark:tw-bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(56,_189,_248,_0.37)_6.77%,_rgba(124,_201,_235,_0.21)_46.35%,_rgba(217,_217,_217,_0)_100%)]"
                    />
                </div>

                <router-view />
            </div>
        </div>

        <div
            class="tw-fixed tw-right-2 tw-bottom-2 tw-flex-col-reverse tw-max-w-[320px] tw-w-full"
            :style="{ zIndex: 51 }"
        >
            <div
                v-for="notif in store.notifications.active"
                :key="notif.id"
                class="tw-relative tw-w-full tw-rounded-lg tw-border tw-p-4 [&amp;>svg~*]:tw-pl-7 [&amp;>svg+div]:tw-translate-y-[-3px] [&amp;>svg]:tw-absolute [&amp;>svg]:tw-left-4 [&amp;>svg]:tw-top-4 [&amp;>svg]:tw-text-foreground tw-bg-background tw-text-foreground tw-flex tw-gap-x-2 tw-mt-2"
            >
                <InfoIcon class="tw-w-4 tw-h-4 tw-text-foreground" />
                <div>
                    <h5
                        class="tw-mb-1 tw-font-medium tw-leading-none tw-tracking-tight"
                    >
                        {{ notif.title }}
                    </h5>
                    <div class="tw-text-sm [&amp;_p]:tw-leading-relaxed">
                        {{ notif.message }}
                    </div>
                </div>
            </div>
        </div>

        <portal-target name="overlay"></portal-target>
    </div>
</template>
