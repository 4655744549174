import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.CONTACT_INFORMATION;

export const CI_KEY = {
  DIAL_CODE: TABLE_KEY.DIAL_CODE,
  EMAILS: "emails",
  FAX: TABLE_KEY.FAX,
  PHONE_NUMBERS: "phone_numbers",
  PHONE_COUNTRY_CODE: "phone_country_code",
};

export function getContactInformation(result) {
  const contactInfo = {};

  const emails = [
    result.raw[TABLE_KEY.EMAIL],
    result.raw[TABLE_KEY.EMAIL_2],
    result.raw[TABLE_KEY.EMAIL_3],
    result.raw[TABLE_KEY.EMAIL_4],
    result.raw[TABLE_KEY.EMAIL_ORIGINAL],
  ].filter(Boolean);

  if (emails.length > 0) {
      contactInfo[CI_KEY.EMAILS] = emails;
  }

  if (result.raw[TABLE_KEY.DIAL_CODE]) {
      contactInfo[CI_KEY.DIAL_CODE] = result.raw[TABLE_KEY.DIAL_CODE];
  }

  if (result.raw[TABLE_KEY.FAX]) {
      contactInfo[CI_KEY.FAX] = result.raw[TABLE_KEY.FAX];
  }

  const phoneNumbers = [
    result.raw[TABLE_KEY.PHONE_NUMBER],
    result.raw[TABLE_KEY.PHONE_NUMBER_2],
    result.raw[TABLE_KEY.PHONE_NUMBER_3],
    result.raw[TABLE_KEY.PHONE_NUMBER_E164],
    result.raw[TABLE_KEY.PHONE_NUMBER_E164_UK],
    result.raw[TABLE_KEY.TELEPHONE_NUMBER],
    result.raw[TABLE_KEY.TELEPHONE_NUMBER_2],
  ]
    .filter(Boolean)
    .map(String);

  if (phoneNumbers.length > 0) {
      contactInfo[CI_KEY.PHONE_NUMBERS] = phoneNumbers;
  }

  if (result.raw[TABLE_KEY.COUNTRY_CODE] || result.raw[TABLE_KEY.PHONE_NUMBER_COUNTRY_CODE] || result.raw[TABLE_KEY.TELEPHONE_NUMBER_COUNTRY_CODE]) {
      contactInfo[CI_KEY.PHONE_COUNTRY_CODE] = result.raw[TABLE_KEY.COUNTRY_CODE] || result.raw[TABLE_KEY.PHONE_NUMBER_COUNTRY_CODE] || result.raw[TABLE_KEY.TELEPHONE_NUMBER_COUNTRY_CODE];
  }

  return contactInfo;
}
