<script setup>
import { ref, computed } from 'vue';
import { PlusCircleIcon } from 'lucide-vue';
import { SOURCE_LABELS } from '@/components/pfp/const';
import Button from '@/components/pfp/ui/button/Button.vue';
import ResultSection from '@/components/pfp/results-table/ResultSection.vue';
import ResultTitle from '@/components/pfp/results-table/ResultTitle.vue';
import SourceTags from '@/components/pfp/source-tags/SourceTags.vue';
import Toggle from '../../../ui/toggle/Toggle.vue';
import Label from '../../../ui/label/Label.vue';

const props = defineProps({
  sources: {
    type: Array,
    required: true,
  } 
})

const emit = defineEmits(['add:sources']);

const selected = ref(props.sources.reduce((acc, source) => {
  acc[source] = true;

  return acc;
}, {}));

const list = computed(() => {
  return props.sources
    .map(source => SOURCE_LABELS[source])
    .join(', ')
    .replace(/, ([^,]*)$/, ' or $1');
})

function handleChecked(source, checked) {
  selected.value[source] = checked;
}

function handleAddSources() {
  emit('add:sources', Object.keys(selected.value).filter(source => selected.value[source]));
}
</script>

<template>
  <ResultSection class="tw-flex tw-flex-col tw-justify-between tw-items-start tw-gap-2">
    <div>
      <ResultTitle>
        Add more sources?
      </ResultTitle>
      <p class="tw-text-muted-foreground tw-font-light tw-text-sm">
        You didn't add <span class="tw-text-foreground tw-font-normal">{{ list }}</span> as a source on your original query.
      </p>
      <p class="tw-text-muted-foreground tw-font-light tw-mt-1 tw-text-sm">
        By adding more sources you can widen your search drastically.
      </p>
    </div>

    <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-end md:tw-justify-between tw-gap-4 tw-w-full">
      <div class="tw-flex tw-flex-col tw-gap-y-4">
        <div>
          <Label class="tw-mt-3">
            Select sources
          </Label>
          <p class="tw-text-muted-foreground tw-font-light tw-text-sm">
            Choose which sources you wish to search by, you can select multiple sources.
          </p>
          <p class="tw-text-muted-foreground tw-font-light tw-text-sm tw-mt-1">
            This will create a new search with the same reference.
          </p>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-y-4">
          <div v-for="source in props.sources" :key="source" class="tw-grid tw-grid-cols-12 tw-gap-x-2">
            <SourceTags :data="[source]" class="tw-col-span-10 md:tw-col-span-4" />
            <Toggle class="tw-w-10 tw-h-10" @update:checked="handleChecked(source, $event)" :checked="selected[source]" />
          </div>
        </div>
      </div>
      
      <Button class="tw-w-full md:tw-w-auto" @click="handleAddSources">
        <PlusCircleIcon class="tw-mr-2 tw-w-5 tw-h-5" />
        Add selected sources
      </Button>
    </div>
  </ResultSection>
</template>
