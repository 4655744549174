var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.result)?_c('div',{staticClass:"tw-w-full tw-rounded-xl tw-border tw-border-border tw-p-4 md:tw-border-none md:tw-p-0",attrs:{"data-result":_vm.result.id}},[_c('div',{staticClass:"tw-grid-cols-[1fr_4fr_8fr_5fr_3fr_3fr] tw-transition-colors hover:tw-bg-muted tw-px-3 tw-py-4 tw-rounded-xl tw-cursor-pointer tw-items-center tw-justify-center tw-hidden md:tw-grid tw-relative",on:{"click":_setup.toggleRow}},[(_setup.isTeamOwner)?_c('p',{staticClass:"tw-text-xs tw-text-muted-foreground tw-absolute tw-bottom-1 tw-left-11"},[_vm._v("\n            Searched by "),_c('span',[_vm._v(_vm._s(_vm.result.user.name))]),_vm._v(" ("+_vm._s(_vm.result.team.name)+")\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-items-center"},[_c(_setup.ChevronRight,{class:_setup.cn(
                        'tw-w-5 tw-h-5 tw-text-foreground tw-transition-transform',
                        _setup.rowOpen && 'tw-rotate-90'
                    )})],1),_vm._v(" "),_c('div',{staticClass:"tw-text-sm tw-pr-1"},[_vm._v(_vm._s(_vm.result.reference))]),_vm._v(" "),_c('div',{staticClass:"tw-text-sm"},[_vm._v(_vm._s(_vm.result.query))]),_vm._v(" "),(!_setup.props.result.loading)?_c('div',{staticClass:"group tw-text-center"},[(_vm.result.sourcesMatched?.length === 0)?_c('p',{staticClass:"tw-text-sm tw-text-muted-foreground"},[_vm._v("\n                No sources matched\n            ")]):(_vm.result.sourcesMatched.length > 1)?_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":200},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_setup.SourceTags,{staticClass:"tw-inline-flex",attrs:{"data":[_vm.result.sourcesMatched?.[0]]}}),_vm._v(" "),_c('span',{staticClass:"tw-text-xs tw-text-muted-foreground group-hover:tw-text-foreground tw-inline-block tw-text-left"},[_vm._v("+ "+_vm._s(_vm.result.sourcesMatched.length - 1)+" more")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"tw-flex tw-gap-2"},[_c(_setup.SourceTags,{attrs:{"data":_vm.result.sourcesMatched}})],1)]},proxy:true}],null,false,2028360171)}):_c(_setup.SourceTags,{staticClass:"tw-inline-flex",attrs:{"data":_vm.result.sourcesMatched}})],1):_vm._e(),_vm._v(" "),(!_setup.props.result.loading)?_c('p',{staticClass:"tw-text-sm tw-text-center"},[_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":400},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',[_vm._v(_vm._s(_setup.format(_vm.result.startedDate, "dd/MM/yy")))])]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n                    "+_vm._s(_setup.formatRelative(_vm.result.startedDate, new Date(), {
                            locale: _setup.locale,
                        }))+"\n                ")]},proxy:true}],null,false,3819665328)}),_vm._v(" "),_c('span',{staticClass:"tw-text-sm tw-block tw-text-muted-foreground"},[_vm._v(_vm._s(_setup.format(_vm.result.startedDate, "p")))])],1):_vm._e(),_vm._v(" "),(!_setup.props.result.loading)?_c('p',{staticClass:"tw-text-sm tw-text-center"},[_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":400},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',[_vm._v(_vm._s(_setup.format(_vm.result.completedDate, "dd/MM/yy")))])]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n                    "+_vm._s(_setup.formatRelative(_vm.result.completedDate, new Date(), {
                            locale: _setup.locale,
                        }))+"\n                ")]},proxy:true}],null,false,1335804848)}),_vm._v(" "),_c('span',{staticClass:"tw-text-sm tw-block tw-text-muted-foreground"},[_vm._v(_vm._s(_setup.format(_vm.result.completedDate, "p")))])],1):_vm._e(),_vm._v(" "),(_setup.props.result.loading)?_c('p',{staticClass:"tw-absolute tw-right-2 tw-text-sm tw-bg-muted tw-p-3 tw-rounded-xl"},[_vm._v("\n            Your search has been started, open to view results.\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"md:tw-hidden tw-flex tw-flex-col tw-gap-y-6 tw-px-2 tw-py-4",on:{"click":_setup.toggleRow}},[_c('div',{staticClass:"tw-flex tw-gap-x-4"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c(_setup.ChevronRight,{class:_setup.cn(
                            'tw-w-5 tw-h-5 tw-text-foreground tw-transition-transform',
                            _setup.rowOpen && 'tw-rotate-90'
                        )})],1),_vm._v(" "),_c('div',[_c(_setup.Label,[_vm._v("Reference")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.result.reference))])],1)]),_vm._v(" "),_c('div',{staticClass:"tw-ml-9"},[_c(_setup.Label,[_vm._v("Query")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.result.query))])],1),_vm._v(" "),(_setup.props.result.loading)?_c('p',{staticClass:"tw-text-sm tw-bg-muted tw-p-3 tw-rounded-xl"},[_vm._v("\n            Your search has been started, open to view results.\n        ")]):_vm._e(),_vm._v(" "),(!_setup.props.result.loading)?_c('div',{staticClass:"tw-ml-9"},[_c(_setup.Label,[_vm._v("Sources matched")]),_vm._v(" "),_c(_setup.SourceTags,{attrs:{"data":_vm.result.sourcesMatched}}),_vm._v(" "),(_vm.result.sourcesMatched?.length === 0)?_c('p',[_vm._v("None")]):_vm._e()],1):_vm._e(),_vm._v(" "),(!_setup.props.result.loading)?_c('div',{staticClass:"tw-flex tw-gap-x-6 tw-ml-9"},[_c('div',[_c(_setup.Label,[_vm._v("Started")]),_vm._v(" "),_c('p',[_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":400},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',[_vm._v(_vm._s(_setup.format(_vm.result.startedDate, "dd/MM/yyyy")))])]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n                            "+_vm._s(_setup.formatRelative(
                                    _vm.result.startedDate,
                                    new Date(),
                                    { locale: _setup.locale }
                                ))+"\n                        ")]},proxy:true}],null,false,4118646460)}),_vm._v(" "),_c('span',{staticClass:"tw-text-sm tw-block tw-text-muted-foreground"},[_vm._v(_vm._s(_setup.format(_vm.result.startedDate, "p")))])],1)],1),_vm._v(" "),_c('div',[_c(_setup.Label,[_vm._v("Completed")]),_vm._v(" "),_c('p',[_c(_setup.Tooltip,{staticClass:"tw-block tw-w-full",attrs:{"hover":"","hover-intent":400},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',[_vm._v(_vm._s(_setup.format(_vm.result.completedDate, "dd/MM/yyyy")))])]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n                            "+_vm._s(_setup.formatRelative(
                                    _vm.result.completedDate,
                                    new Date(),
                                    { locale: _setup.locale }
                                ))+"\n                        ")]},proxy:true}],null,false,494637116)}),_vm._v(" "),_c('span',{staticClass:"tw-text-sm tw-block tw-text-muted-foreground"},[_vm._v(_vm._s(_setup.format(_vm.result.completedDate, "p")))])],1)],1)]):_vm._e(),_vm._v(" "),(_setup.isTeamOwner)?_c('p',{staticClass:"tw-text-xs tw-text-muted-foreground tw-ml-9"},[_vm._v("\n            Searched by "),_c('span',[_vm._v(_vm._s(_vm.result.user.name))]),_vm._v(" ("+_vm._s(_vm.result.team.name)+")\n        ")]):_vm._e()]),_vm._v(" "),_c(_setup.TransitionExpand,[(_setup.rowOpen)?_c('div',[_c('div',{staticClass:"tw-py-2 tw-flex tw-flex-col tw-gap-y-2"},[(_setup.props.result.loading)?_c('p',{staticClass:"tw-flex tw-items-center mx-auto tw-text-muted-foreground"},[_c('svg',{staticClass:"tw-animate-spin tw-mr-2 tw-h-5 tw-w-5 tw-text-muted-foreground",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"tw-opacity-50",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_vm._v(" "),_c('path',{staticClass:"tw-opacity-100",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]),_vm._v("\n                    Loading...\n                ")]):_vm._e(),_vm._v(" "),(_setup.noResults && !_setup.props.result.loading)?_c('p',{staticClass:"tw-text-center tw-text-muted-foreground"},[_vm._v("\n                    No results found\n                ")]):_vm._e(),_vm._v(" "),_vm._t("default")],2)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }