<script>
import {defineComponent} from 'vue'
import {mapGetters} from 'vuex';
import {get} from "lodash";

export default defineComponent({
    name: 'FormOsintIndustriesLookup',
    computed: {
        ...mapGetters({
            loading: 'osintIndustriesLookup/loading',
            message: 'osintIndustriesLookup/message',
            hasMessage: 'osintIndustriesLookup/hasMessage',
            form: 'osintIndustriesLookup/form',
            types: 'osintIndustriesLookup/types'
        }),
        client_reference: {
            get() {
                return this.$store.state.osintIndustriesLookup.form.client_reference
            },
            set(value) {
                this.$store.commit('osintIndustriesLookup/SET_FORM_CLIENT_REFERENCE', value)
            }
        },
        type: {
            get() {
                return this.$store.state.osintIndustriesLookup.form.type
            },
            set(value) {
                this.$store.commit('osintIndustriesLookup/SET_FORM_TYPE', value)
            }
        },
        query: {
            get() {
                return this.$store.state.osintIndustriesLookup.form.query
            },
            set(value) {
                this.$store.commit('osintIndustriesLookup/SET_FORM_QUERY', value)
            }
        },
    },
    methods: {
        async submit() {
            try {
                const data = await this.$store.dispatch('osintIndustriesLookup/lookup')

                if (get(data, 'uuid')) {
                    await this.$router.push({
                        name: 'osint-industries-lookup.view',
                        params: {
                            uuid: data.uuid
                        }
                    })
                }
            } catch (e) {
                console.warn(e)
            }
        }
    }
})
</script>

<template>
    <b-form @submit.prevent="submit">
        <b-overlay :show="loading"/>
        <b-form-row>
            <b-col cols="12">
                <b-alert variant="danger" :show="hasMessage">
                    {{ message }}
                </b-alert>
            </b-col>
            <b-col cols="4">
                <b-form-group id="input-group__client_reference"
                              label-for="input__client_reference">
                    <b-form-input id="input__client_reference"
                                  type="text"
                                  :placeholder="$t('client_reference')"
                                  v-model="client_reference"
                                  :class="{'is-invalid': form.errors.has('client_reference')}"
                                  trim
                    />
                    <has-error :form="form"
                               field="client_reference"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group id="input-group__type"
                              label-for="input__type">
                    <b-form-select id="input__type"
                                   :options="types"
                                   :placeholder="$t('type')"
                                   v-model="type"
                                   :class="{'is-invalid': form.errors.has('type')}"
                    />
                    <has-error :form="form"
                               field="type"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group id="input-group__query"
                              label-for="input__query">
                    <b-form-input id="input__query"
                                  type="text"
                                  :placeholder="$t('query')"
                                  v-model="query"
                                  :class="{'is-invalid': form.errors.has('query')}"
                                  trim
                    />
                    <has-error :form="form"
                               field="query"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" class="text-center">
                <b-btn type="submit" variant="primary">
                    Lookup
                </b-btn>
            </b-col>
        </b-form-row>
    </b-form>
</template>
