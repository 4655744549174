import store from '../store'

export default (to, from, next) => {
    let isTeamOwner = store.getters['auth/user'].id ===
        store.getters['auth/team'].owner_id

    if (!isTeamOwner) {
        next({ name: 'home' })
    } else {
        next()
    }
}
