import axios from 'axios'
import Form from 'vform'

export const state = {
    form: new Form({
        username: '',
        client_reference: '',
        type: null,
        duration: 1
    }),
    loading: false,
    privacyCheckers: [],
    types: [
        'instagram',
        'twitter'
    ]
}

export const getters = {
    loading: state => state.loading,
    form: state => state.form,
    privacyCheckers: state => state.privacyCheckers,
    hasPrivacyCheckers: state => !!state.privacyCheckers.length,
    types: state => state.types
}

export const mutations = {
    RESET_FORM (state) {
        state.form.username = ''
        state.form.client_reference = ''
        state.form.type = null
        state.form.duration = 1
    },
    SET_FORM_USERNAME (state, payload) {
        state.form.username = payload
    },
    SET_FORM_CLIENT_REFERENCE (state, payload) {
        state.form.client_reference = payload
    },
    SET_FORM_TYPE (state, payload) {
        state.form.type = payload
    },
    SET_FORM_DURATION (state, payload) {
        state.form.duration = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_PRIVACY_CHECKERS (state, payload) {
        state.privacyCheckers = payload
    }
}

export const actions = {
    async fetchPrivacyCheckers ({ commit }) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(`/api/privacy-checker`)

            commit('RESET_FORM')
            commit('SET_PRIVACY_CHECKERS', data.data)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async submitPrivacyChecker ({ dispatch, state }) {
        try {
            await state.form.post(`/api/privacy-checker`)
        } catch (e) {
            console.error(e)
        } finally {
            await dispatch('fetchPrivacyCheckers')
        }
    }

}
