<template>
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        <b-form-group id="input-group__firstname"
                                      label-for="input__firstname">
                            <b-form-input type="text"
                                          id="input__firstname"
                                          placeholder="Firstname"
                                          v-model="form.firstname"
                                          :state="firstnameHasError"
                            />
                            <b-form-invalid-feedback>
                                {{ firstnameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group id="input-group__middlenames"
                                      label-for="input__middlenames">
                            <b-form-input type="text"
                                          id="input__middlenames"
                                          placeholder="Middlenames"
                                          v-model="form.middlenames"
                                          :state="middlenamesHasError"
                            />
                            <b-form-invalid-feedback>
                                {{ middlenamesErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group id="input-group__lastname"
                                      label-for="input__lastname">
                            <b-form-input type="text"
                                          id="input__lastname"
                                          placeholder="Lastname"
                                          v-model="form.lastname"
                                          :state="lastnameHasError"
                            />
                            <b-form-invalid-feedback>
                                {{ lastnameErrorMessage }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-10">
                        <div class="row" v-for="(contact, key) in form.contacts" :key="key">
                            <div class="col mb-3">
                                <input-profile-finder-plus-contact :form="form"
                                                                   :input="contact"
                                                                   :key="key"
                                                                   :position="key"
                                />
                            </div>
                        </div>
                        <a href="#"
                           class="btn-new-entry p-2"
                           @click.prevent="$emit('pfp-add-contact')"
                           v-if="canAddContacts">
                            <font-awesome-icon icon="plus"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <b-form-group id="input-group__reference"
                              label-for="input__reference">
                    <b-form-input type="text"
                                  id="input__reference"
                                  placeholder="Reference"
                                  v-model="form.reference"
                                  :state="referenceHasError"
                    />
                    <b-form-invalid-feedback>
                        {{ referenceErrorMessage }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import InputProfileFinderPlusContact from './elements/InputProfileFinderPlusContact'

    export default {
        name: 'FormProfileFinderPlusContact',
        components: {
            InputProfileFinderPlusContact
        },
        computed: {
            firstnameErrorMessage () {
                return this.form.errors.get('firstname')
            },
            firstnameHasError () {
                return !this.form.errors.has('firstname') ? null : false
            },
            middlenamesErrorMessage () {
                return this.form.errors.get('middlenames')
            },
            middlenamesHasError () {
                return !this.form.errors.has('middlenames') ? null : false
            },
            lastnameErrorMessage () {
                return this.form.errors.get('lastname')
            },
            lastnameHasError () {
                return !this.form.errors.has('lastname') ? null : false
            },
            referenceErrorMessage () {
                return this.form.errors.get('reference')
            },
            referenceHasError () {
                return !this.form.errors.has('reference') ? null : false
            },
            numberOfContacts () {
                return this.form.contacts.length
            },
            canAddContacts () {
                return this.numberOfContacts < 3
            },
        },
        props: [
            'form'
        ]
    }
</script>

<style scoped lang="scss">
    @import '../../../sass/variables';

    .btn {
        &-new-entry {
            position: absolute;
            right: 10px;
            top: 0;
            color: $primary-color;
        }
    }
</style>
