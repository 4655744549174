import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import {Icon} from 'leaflet'
import VueCurrencyFilter from 'vue-currency-filter'
import Vue from 'vue'
import store from './store'
import router from './router'
import i18n from './plugins/i18n'
import App from './components/App'
import './components'
import './plugins'
import VuePlyr from 'vue-plyr'
import CountryFlag from 'vue-country-flag'
import {HasError, AlertError, AlertSuccess} from 'vform/src/components/bootstrap4'
import {TrackJS} from 'trackjs';
import {createPinia} from 'pinia'

TrackJS.install({
    token: "34dbcde7943a4048801abc2ca6041225",
    application: "toolsuite",
    forwardingDomain: 'errors.rpr.netwatchglobal.app'
});

/**
 * Vue Uses
 */
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCurrencyFilter, {
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false
})
Vue.use(VuePlyr)
Vue.use(CountryFlag)

const pinia = createPinia()
Vue.use(pinia)

// Components that are registered globally.
Vue.component('has-error', HasError)
Vue.component('alert-error', AlertError)
Vue.component('alert-success', AlertSuccess)

require('./utils/filters')

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
// import Echo from 'laravel-echo'
//
// window.Pusher = require('pusher-js');
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

new Vue({
    i18n,
    store,
    router,
    ...App
})
