import axios from 'axios'

export const state = {
    loading: false,
    tic: {},
}

export const getters = {
    tic: state => state.tic,
    loading: state => state.loading,
}

export const mutations = {
    SET_TIC (state, payload) {
        state.tic = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    }
}

export const actions = {
    async fetchTic ({ commit }, uuid) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(
                `/api/temporary-intelligence-capture/${uuid}`
            )

            commit('SET_TIC', data.data)
        } catch (e) {
            console.error(e)
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
