<script setup>
import { cn } from "@/utils/lib";

const props = defineProps({
  defaultValue: { type: [String, Number], required: false },
  value: { type: [String, Number], required: false },
  size: { type: String, required: false },
});

const emit = defineEmits(["input", "paste"]);

function handleInput(event) {
  emit("input", event.target.value);
}
</script>

<template>
  <input
    :value="value"
    @input="handleInput"
    @paste="emit('paste', $event)"
    type="text"
    :class="
      cn(
        'tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
        props.size === 'sm' && 'tw-h-9',
        $attrs.class ?? '',
      )
    "
  />
</template>
