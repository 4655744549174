import { DATALEAK_KEYS } from '@/const/dataleak-key';

const { USERNAME_MD5, ...TABLE_KEY } = DATALEAK_KEYS.USERNAMES;

export const USERNAMES_KEY = {
  USERNAME: 'username',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  SKYPE: 'skype',
  YAHOO: 'yahoo',
};

export function getUsernames(result) {
  const usernames = {};

  if (result.raw[TABLE_KEY.USERNAME]) {
      usernames[USERNAMES_KEY.USERNAME] = result.raw[TABLE_KEY.USERNAME];
  }

  if (result.raw[TABLE_KEY.INSTAGRAM_USERNAME]) {
      usernames[USERNAMES_KEY.INSTAGRAM] = result.raw[TABLE_KEY.INSTAGRAM_USERNAME];
  }

  if (result.raw[TABLE_KEY.TWITTER_USERNAME]) {
      usernames[USERNAMES_KEY.TWITTER] = result.raw[TABLE_KEY.TWITTER_USERNAME];
  }

  if (result.raw[TABLE_KEY.SKYPE]) {
      usernames[USERNAMES_KEY.SKYPE] = result.raw[TABLE_KEY.SKYPE];
  }

  if (result.raw[TABLE_KEY.YAHOO]) {
      usernames[USERNAMES_KEY.YAHOO] = result.raw[TABLE_KEY.YAHOO];
  }

  return usernames;
}
