<template>
    <label class="switch" :for="uuid">
        <input type="checkbox" class="default" :id="uuid" v-model="set">
        <span class="slider round"></span>
    </label>
</template>

<script>
    import uuid from 'uuid'

    export default {
        name: 'Toggle',
        computed: {
            set: {
                get() {
                    return this.active
                },
                set(value) {
                    this.$emit('set-active', value);
                }
            },
            uuid () {
                return uuid()
            }
        },
        data () {
            return {
                check: false,
            }
        },
        props: [
            'active'
        ]
    }
</script>

<style scoped lang="scss">
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 53px;
        height: 27px;
        float: right;
    }

    /* Hide default HTML checkbox */
    .switch input {
        display: none;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider.round {
        border-radius: 27px;

        &::before {
            border-radius: 50%;
        }
    }

    .slider::before {
        position: absolute;
        content: "";
        height: 19px;
        width: 19px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input.default:checked + .slider {
        background-color: #03AFFB;
    }

    input.primary:checked + .slider {
        background-color: #2196F3;
    }

    input.success:checked + .slider {
        background-color: #8bc34a;
    }

    input.info:checked + .slider {
        background-color: #3de0f5;
    }

    input.warning:checked + .slider {
        background-color: #FFC107;
    }

    input.danger:checked + .slider {
        background-color: #f44336;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
</style>
