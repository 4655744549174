import Form from 'vform'

export const state = {
    form: new Form({
        price: null,
        actions: null,
    })
}

export const getters = {
    form: state => state.form
}

export const mutations = {
    SET_FORM_PRICE (state, payload) {
        state.form.price = payload
    },
    SET_FORM_ACTIONS (state, payload) {
        state.form.actions = payload
    },
    RESET_FORM (state) {
        state.form.price = null
        state.form.actions = null
    }
}

export const actions = {
    async savePayAsYouGo ({ state }) {
        await state.form.post(`/api/kiosk/pay-as-you-gos`)
    }
}
