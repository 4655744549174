<template>
    <div class="card card-default shadow-custom mb-4">
        <div class="card-header">
            <router-link
                :to="{name: 'profile-finder-plus-update', params: {uuid: result.uuid}}">
                {{ title }}
            </router-link>
            <div class="float-right" :class="iconClass">
                <svg aria-hidden="true" data-prefix="fas" data-icon="check-circle"
                     class="icon-20" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512" v-if="isComplete && isSuccessful">
                    <path fill="currentColor"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path>
                </svg>
                <svg aria-hidden="true" data-prefix="far" data-icon="clock"
                     class="icon-20" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512" v-else-if="!isComplete">
                    <path fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                </svg>
                <svg aria-hidden="true" data-prefix="far" data-icon="times-circle"
                     class="icon-20" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512" v-else>
                    <path fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
                </svg>
            </div>
        </div>
        <div class="card-body">
            <p class="greyed-out font-weight-normal mb-2" v-for="search in searches">
                {{ search.email || search.phone }}
            </p>
            <p class="mb-0">Requested: <span>{{ result.created_at | localDatetime }}</span></p>
            <p>Completed: <span>{{ result.updated_at | localDatetime }}</span></p>
            <div v-if="hasAttributedResults" class="social-icons">
                <template v-for="(profile, key) in attributedResults">
                    <social-media-icon :profile="profile" :key="key"></social-media-icon>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import localDatetime from '../../utils/mixins/localDatetime'
    import SocialMediaIcons from './elements/SocialMediaIcons'
    import { concat, get } from 'lodash'

    export default {
        name: 'CardProfileFinderPlusSearch',

        components: {
            'social-media-icon': SocialMediaIcons
        },
        computed: {
            isComplete () {
                return this.result.status === 'complete'
            },
            isSuccessful () {
                return !!get(this.result, 'search_results.profiles', []).length ||
                    !!get(this.result, 'search_results.additional', []).length
            },
            iconClass () {
                if (!this.isComplete) {
                    return 'text-warning'
                }

                if (!this.isSuccessful) {
                    return 'text-danger'
                }

                if (this.isComplete) {
                    return 'text-success'
                }
            },
            getReference () {
                if (this.result.search.phones.length) {
                    return this.result.search.phones[0].reference
                } else {
                    return this.result.search.emails[0].reference
                }
            },
            title () {
                return this.result.client_reference
            },
            searches () {
                return concat(this.result.search.emails, this.result.search.phones)
            },
            attributedResults () {
                return get(this.result, 'search_results.profiles', []).filter(profile => profile.attributed)
            },
            unattributedResults () {
                return this.result.search_results.profiles.filter(profile => profile.unattributed)
            },
            hasAttributedResults () {
                return !!this.attributedResults.length
            },
            hasUnattributedResults () {
                return !!this.unattributedResults.length
            },
        },
        props: [
            'result',
        ],
        mixins: [
            localDatetime,
        ]
    }
</script>

<style scoped lang="scss">
    @import '../../../sass/variables';

    .card {
        font-weight: bold;

        &-header {
            color: $secondary-color;
            background-color: transparent;
            border-bottom: 2px solid $primary-color;

            a {
                text-decoration: none;
                color: $secondary-color;
            }
        }

        &-body {
            span {
                font-weight: 400;
            }
        }
    }

    .greyed-out {
        color: $text-gray;
    }
</style>
