<!-- https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/ -->
<script setup>
function enter(element) {
  const width = getComputedStyle(element).width;

  element.style.width = width;
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';

  // add padding 
  const height = getComputedStyle(element).height

  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height;
  });
}

function afterEnter(element) {
  element.style.height = 'auto';
}

function leave(element) {
  element.style.height = getComputedStyle(element).height;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  });
}
</script>

<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    class="tw-w-full"
  >
    <slot/>
  </transition>
</template>

<style scoped>
.expand {
  opacity: 1;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
  opacity: 1;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}

* {
  will-change: height, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
