<script setup>
import { defineProps, computed } from 'vue'
import { sourceToLabel } from '@/utils/lib'
import Badge from '@/components/pfp/ui/badge/Badge.vue'

const SOURCES = ['nwData', 'nwOSINT', 'nwGumtree', 'pipl', 'leakedDatasets', 'leakedDatasetsOld']

const props = defineProps({
  // ['nwData', 'nwOSINT', 'nwGumtree', 'pipl', 'leakedDatasets']
  data: {
    type: Array,
    default: () => [],
  },
})

const validatedFormattedData = computed(() => {
  return props.data
    .map((item) => {
      return {
        variant: SOURCES.includes(item) ? item : 'default',
        text: sourceToLabel(item),
      }
    })
})
</script>

<template>
  <div class="tw-flex tw-flex-wrap tw-gap-2">
    <Badge v-for="item in validatedFormattedData" :key="item.variant" :variant="item.variant" class="">{{ item.text }}</Badge>
  </div>
</template>