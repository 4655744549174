<template>
    <b-row no-gutters>
        <b-col class="mt-2" cols="12">
            <b-tabs>
                <b-tab>
                    <template #title>
                        <span><font-awesome-icon icon="coins" fixed-width/>Actions</span>
                    </template>
                    <b-row class="pt-4">
                        <b-col cols="6">
                            <label for="input__date-from">Date From</label>
                            <b-form-datepicker id="input__date-from"
                                               v-model="filters.date_from"
                                               class="transactions__date-picker">
                            </b-form-datepicker>
                        </b-col>
                        <b-col cols="6">
                            <label for="input__date-from">Date To</label>
                            <b-form-datepicker id="input__date-to"
                                               v-model="filters.date_to"
                                               class="transactions__date-picker">
                            </b-form-datepicker>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <b>Auto-Select:</b>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('1D')">1D</a>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('7D')">7D</a>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('3M')">3M</a>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('6M')">6M</a>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('1Y')">1Y</a>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('MTD')">MTD</a>
                        </b-col>
                        <b-col>
                            <a href="#" @click.prevent="setDateFilter('YTD')">YTD</a>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-tabs>
                        <b-tab>
                            <template v-slot:title>
                                <span><b-icon-table style="margin-right: 10px;"></b-icon-table>Table</span>
                            </template>
                            <b-table id="actions-table"
                                     :fields="fields"
                                     :items="actions"
                                     :busy="loading"
                                     :responsive="true">
                                <template v-slot:table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                                <template #cell(team)="data">
                                    {{ data.value.full_team_name }}
                                </template>
                                <template #cell(tool)="data">
                                    <router-link :to="{name: data.item.route.name}" v-if="data.item.route">
                                        {{ data.item.actionable_type.name }}
                                    </router-link>
                                </template>
                                <template #cell(user)="data">
                                    {{ data.value.email }}
                                </template>
                                <template #cell(client_reference)="data">
                                    {{ data.item.actionable ? data.item.actionable.client_reference : '' }}
                                </template>
                                <template v-slot:cell(created_at)="data">
                                    {{ data.value | datetime }}
                                </template>
                            </b-table>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title>
                                <span><b-icon-bar-chart style="margin-right: 10px;"></b-icon-bar-chart> Chart</span>
                            </template>
                            <div class="text-center">
                                <h3 class="text-center my-4">Coming Soon</h3>
                                <p>This feature is currently in testing, and is currently scheduled for release towards
                                    the end of 2021 Q3/beginning of 2021 Q4.</p>
                                <p>For more information, please contact your Account Manager or appointed Customer
                                    Service Representative.</p>
                            </div>
                        </b-tab>
                    </b-tabs>
                    <b-pagination v-model="page"
                                  :total-rows="meta.total"
                                  :per-page="meta.per_page"
                                  aria-controls="transactions-table">
                    </b-pagination>
                </b-tab>
                <b-tab v-if="isTeamOwner">
                    <template #title>
                        <span><font-awesome-icon icon="cash-register" fixed-width/>{{ $t('pay_as_you_go') }}</span>
                    </template>
                    <pay-as-you-gos/>
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
    import { mapGetters } from 'vuex'
    import localDatetime from '../../utils/mixins/localDatetime'
    import TransactionsChart from '../../components/charts/TransactionsChart'
    import { toLower, uniq, get } from 'lodash'
    import moment from 'moment'
    import qs from 'qs'
    import axios from 'axios'
    import PayAsYouGos from '../../components/PayAsYouGos'

    export default {
        name: 'HomeDashboard',
        components: {
            PayAsYouGos,
            TransactionsChart
        },
        metaInfo () {
            return { title: this.$t('dashboard') }
        },
        async created () {
            await this.setDateFilter('1D')
            await this.fetchActions()
        },
        computed: {
            ...mapGetters({
                team: 'auth/team',
                user: 'auth/user',
                isTeamOwner: 'auth/isTeamOwner'
            }),
            chartData () {
                const tools = uniq(this.items.map(value => value.tool_name), 'tool_name')
                let data = []
                tools.forEach(tool => {
                    let dataset = {
                        label: tool,
                        data: this.items.filter(item => item.tool_name === tool).map(item => item.amount)
                    }

                    data.push(dataset)
                })

                return {
                    // labels: tools,
                    datasets: data
                }
            },
            chartOptions () {
                const timeFormat = 'dd/MM/YY'
                return {
                    scales: {
                        xAxes: [
                            {
                                type: 'time',
                                parser: timeFormat,
                                tooltipFormat: 'll HH:mm'
                            }
                        ],
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: 'amount'
                                }
                            }
                        ]
                    }
                }
            },
            actions () {
                return this.items.map(action => {
                    let kebabCaseTool = `${toLower(action.actionable_type.name.split(' ').join('-'))}`

                    return {
                        ...action,
                        route: action.actionable ? {
                            name: kebabCaseTool,
                            params: {
                                id: get(action, 'actionable.uuid', action.actionable.id)
                            }
                        } : null
                    }
                })
            }
        },
        data () {
            return {
                fields: [
                    'team',
                    'tool',
                    'user',
                    'client_reference',
                    'created_at'
                ],
                filters: {
                    date_from: null,
                    date_to: null,
                },
                items: [],
                loading: false,
                meta: {
                    current_page: null,
                    from: null,
                    last_page: null,
                    path: '',
                    per_page: null,
                    to: null,
                    total: null,
                },
                page: 1,
            }
        },
        methods: {
            async fetchActions () {
                this.loading = true

                const { data } = (await axios.get(`/api/actions`, {
                    params: {
                        page: this.page,
                        filter: this.filters
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: 'brackets', encode: false })
                    }
                }))

                this.items = data.data
                this.meta = data.meta
                this.loading = false
            },
            async setDateFilters (dateFrom, dateTo) {
                this.filters.date_from = dateFrom
                this.filters.date_to = dateTo

                if (this.page === 1) {
                    await this.fetchActions()
                } else {
                    this.page = 1
                }
            },
            async setDateFilter (value) {
                switch (value) {
                    case '1D':
                        await this.setDateFilters(
                            moment().utc().subtract(1, 'days').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                    case '7D':
                        await this.setDateFilters(
                            moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                    case '3M':
                        await this.setDateFilters(
                            moment().utc().subtract(3, 'months').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                    case '6M':
                        await this.setDateFilters(
                            moment().utc().subtract(6, 'months').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                    case '1Y':
                        await this.setDateFilters(
                            moment().utc().subtract(1, 'years').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                    case 'MTD':
                        await this.setDateFilters(
                            moment().utc().startOf('month').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                    case 'YTD':
                        await this.setDateFilters(
                            moment().utc().startOf('year').format('YYYY-MM-DD'),
                            moment().utc().format('YYYY-MM-DD')
                        )

                        break
                }
            },
        },
        mixins: [
            localDatetime
        ],
        watch: {
            async page () {
                await this.fetchActions()
            }
        }
    }
</script>

<style lang="scss">
.transactions__date-picker {
    & > label {
        margin: 0 !important;
    }
}
</style>
