import axios from 'axios'
import Cookies from 'js-cookie'
import { get, has } from 'lodash'
import { TrackJS } from 'trackjs'

// state
export const state = {
    user: null,
    team: null,
    token: Cookies.get('token')
}

// getters
export const getters = {
    user: state => state.user,
    team: state => state.team,
    token: state => state.token,
    check: state => state.user !== null,
    current_team_id: state => get(state.user, 'current_team_id', null),
    isTeamOwner: state => {
        if (has(state.user, 'id') && has(state.team, 'owner_id')) {
            return state.user.id === state.team.owner_id
        }

        return false
    },
    teamTools: state => {
        return get(state, 'team.tools') ? Object.values(state.team.tools) : []
    },
    teams: state => state.user.teams,
    hasTeams: state => !!state.user.teams.length,
    userId: state => state.user.id,
    onTeam: state => get(state.team, 'id', null) !== null,
}

// mutations
export const mutations = {
    SAVE_TOKEN (state, { token, remember }) {
        state.token = token
        Cookies.set('token', token, { expires: remember ? 365 : null })
    },

    FETCH_TEAM_SUCCESS (state, { team }) {
        state.team = team

        TrackJS.addMetadata('team', team.full_team_name)
        TrackJS.addMetadata('teamId', team.id)
    },

    FETCH_TEAM_FAILURE (state) {
        state.token = null
        Cookies.remove('token')
    },

    FETCH_USER_SUCCESS (state, { user }) {
        state.user = user
        state.team = user.team

        TrackJS.configure({ userId: user.email })
    },

    FETCH_USER_FAILURE (state) {
        state.token = null
        Cookies.remove('token')
    },

    LOGOUT (state) {
        state.user = null
        state.team = null
        state.token = null

        Cookies.remove('token')
    },

    UPDATE_USER (state, { user }) {
        state.user = user
    },

    UPDATE_TEAM (state, { team }) {
        state.team = team
    }
}

// actions
export const actions = {
    saveToken ({ commit, dispatch }, payload) {
        commit('SAVE_TOKEN', payload)
    },

    async fetchUser ({ commit, dispatch }) {
        try {
            const { data } = await axios.get('/api/user')

            commit('FETCH_USER_SUCCESS', { user: data })

            await dispatch('fetchTeam', data.current_team_id)
        } catch (e) {
            commit('FETCH_USER_FAILURE')
        }

    },

    async fetchTeam ({ commit }, payload) {
        if (!payload) {
            return null
        }

        try {
            const { data } = await axios.get(`/api/teams/${payload}`)

            commit('FETCH_TEAM_SUCCESS', { team: data })
        } catch (e) {
            commit('FETCH_TEAM_FAILURE')
        }
    },

    async reloadTeam ({ commit, state }) {
        try {
            const { data } = await axios.get(`/api/teams/${state.team.id}`)

            commit('FETCH_TEAM_SUCCESS', { team: data })
        } catch (e) {
            commit('FETCH_TEAM_FAILURE')
        }
    },

    updateUser ({ commit }, payload) {
        commit('UPDATE_USER', payload)
    },

    updateTeam ({ commit }, payload) {
        commit('UPDATE_TEAM', payload)
    },

    async logout ({ commit }) {
        try {
            await axios.post('/api/logout')
        } catch (e) {
            console.error(e)
        }

        commit('LOGOUT')
    },

    async fetchOauthUrl (ctx, { provider }) {
        const { data } = await axios.post(`/api/oauth/${provider}`)

        return data.url
    }
}
