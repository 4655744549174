import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.MISC;

export const MISC_KEY = {
    ...TABLE_KEY,
}

export function getMisc(result) {
    const misc = {};

    for (const [, value] of Object.entries(MISC_KEY)) {
        if (result.raw[value]) {
            misc[value] = result.raw[value];
        }
    }

    return misc;
}
