<template>
    <ul class="timeline">
        <timeline-story v-for="(story, key) in stories" :story="story" :key="key"></timeline-story>
    </ul>
</template>

<script>
    import TimelineStory from '../elements/TimelineStory'

    export default {
        name: 'TimelineStories',
        components: {
            TimelineStory
        },
        props: [
            'stories',
        ]
    }
</script>

<style scoped lang="scss">
    .timeline {
        list-style-type: none;
        position: relative;

        &::before {
            content: ' ';
            background: #d4d9df;
            display: inline-block;
            position: absolute;
            left: 0px;
            top: 8px;
            margin-top: 2px;
            width: 2px;
            height: calc(100% - 50px);
            z-index: 400;
        }
    }
</style>
