import axios from 'axios'

export const state = {
    loading: false,
    tools: []
}

export const getters = {
    loading: state => state.loading,
    tools: state => state.tools,
    hasTools: state => !!state.tools.length
}

export const mutations = {
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_TOOLS (state, payload) {
        state.tools = payload
    }
}

export const actions = {
    async fetchTools ({ commit }) {
        commit('SET_LOADING', true)

        const { data } = await axios.get(`/api/tools`)

        commit('SET_TOOLS', data.data)
        commit('SET_LOADING', false)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
