import { DATALEAK_KEYS } from '@/const/dataleak-key';

const TABLE_KEY = DATALEAK_KEYS.IDENTIFIERS;

export const ID_KEY = {
  ...TABLE_KEY,
};

export function getIdentifiers(result) {
  const identifiers = {};

  for (const [, value] of Object.entries(ID_KEY)) {
    if (result.raw[value]) {
      identifiers[value] = result.raw[value];
    }
  }

  return identifiers;
}
