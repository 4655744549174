var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{class:_setup.cn(
      'tw-block tw-text-sm tw-leading-3 tw-font-medium tw-text-foreground tw-text-left',
      _vm.$attrs.class
      ),attrs:{"for":_setup.props.for},on:{"mousedown":(event) => {
      // prevent text selection when double clicking label
      if (!event.defaultPrevented && event.detail > 1) event.preventDefault();
  }}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }