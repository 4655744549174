<script setup>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import Card from '@/components/pfp/ui/card/Card.vue';
import Label from '@/components/pfp/ui/label/Label.vue';
import Input from '@/components/pfp/ui/input/Input.vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import Separator from '@/components/pfp/ui/separator/Separator.vue';
import DatePicker from '@/components/pfp/date-picker/DatePicker.vue';
import useVWStore from '@/pinia/vw';

const store = useVWStore();

const reference = ref('');
const registration = ref('');
const date = ref('');

const referenceError = ref('');
const registrationError = ref('');
const dateError = ref('');
const generalError = ref('');

const dateKey = ref(0);

const hasErrors = computed(() => {
    return (
        referenceError.value ||
        registrationError.value ||
        dateError.value ||
        generalError.value
    );
});

function handleSearch() {
    const hasBoth = registration.value && date.value;
    const hasNeither = !registration.value && !date.value;

    if (!reference.value) {
        referenceError.value = 'Please enter a reference.';
    } else {
        referenceError.value = '';
    }

    if (hasBoth) {
        generalError.value =
            'You must enter either a registration or a date, not both.';
    }

    if (hasNeither) {
        generalError.value = 'You must enter either a registration or a date.';
    }

    if (hasErrors.value) {
        return;
    }

    store.searchForMaterials({
        reference: reference.value,
        registration: registration.value,
        date: date.value,
    });

    reference.value = '';
    registration.value = '';
    date.value = '';
    dateKey.value += 1;

    referenceError.value = '';
    registrationError.value = '';
    dateError.value = '';
    generalError.value = '';
}

function handleInput(type, value) {
    switch (type) {
        case 'ref':
            reference.value = value;
            break;
        case 'reg':
            registration.value = value;
            break;
        case 'date':
            if (!value) {
                date.value = '';
                break;
            }

            date.value = format(new Date(value), 'yyyy-MM-dd');
            break;
    }
}
</script>

<template>
    <Card
        class="tw-w-[360px] tw-my-8 tw-relative tw-flex tw-flex-col tw-border-none tw-group tw-p-2"
        style="padding: 0"
    >
        <div
            class="tw-absolute -tw-inset-2 tw-z-0 tw-bg-gradient-to-r tw-from-sky-500 tw-via-cyan-300 tw-to-sky-500 dark:tw-from-sky-400 dark:tw-via-cyan-200 dark:tw-to-sky-400 tw-rounded-lg tw-blur-lg tw-transition-opacity tw-ease-in-out tw-duration-500 tw-opacity-15 group-focus-within:dark:tw-opacity-35 group-focus-within:tw-opacity-25"
        />

        <div
            class="tw-bg-card tw-z-10 tw-p-6 tw-rounded-lg tw-border tw-border-border tw-flex tw-flex-col tw-gap-y-3"
        >
            <div key="ref">
                <Label for="reference">Reference</Label>
                <Input
                    id="reference"
                    size="sm"
                    class="tw-basis-full"
                    placeholder="ID-12345"
                    autocomplete="off"
                    :value="reference"
                    @input="(str) => handleInput('ref', str)"
                />
                <p
                    v-if="referenceError"
                    class="tw-text-xs tw-text-destructive mt-1"
                >
                    {{ referenceError }}
                </p>
            </div>

            <div
                class="tw-p-5 tw-border tw-border-muted tw-bg-muted tw-rounded-md tw-flex tw-flex-col tw-space-y-3"
            >
                <div key="reg">
                    <Label for="registration">Registration</Label>
                    <Input
                        id="registration"
                        size="sm"
                        class="tw-basis-full"
                        placeholder="AB12 CDE"
                        autocomplete="off"
                        :value="registration"
                        :disabled="false"
                        @input="(str) => handleInput('reg', str)"
                    />
                    <p
                        v-if="registrationError"
                        class="tw-text-xs tw-text-destructive mt-1"
                    >
                        {{ registrationError }}
                    </p>
                </div>

                <div
                    class="tw-relative tw-flex tw-items-center tw-h-6"
                    key="or-separator"
                >
                    <span
                        class="tw-bg-muted tw-text-muted-foreground tw-text-sm tw-absolute tw-left-1/2 tw--translate-x-1/2 px-2"
                    >
                        OR
                    </span>
                    <Separator class="tw-w-full" />
                </div>

                <div key="date">
                    <Label for="date">Date</Label>
                    <DatePicker
                        :key="dateKey"
                        :range="false"
                        @selected="(str) => handleInput('date', str)"
                        placeholder="Select a date"
                    />
                    <p
                        v-if="dateError"
                        class="tw-text-xs tw-text-destructive mt-1"
                    >
                        {{ dateError }}
                    </p>
                </div>
            </div>

            <p v-if="generalError" class="tw-text-xs tw-text-destructive">
                {{ generalError }}
            </p>

            <Button class="tw-w-full" @click="handleSearch">
                Search for materials
            </Button>
        </div>
    </Card>
</template>
