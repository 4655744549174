<template>
    <div class="card card-default my-4">
        <div class="card-header d-flex justify-content-between">
            <template v-for="name in person.names">{{ name.display }}</template>
            <template v-if="person.dob">({{ personAge }}),</template>
            <template v-if="person.gender">{{person.gender.content}}</template>
            <span class="badge badge-secondary"> {{ resultPosition }} / {{ resultsCount }} results</span>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-6">
                        <div class="card card-default" v-if="person.images">
                            <div class="card-header">
                                Images
                                <small>Note: sometimes images may be blocked from loading!</small>
                            </div>
                            <div class="card-body result-image">
                                <div class="card" v-for="image in person.images">
                                    <img class="card-img-top" :src="image.url" alt="Profile Picture">
                                </div>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.addresses">
                            <div class="card-header">
                                Addresses
                            </div>
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li v-for="address in person.addresses" class="list-group-item">
                                        {{ address.display }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.educations">
                            <div class="card-header">
                                Education
                            </div>
                            <div class="card-body">
                                <p v-for="education in person.educations">{{ education.display }}</p>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.jobs">
                            <div class="card-header">
                                Jobs
                            </div>
                            <div class="card-body">
                                <p v-for="job in person.jobs">{{ job.display }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card card-default" v-if="person.phones">
                            <div class="card-header">
                                Phones
                            </div>
                            <div class="card-body">
                                <p v-for="phone in person.phones">{{ phone.display }}</p>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.languages">
                            <div class="card-header">
                                Languages
                            </div>
                            <div class="card-body">
                                <p v-for="language in person.languages">{{ language.display }}</p>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.relationships">
                            <div class="card-header">
                                Relationships
                            </div>
                            <div class="card-body">
                                <p v-for="relationship in person.relationships">
                                    {{ relationship.type }}
                                    <template v-for="name in relationship.names">{{ name.display }},</template>
                                </p>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.urls">
                            <div class="card-header">
                                URLs
                            </div>
                            <div class="card-body">
                                <p v-for="url in person.urls">
                                    <a :href="url.url" target="_blank">{{ url.url }}</a>
                                </p>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.user_ids">
                            <div class="card-header">
                                User IDs
                            </div>
                            <div class="card-body">
                                <p v-for="user_id in person.user_ids">{{ user_id.content }}</p>
                            </div>
                        </div>
                        <div class="card card-default" v-if="person.usernames">
                            <div class="card-header">
                                Usernames
                            </div>
                            <div class="card-body">
                                <p v-for="username in person.usernames">{{ username.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PossiblePerson',
        props: [
            'person',
            'resultsCount',
            'resultPosition'
        ],
        computed: {
            personAge () {
                let age = this.person.dob.display
                let ageSplit = age.split(' ', 5)

                return ageSplit[0]
            },
        },
    }
</script>

<style scoped lang="scss">
    @import '../../../sass/variables';

    .profile-finder-results {
        .card {
            &-header {
                color: $white;
                background: $primary-color;

                .badge {
                    &-secondary {
                        color: $white;
                        background-color: transparent;
                    }
                }
            }

            &-body {
                .container-fluid {
                    .card-header {
                        background: $secondary-color;
                    }
                }
            }
        }
    }

    .result-image {
        display: flex;
        justify-content: flex-start;

        .card {
            width: 100px;
            margin-right: 25px;
        }
    }
</style>
