<script>
import {defineComponent} from 'vue'
import {mapGetters} from 'vuex';

export default defineComponent({
    name: 'OsintIndustriesLookupIndex',
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.$store.dispatch('osintIndustriesLookup/fetchOsintIndustriesLookups')
        })
    },
    computed: {
        ...mapGetters({
            osintIndustriesLookups: 'osintIndustriesLookup/osintIndustriesLookups',
            loading: 'osintIndustriesLookup/loading',
        })
    },
    data: () => ({
        fields: [
            'uuid',
            'client_reference',
            'type',
            'query',
            'options'
        ]
    })
})
</script>

<template>
    <b-card class="default" header-class="d-flex flex-row justify-content-between align-items-center">
        <template #header>
            <p class="m3 m-0">
                Previous Searches
            </p>
            <b-button variant="success" :to="{name: 'osint-industries-lookup.lookup'}">
                Search
            </b-button>
        </template>
        <b-table :items="osintIndustriesLookups" :fields="fields" :busy="loading">
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(client_reference)="data">
                <router-link :to="{name: 'osint-industries-lookup.view', params: {uuid: data.item.uuid}}">
                    {{ data.value }}
                </router-link>
            </template>
            <template #cell(options)="data">

            </template>
        </b-table>
    </b-card>
</template>

<style scoped lang="scss">

</style>
