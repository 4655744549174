<script setup>
import { computed } from 'vue';
import { enGB } from 'date-fns/locale';
import { format, formatRelative } from 'date-fns';
import { CheckCircle2Icon, XCircleIcon } from 'lucide-vue';
import _store from '@/store';
import Tooltip from '@/components/pfp/ui/tooltip/Tooltip.vue';

const props = defineProps(['result']);
const emit = defineEmits(['click']);

const type = computed(() => {
    if (props.result.search.registration) {
        return 'Registration';
    }

    return 'Date';
});

const query = computed(() => {
    if (props.result.search.registration) {
        return props.result.search.registration;
    }

    return props.result.search.date;
});

const isTeamOwner = computed(() => {
    return _store.getters['auth/isTeamOwner'];
});

const locale = {
    ...enGB,
    formatRelative: (token) => {
        return {
            lastWeek: "'Last' eeee 'at' p",
            yesterday: "'Yesterday at' p",
            today: "'Today at' p",
            tomorrow: "'Tomorrow at' p",
            nextWeek: "eeee 'at' p",
            other: 'P p',
        }[token];
    },
};
</script>

<template>
    <div
        v-if="result"
        :data-result="result.uuid"
        @click="emit('click')"
        class="tw-w-full tw-rounded-xl tw-border tw-border-border tw-p-4 md:tw-border-none md:tw-p-0 tw-cursor-pointer tw-relative"
        :class="{
            'tw-cursor-not-allowed': result.results_count === 0,
        }"
    >
        <p
            v-if="isTeamOwner"
            class="tw-text-xs tw-text-muted-foreground tw-absolute tw-bottom-1 tw-left-3 tw-z-10 tw-hidden md:tw-block"
        >
            Searched by
            <span>{{ result.user.name }}</span> ({{ result.team.name }})
        </p>

        <!-- TABLET ABOVE -->
        <div
            class="tw-grid-cols-[5fr_7fr_5fr_2fr_3fr] tw-transition-colors hover:tw-bg-muted tw-px-3 tw-py-4 tw-rounded-xl tw-items-center tw-justify-center tw-hidden md:tw-grid tw-relative"
        >
            <div class="tw-text-sm tw-pr-1">{{ result.client_reference }}</div>

            <div class="tw-text-sm">{{ query }}</div>

            <div class="tw-text-sm">{{ type }}</div>

            <div
                class="tw-flex tw-justify-center"
                :class="{
                    'tw-text-green-500': result.results_count > 0,
                    'tw-text-red-500': result.results_count === 0,
                }"
            >
                <component
                    :is="
                        result.results_count > 0
                            ? CheckCircle2Icon
                            : XCircleIcon
                    "
                />
            </div>

            <p class="tw-text-sm tw-text-center">
                <Tooltip hover :hover-intent="400" class="tw-block tw-w-full">
                    <template #trigger class="w-full">
                        <span>{{
                            format(new Date(result.created_at), 'dd/MM/yy')
                        }}</span>
                    </template>
                    <template #content>
                        {{
                            formatRelative(
                                new Date(result.created_at),
                                new Date(),
                                {
                                    locale,
                                },
                            )
                        }}
                    </template>
                </Tooltip>
                <span class="tw-text-sm tw-block tw-text-muted-foreground">
                    {{ format(new Date(result.created_at), 'p') }}
                </span>
            </p>
        </div>

        <!-- MOBILE CARDS -->
        <div
            class="md:tw-hidden tw-flex tw-justify-between tw-gap-y-6 tw-px-2 tw-py-4 tw-w-full tw-relative"
        >
            <div class="tw-flex tw-flex-col tw-space-y-8">
                <div>
                    <Label>Reference</Label>
                    <div class="tw-text-sm tw-text-muted-foreground">
                        {{ result.client_reference }}
                    </div>
                </div>

                <div>
                    <Label>Query</Label>
                    <div class="tw-text-sm tw-text-muted-foreground">
                        {{ query }}
                    </div>
                </div>

                <div>
                    <Label>Created</Label>
                    <p>
                        <Tooltip
                            hover
                            :hover-intent="400"
                            class="tw-block tw-w-full"
                        >
                            <template #trigger class="w-full">
                                <span>{{
                                    format(
                                        new Date(result.created_at),
                                        'dd/MM/yyyy',
                                    )
                                }}</span>
                            </template>
                            <template #content>
                                {{
                                    formatRelative(
                                        new Date(result.created_at),
                                        new Date(),
                                        {
                                            locale,
                                        },
                                    )
                                }}
                            </template>
                        </Tooltip>
                        <span
                            class="tw-text-sm tw-block tw-text-muted-foreground"
                            >{{
                                format(new Date(result.created_at), 'p')
                            }}</span
                        >
                    </p>
                </div>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-8 tw-mb-2">
                <div>
                    <Label>Materials</Label>
                    <div
                        :class="{
                            'tw-text-green-500': result.results_count > 0,
                            'tw-text-red-500': result.results_count === 0,
                        }"
                    >
                        <component
                            :is="
                                result.results_count > 0
                                    ? CheckCircle2Icon
                                    : XCircleIcon
                            "
                        />
                    </div>
                </div>

                <div>
                    <Label>Type</Label>
                    <div class="tw-text-sm tw-text-muted-foreground">
                        {{ type }}
                    </div>
                </div>

                <div>
                    <Label>Searched by</Label>
                    <div class="tw-text-sm tw-text-muted-foreground">
                        <span>{{ result.user.name }}</span> ({{
                            result.team.name
                        }})
                    </div>
                </div>
            </div>

            <p
                class="tw-absolute -tw-bottom-2 tw-w-full tw-text-center tw-text-xs tw-text-muted-foreground"
            >
                Click card to view results
            </p>
        </div>
    </div>
</template>
