import Form from 'vform'
import { get } from 'lodash'
import axios from 'axios'

export const state = {
    form: new Form({
        client_reference: '',
        id: ''
    }),
    loading: false,
    message: null,
    result: null,
    results: []
}

export const getters = {
    form: state => state.form,
    loading: state => state.loading,
    hasMessage: state => state.message !== null,
    message: state => state.message,
    result: state => state.result,
    hasResult: state => state.result !== null,
    results: state => state.results,
    hasResults: state => !!state.results.length
}

export const mutations = {
    SET_FORM_CLIENT_REFERENCE (state, payload) {
        state.form.client_reference = payload
    },
    SET_FORM_ID (state, payload) {
        state.form.id = payload
    },
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_MESSAGE (state, value) {
        state.message = value
    },
    SET_RESULT (state, payload) {
        state.result = payload
    },
    SET_RESULTS (state, payload) {
        state.results = payload
    }
}

export const actions = {
    async search ({ commit, dispatch, state }) {
        commit('SET_LOADING', false)
        commit('SET_MESSAGE', null)

        try {
            const { data } = await state.form.post(`/api/facebook-id-lookup`)

            commit('SET_RESULT', data)

            if (!data.results.length) {
                commit('SET_MESSAGE', 'No matching profiles found')
            }

            state.form.reset()
        } catch (e) {
            console.error(e.response)

            commit('SET_MESSAGE', get(e.response, 'data.message', null))
        } finally {
            commit('SET_LOADING', false)

            await dispatch('fetchResults')
        }
    },
    async fetch ({ commit }, id) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(`/api/facebook-id-lookup/${id}`)

            commit('SET_RESULT', data.data)
        } catch (e) {
            console.error(e.response)

            commit('SET_MESSAGE', get(e.response, 'data.message', null))
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async fetchResults ({ commit }) {
        commit('SET_LOADING', true)

        try {
            const { data } = await axios.get(`/api/facebook-id-lookup`)

            commit('SET_RESULTS', data.data)
        } catch (e) {
            console.error(e.response)

            commit('SET_MESSAGE', get(e.response, 'data.message', null))
        } finally {
            commit('SET_LOADING', false)
        }
    }
}
