import { DATALEAK_KEYS } from '@/const/dataleak-key';

const { HOME_OWNER, ...TABLE_KEY} = DATALEAK_KEYS.HOUSEHOLD_INFORMATION;

export const HI_KEY = {
    ...TABLE_KEY,
};

export function getHouseholdInformation(result) {
  
  const householdInfo = {};

  if (result.raw[TABLE_KEY.DWELLING_AGE]) {
      householdInfo[HI_KEY.DWELLING_AGE] = result.raw[TABLE_KEY.DWELLING_AGE];
  }

  if (result.raw[TABLE_KEY.HOME_OWNER] || result.raw[TABLE_KEY.HOME_OWNERSHIP]) {
      householdInfo[HI_KEY.HOME_OWNER] = result.raw[TABLE_KEY.HOME_OWNER] || result.raw[TABLE_KEY.HOME_OWNERSHIP];
  }

  if (result.raw[TABLE_KEY.HOUSEHOLD_INCOME]) {
      householdInfo[HI_KEY.HOUSEHOLD_INCOME] = result.raw[TABLE_KEY.HOUSEHOLD_INCOME];
  }

  if (result.raw[TABLE_KEY.HOUSEHOLD_MARITAL_STATUS]) {
      householdInfo[HI_KEY.HOUSEHOLD_MARITAL_STATUS] = result.raw[TABLE_KEY.HOUSEHOLD_MARITAL_STATUS];
  }

  if (result.raw[TABLE_KEY.HOUSEHOLD_SIZE]) {
      householdInfo[HI_KEY.HOUSEHOLD_SIZE] = result.raw[TABLE_KEY.HOUSEHOLD_SIZE];
  }

  if (result.raw[TABLE_KEY.NUM_ADULTS]) {
      householdInfo[HI_KEY.NUM_ADULTS] = result.raw[TABLE_KEY.NUM_ADULTS];
  }

  if (result.raw[TABLE_KEY.NUM_KIDS]) {
      householdInfo[HI_KEY.NUM_KIDS] = result.raw[TABLE_KEY.NUM_KIDS];
  }

  return householdInfo;
}
