import Form from 'vform'

export const state = {
    profile: {
        photo: new Form({
            image: null,
        }),
    },
    membership: {
        sendInvitation: new Form({
            email: '',
        }),
        teamMembers: []
    },
}

export const getters = {
    teamMembers: state => state.membership.teamMembers
}
