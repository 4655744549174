import { ID_KEY } from "@/utils/pfp2/dataleaks/getIdentifiers";

export function generateProfileURLs(data) {
  const profileURLs = {};

  for (const [, id] of Object.entries(ID_KEY)) {
    if (!data[id] || data[id] === "" || data[id] === "0" || data[id] === "null" || data[id] === "undefined") {
      continue;
    }

    switch (id) {
      case ID_KEY.ACNE_ID:
        // nothing?
        break;
      case ID_KEY.ANET_CUSTOMER_ID:
        // is this arena net or anet?
        break;
      case ID_KEY.APPLE_ID:
        // nothing?
        break;
      case ID_KEY.BHINNEKA_ID:
        // nothing?
        break;
      case ID_KEY.BLACKHAT_USER_ID:
        // is this blackhatworld or something else?
        profileURLs[ID_KEY.BLACKHAT_USER_ID] = `https://www.blackhatworld.com/members/${data[ID_KEY.BLACKHAT_USER_ID]}/`;
        break;
      case ID_KEY.BRAINTREE_ID:
        // nothing?
        break;
      case ID_KEY.CANNABIS_FORUM_USER_ID:
        // nothing?
        break;
      case ID_KEY.CANVA_USER_ID:
        // nothing?
        break;
      case ID_KEY.CHATBOOKS_USER_ID:
        // nothing?
        break;
      case ID_KEY.DRIZLY_ID:
        // nothing?
        break;
      case ID_KEY.DUNZO_USER_ID:
        // nothing?
        break;
      case ID_KEY.EATSTREET_USER_ID:
        // nothing?
        break;
      case ID_KEY.EBAY_USER_ID:
        profileURLs[ID_KEY.EBAY_USER_ID] = `https://www.ebay.com/usr/${data[ID_KEY.EBAY_USER_ID]}`;
        break;
      case ID_KEY.EVITE_USER_ID:
        // nothing?
        break;
      case ID_KEY.FACEBOOK_USER_ID:
        profileURLs[ID_KEY.FACEBOOK_USER_ID] = `https://www.facebook.com/${data[ID_KEY.FACEBOOK_USER_ID]}`;
        break;
      case ID_KEY.FACEPUNCH_USER_ID:
        profileURLs[ID_KEY.FACEPUNCH_USER_ID] = `https://facepunch.com/member.php?u=${data[ID_KEY.FACEPUNCH_USER_ID]}`;
        break;
      case ID_KEY.FFSHRINE_USER_ID:
        // nothing?
        break;
      case ID_KEY.FIREBASE_USER_ID:
        // nothing?
        break;
      case ID_KEY.FLING_USER_ID:
        // nothing?
        break;
      case ID_KEY.GAADI_USER_ID:
        // nothing?
        break;
      case ID_KEY.GAMETUTS_USER_ID:
        // nothing?
        break;
      case ID_KEY.GCM_ID:
        // nothing?
        break;
      case ID_KEY.GOOGLE_USER_ID:
        // nothing?
        break;
      case ID_KEY.GUNTRADER_USER_ID:
        // nothing?
        break;
      case ID_KEY.HAVENLY_USER_ID:
        // nothing?
        break;
      case ID_KEY.HOUZZ_USER_ID:
        profileURLs[ID_KEY.HOUZZ_USER_ID] = `https://www.houzz.co.uk/user/${data[ID_KEY.HOUZZ_USER_ID]}`;
        break;
      case ID_KEY.INSTAGRAM_USER_ID:
        profileURLs[ID_KEY.INSTAGRAM_USER_ID] = `https://www.instagram.com/${data[ID_KEY.INSTAGRAM_USER_ID]}`;
        break;
      case ID_KEY.LINKEDIN_USER_ID:
        profileURLs[ID_KEY.LINKEDIN_USER_ID] = `https://www.linkedin.com/in/${data[ID_KEY.LINKEDIN_USER_ID]}`;
        break;
      case ID_KEY.LIVE_AUCTIONEERS_USER_ID:
        // nothing?
        break;
      case ID_KEY.MACFORUMS:
        profileURLs[ID_KEY.MACFORUMS] = `https://www.mac-forums.com/members/${data[ID_KEY.MACFORUMS]}`;
        break;
      case ID_KEY.MATHWAY:
        // nothing?
        break;
      case ID_KEY.MINDJOLT_USER_ID:
        // nothing?
        break;
      case ID_KEY.MORELE_USER_ID:
        // nothing?
        break;
      case ID_KEY.MORTAL_ONLINE_USER_ID:
        // nothing?
        break;
      case ID_KEY.NAUGHTYAMERICA_USER_ID:
        // nothing?
        break;
      case ID_KEY.PARKMOBILE_USER_ID:
        // nothing?
        break;
      case ID_KEY.PROCTORU_ACCOUNT_UUID:
        // nothing?
        break;
      case ID_KEY.PROCOTRU_RECEIVABLES_USER_ID:
        // nothing?
        break;
      case ID_KEY.PROCTORU_USER_ID:
        // nothing?
        break;
      case ID_KEY.SHARETHIS_USER_ID:
        // nothing?
        break;
      case ID_KEY.STUDENT_ID:
        // nothing?
        break;
      case ID_KEY.TORRENTINVITES_USER_ID:
        // nothing?
        break;
      case ID_KEY.TRUEFIRE_USER_ID:
        // nothing?
        break;
      case ID_KEY.TWITTER_USER_ID:
        profileURLs[ID_KEY.TWITTER_USER_ID] = `https://twitter.com/${data[ID_KEY.TWITTER_USER_ID]}`;
        break;
      case ID_KEY.VAKINHA_ID:
        profileURLs[ID_KEY.VAKINHA_ID] = `https://www.vakinha.com.br/usuario/${data[ID_KEY.VAKINHA_ID]}`;
        break;
      case ID_KEY.VAKINHA_PAY_ID:
        // nothing?
        break;
      case ID_KEY.WISHBONE_USER_ID:
        // nothing?
        break;
      case ID_KEY.ZYNGA_USER_ID:
        // nothing?
        break;
    }
  }

  return profileURLs;
}