import axios from 'axios'

export const state = {
    loading: false,
    payAsYouGos: []
}

export const getters = {
    loading: state => state.loading,
    hasPayAsYouGos: state => !!state.payAsYouGos.length,
    payAsYouGos: state => state.payAsYouGos
}

export const mutations = {
    SET_LOADING (state, payload) {
        state.loading = payload
    },
    SET_PAY_AS_YOU_GOS (state, payload) {
        state.payAsYouGos = payload
    }
}

export const actions = {
    async fetchPayAsYouGos ({ commit }) {
        commit('SET_LOADING', true)

        const { data } = await axios.get(`/api/pay-as-you-gos`)

        commit('SET_PAY_AS_YOU_GOS', data.data)
        commit('SET_LOADING', false)
    }
}
